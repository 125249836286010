import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'page-name',
  templateUrl: './page-name.component.html',
  styleUrls: ['./page-name.component.scss']
})
export class PageNameComponent implements OnInit {
  @Input() pageName:any;

  constructor() { }

  ngOnInit(): void {
    if (!this.pageName) this.pageName = 'page name not defined'
  }

}
