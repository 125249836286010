import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalSearchContactRoutingModule, globalSearchContactRoutingComponents } from './global-search-contact-routing.module';
import { GlobalSearchContactComponent } from '../global-search-contact/global-search-contact.component';
import { SharedModule } from '../../modules/shared/shared.module';

@NgModule({
  declarations: [
    globalSearchContactRoutingComponents,
    GlobalSearchContactComponent
  ],
  imports: [
    CommonModule,
    GlobalSearchContactRoutingModule,
    SharedModule
  ]
})
export class GlobalSearchContactModule { }