import { Component, OnInit, Input, DoCheck, ChangeDetectorRef } from '@angular/core';
import { GlobalFnService } from 'src/app/services/global-functions.service';
import { WindowWidthService } from 'src/app/services/windowWidth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'doughnut-scroll',
  templateUrl: './doughnut-scroll.component.html',
  styleUrls: ['./doughnut-scroll.component.scss']
})
export class DoughnutScrollComponent implements OnInit, DoCheck {

  protected subscription1$: Subscription;

  @Input() backgroundColors: any;
  @Input() data:any;
  @Input() dataArray: any;

  ranCatIndex:number;
  chart: any;
  categoryIndex: number = 0;
  topThree:any = [];
  screenSize:number;

  constructor(
    private changeDetect: ChangeDetectorRef,
    private _windowWidth: WindowWidthService,
    public _fn: GlobalFnService
  ) { }

  ngOnInit(): void {
    this.subscription1$ = this._windowWidth.currentVal.subscribe(currentVal => this.screenSize = currentVal);

    this.topThree[0] = this.data[this.categoryIndex]._top3.doughnutData.top3;
  }

  ngAfterViewInit() {
    this.changeDetect.detectChanges();
  }

  ngDoCheck(){
    this.resetCategories()
  }

  goBack(){
    this.categoryIndex <=0 ? this.categoryIndex = 4:this.categoryIndex--;
    this.resetCategories()
  }

  goForward(){
    this.categoryIndex >=4 ? this.categoryIndex = 0:this.categoryIndex++;
    this.resetCategories();
  }

  resetCategories(){
    this.topThree[0] = this.data[this.categoryIndex]._top3.doughnutData.top3;
  }

}
