import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VendorContractDetailsComponent } from '../vendor-contract-details/vendor-contract-details.component';

const routes: Routes = [
  { path: '', component: VendorContractDetailsComponent, children: [
      { path:'vendor-contract-details', component: VendorContractDetailsComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VendorContractDetailsRoutingModule { }

export const vendorContractDetailsRoutingComponents = [
  VendorContractDetailsComponent
]
