import { Component, ElementRef, Input, Output, EventEmitter, OnInit, ViewChild, OnDestroy } from '@angular/core';

@Component({
  selector: 'search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit, OnDestroy {

  filterActive:boolean;
  @Input() backgroundColor: any;
  @Input() placeholder: any;
  @Input() filterless: any;
  @Input() currentSearch: any;
  @Output() searchText = new EventEmitter<any>();
  @Output() filterStatus = new EventEmitter<boolean>();
  @ViewChild('inputField', {static:false}) inputField:ElementRef;


  searchChange(e:any) {
    this.searchText.emit(e)
  }

  constructor() { }

  ngOnInit(): void {
    if (!this.placeholder) {
      this.placeholder = 'Search Dept, Contact, Etc..'
    }
  }

  ngAfterViewInit(){
    this.inputField.nativeElement.style = "background-color:" + this.backgroundColor;
  }

  ngOnDestroy(){}

  toggleFilters(){
    this.filterActive = true;
    this.filterStatus.emit(this.filterActive);
  }

}
