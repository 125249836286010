import { Component, OnInit, ChangeDetectorRef, OnDestroy, Output, ViewChild } from '@angular/core';
import * as filteredCharts from './filteredCharts.json';
import { WindowWidthService } from 'src/app/services/windowWidth.service';
import * as deptTable from './table-data.json';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from 'rxjs';
import { ModalBgService } from 'src/app/services/modal-bg.service';
import { DeptTableComponent } from '../../components/_reusable/dept-table/dept-table.component';
import { GlobalFnService } from 'src/app/services/global-functions.service';
import { DeptFiltersComponent } from 'src/app/components/dept-filters/dept-filters.component';

@AutoUnsubscribe()

@Component({
  selector: 'app-contract-obligations-breakdown',
  templateUrl: './contract-obligations-breakdown.component.html',
  styleUrls: ['./contract-obligations-breakdown.component.scss']
})

export class ContractObligationsBreakdownComponent implements OnInit, OnDestroy {

  protected subscription1$: Subscription;
  protected subscription2$: Subscription;
  protected subscription3$: Subscription;

  // Get Column Names from Table
  @ViewChild(DeptTableComponent) tableComponent: any = [];
  columnNames: string;
  @Output() visibleFilters: any = this.tableComponent.columnNames;
  @Output() gridAreaNames: any;

  @ViewChild(DeptFiltersComponent) dFilters:any

  filterStatus: any;
  screenSize: number;
  totalAmount: number;
  currentAmount: number;
  percentOfCommitment: number;
  result: any = [];
  filteredChartsArray: any = [];
  filteredCharts: any = filteredCharts;
  chartData: any;
  dataIndex: number = 0;
  tableValue: any = [];
  tableArray: any = [];
  randomNum: number;

  constructor(
    private _windowWidth: WindowWidthService,
    public _modalBG: ModalBgService,
    private _cd: ChangeDetectorRef,
    public _fn: GlobalFnService
  ) { }

  ngOnInit(): void {
    this.subscription2$ = this._windowWidth.currentVal.subscribe(currentVal => this.screenSize = currentVal);
    this.subscription3$ = this._modalBG.currentVal.subscribe(currentVal => this.filterStatus = currentVal);

    for (let key in filteredCharts.dataArray) {
      if (filteredCharts.dataArray.hasOwnProperty(key)) {
        this.filteredChartsArray.push(filteredCharts.dataArray[key])
      }
    }
    this.toggleTableData(0)
  }

  toggleTableData(i: number) {
    this.tableValue = [];
    for (let key in deptTable.deptTable) {
      if (deptTable.deptTable.hasOwnProperty(key)) {
        this.tableValue.push(deptTable.deptTable[key])
      }
    }
  }

  ngAfterViewInit() {
    this.dFilters.showAbreviations = false;
    this._fn.createTableGridArea(this.tableValue[0] );
    this._fn.createFilterGridArea(this.dFilters, this.tableValue[0]);
    this._cd.detectChanges()
  }

  ngOnDestroy() { }

  // Create new grid-template-area
  receiveNewColNames(e: any) {
    this.gridAreaNames = "";
    for (var i = 0; i < e.length; i++) {
      this.gridAreaNames += e[i] + " "
    }
    this.gridAreaNames = "'" + this.gridAreaNames + "'";
  }

  backClick() {
    this.dataIndex--;
    this.dataIndex <= 0 ? this.dataIndex = this.tableValue.length - 1 : this.dataIndex--;
    this._fn.randomize()
  }

  forwardClick() {
    this.dataIndex++;
    this.dataIndex >= this.tableValue.length - 1 ? this.dataIndex = 0 : this.dataIndex++;
    this._fn.randomize()
  }

  receiveRowEvent(e: any) {
    var incomingArray = e.data
    this.chartData = incomingArray;
  }

  changeDD(e:any) {
    this.tableValue = this._fn.changeDD(this.tableValue);
  }

}