import { Component, OnDestroy, OnInit } from '@angular/core';
import { WindowWidthService } from '../../services/windowWidth.service';
import * as data from './contacts.json';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from 'rxjs';

@AutoUnsubscribe()

@Component({
  selector: 'app-global-search-contact',
  templateUrl: './global-search-contact.component.html',
  styleUrls: ['./global-search-contact.component.scss']
})
export class GlobalSearchContactComponent implements OnInit, OnDestroy {

  protected subscription1$: Subscription;

  screenSize: number;
  contactDetails:any = [];
  data:any = data;

  constructor(
    private _wService: WindowWidthService
  ) {}

  ngOnInit(): void {
    this.subscription1$ = this._wService.currentVal.subscribe(currentVal => this.screenSize = currentVal);
    this.screenSize = this._wService.someWidth;

    for(let key in data.contactCard){
      if(data.contactCard.hasOwnProperty(key)){
        this.contactDetails.push(data.contactCard[key])
      }
    }
  }

  ngOnDestroy(){}
}
