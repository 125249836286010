import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BurnRateCategoryComponent } from '../burn-rate-category/burn-rate-category.component';

const routes: Routes = [
  { path: '', component: BurnRateCategoryComponent, children: [
      { path:'burn-rate-category', component: BurnRateCategoryComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BurnRateCategoryRoutingModule { }

export const burnRateCategoryRoutingComponents = [
  BurnRateCategoryComponent
]
