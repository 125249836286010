import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ExpendituresComponent } from 'src/app/department/expenditures/expenditures.component';

const routes: Routes = [
  { path: '', component: ExpendituresComponent, children: [
      { path:'expenditures', component: ExpendituresComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExpendituresRoutingModule { }

export const expendituresRoutingComponents = [
  ExpendituresComponent
]
