import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpendingMilestoneRoutingModule, spendingMilestoneRoutingComponents } from './spending-milestone-routing.module';
import { SharedModule } from '../../modules/shared/shared.module';



@NgModule({
  declarations: [
    spendingMilestoneRoutingComponents
  ],
  imports: [
    CommonModule,
    SpendingMilestoneRoutingModule,
    SharedModule
  ]
})
export class SpendingMilestoneModule { }
