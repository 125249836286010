import { Component, OnDestroy, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { WindowWidthService } from 'src/app/services/windowWidth.service';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from 'rxjs';
import { GlobalFnService } from 'src/app/services/global-functions.service';
import * as tableData from './vendor-data.json'
import * as chartJson from './chart-data.json';

@AutoUnsubscribe()

@Component({
  selector: 'vendor-spending',
  templateUrl: './vendor-spending.component.html',
  styleUrls: ['./vendor-spending.component.scss'],
})
export class VendorSpendingComponent implements OnInit, OnDestroy {

  protected subscription1$: Subscription;

  p: number = 1;
  itemsPerPage = 8;
  screenSize:number;
  tableResult: any = [];
  dataIndex:number;
  tableData = tableData.tableData;
  chartData = chartJson.chartData;
  chartColors = chartJson.chartColors;

  @Output() gridAreaNames: any;

  constructor(
    private _windowWidth: WindowWidthService,
    private _cd:ChangeDetectorRef,
    public _fn: GlobalFnService
  ) { }

  ngOnInit(): void {
    this.subscription1$ = this._windowWidth.currentVal.subscribe(currentVal => this.screenSize = currentVal)
  }

  ngAfterViewInit(){
    this._fn.createTableGridArea(this.tableData[0] );
    this._cd.detectChanges()
  }

  ngOnDestroy(){}

  calcTotal(array, index) {
    let total = 0;
    array.forEach(contract => {
      total += contract.data[index]
    })
    return total
  }

  calcPercent(array, arrayNumber, index) {
    const total = this.calcTotal(array, arrayNumber)
    return Math.round((array[index].data[arrayNumber] / total) * 100) + '%'
  }

  inDollars(string) {
    return "$" + this._fn.numberWithCommas(string) + ".00"
  }

  changeDD(e) {
    this.tableData = this._fn.changeDD(this.tableData)
  }

}