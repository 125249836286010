import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BurnRateRankComponent } from '../burn-rate-rank/burn-rate-rank.component';

const routes: Routes = [
  { path: '', component: BurnRateRankComponent, children: [
      { path:'burn-rate-rank', component: BurnRateRankComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BurnRateRankRoutingModule { }

export const burnRateRankRoutingComponents = [
  BurnRateRankComponent
]
