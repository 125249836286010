<div id="main-content" data-page="departments">
    <div class="row-one">
        <div class="header">
            <page-name [pageName]='"All Departments"'></page-name>
        </div>
        <div class="search-container">
            <search-bar [placeholder]="'Search Dept, Contact, Etc..'" [backgroundColor]="'white'" (filterStatus)="_fn.transparentBG($event)" *ngIf="windowWidth > 1100"></search-bar>
            <search-bar [placeholder]="'Search Dept, Contact, Etc..'" *ngIf="windowWidth <= 1100" (filterStatus)="_fn.transparentBG($event)"></search-bar>
        </div>
    </div>
    <div class="dept-table p-shadow">
        <dept-table [data]="tableArray" [abreviations]="abreviationArray" [itemsPerPageInput]="17" [firstColumnWidth]="200" [pageValues]="_fn.gridAreaNames" [pageLink]="'department-drilldown'"></dept-table>
    </div>

    <div id="filter-container" [ngClass]="{'show-filters': _modalBG.panelStatus[3].filters.value}">
        <dept-filters [data]="tableArray" [filterList]="tableComponent.columnNames" [pageValues]="_fn.gridAreaNames" (columnNamesEvt)="receiveNewColNames($event)" (abreviationEvt)="getNewData($event)"></dept-filters>
    </div>
</div>