<div class="filter-panel">
    <div class="header" [ngClass]="{'more-grid': category === 'More'}">
        <div class="title" *ngIf="category != 'More'" [ngClass]="{'active-tab': activeTab === 'filter' && !panelStatus}"
            (click)="currentTab('filter')">
            <div class="filter-icon">
                <filter-icon></filter-icon>
            </div>
            <p [ngClass]="{'inactive-tab': activeTab != 'filter'}">Filter</p>
        </div>
        <div class="title" [ngClass]="{'active-tab': activeTab === 'config'}" (click)="currentTab('config')">
            <div class="filter-icon config">
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 698.36 532.08">
                    <defs>
                        <style>
                            .cls-1 {
                                fill: #fff !important;
                            }

                            .cls-2 {
                                fill: #01b3db;
                            }

                            .b,
                            .c {
                                fill: #01b3db;
                            }

                            .d {
                                stroke: none;
                            }
                        </style>
                    </defs>
                    <title>configure-table</title>
                    <g id="Rectangle_7034" data-name="Rectangle 7034">
                        <rect class="cls-1" x="125.9" y="230.81" width="96.34" height="43.23" />
                        <rect class="cls-1" x="125.9" y="300.04" width="96.34" height="47.77" />
                        <rect class="cls-1" x="248.24" y="230.81" width="98.66" height="43.23" />
                        <rect class="cls-1" x="248.24" y="300.04" width="98.66" height="47.77" />
                    </g>
                    <path class="cls-2"
                        d="M-29.4,5.19V537.27H669V5.19ZM350.07,171.46H602.45V282.05H350.07Zm-66.51,299.3H37.11V348.56H283.56Zm0-188.71H37.11V171.46H283.56ZM602.45,470.76H350.07V348.56H602.45Z"
                        transform="translate(29.4 -5.19)" />
                </svg>
            </div>
            <p [ngClass]="{'inactive-tab': activeTab != 'config'}">Configure Table</p>
        </div>
        <div></div>
        <div class="close" (click)="filterData()">
            <img src="../../../assets/dept-name/closeFilterPanel.svg" />
        </div>
    </div>

    <!-- Panel Titles, Left Chevron, and Close Btn -->
    <div class="expense-header" [ngClass]="{'show-exp-header':panelStatus}">
        <div class="exp-chevron" (click)="closePanel()">
            <i class="fa fa-chevron-left"></i>
        </div>
        <div class="sub-panels" *ngIf="activeTab === 'filter'" (click)="closeAll()">
            <div>
                <div class="exp-title">
                    {{ panelTitle }}
                </div>
            </div>
        </div>
    </div>

    <div class="configure-table" [ngClass]="{'hidden':activeTab != 'config'}">
        <div class="first-column-locked" *ngIf="screenSize > 1100 && showAbreviations">
            <p>1</p>
            <div>
                <div (click)="toggleAbreviation()">
                    <div class="faux-radio">
                        <div class="oceanBlue" *ngIf="abreviation"></div>
                    </div>
                    <p>{{ category }} Abreviation</p>
                </div>
                <div (click)="toggleAbreviation()">
                    <div class="faux-radio">
                        <div class="oceanBlue" *ngIf="!abreviation"></div>
                    </div>
                    <p>{{ category }} Name</p>
                </div>
            </div>
            <div class="lock"><i class="fas fa-lock"></i></div>
        </div>
        <div cdkDropListGroup>
            <div cdkDropList [cdkDropListData]="filterList" class="config-group" [style.grid-template-areas]="rowOrder"
                (cdkDropListDropped)="drop($event)">
                <div cdkDrag class="line-item cdk-drag-preview" *ngFor="let item of filterList; let i = index"
                    [style.grid-area]="'col' + (i + 1)">
                    <div class="drag-drop">
                        <i class="fa fa-bars" aria-hidden="true"></i>
                    </div>
                    <span>{{ screenSize > 1100 && showAbreviations ? i + 2 : i + 1 }}</span>
                    <p>{{ item }}</p>
                    <div class="tSwitch">
                        <toggle-switch (toggleStatus)="toggleEvt(i)"></toggle-switch>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- ****************
         Filter Selection
         **************** -->
    <div class="vendor-category-filter" *ngIf="activeTab == 'filter'">
        <div class="desktop-filters">
            <header [ngClass]="{'visibilityNone':panelStatus}" *ngIf="category === 'Departments'">
                <div class="part-two">
                    <div>
                        <div class="icon-text-group" [ngClass]="{'selected-filter': selectedFilter === 0}"
                            (click)="selectFilter(0)">
                            <div>
                                <i class="fas fa-th"></i>
                            </div>
                            <div class="name">
                                <p>All</p>
                            </div>
                        </div>
                        <div class="icon-text-group" [ngClass]="{'selected-filter': selectedFilter === 1}"
                            (click)="selectFilter(1)">
                            <div>
                                <i class="fas fa-star"></i>
                            </div>
                            <div class="name">
                                <p>Favorited</p>
                            </div>
                        </div>
                        <div class="icon-text-group" [ngClass]="{'selected-filter': selectedFilter === 2}"
                            (click)="selectFilter(2)">
                            <div>
                                <i class="fas fa-dollar-sign"></i>
                            </div>
                            <div class="name">
                                <p>Top Spenders</p>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <!-- Filter Type List -->
            <div>
                <div id="home" *ngIf="category === 'Home'">
                    <div class="select-filter" *ngFor="let item of homeFilter; let i = index"
                        (click)="contractsPanel(i)">
                        <p>{{ item.name }}</p>
                        <p class="item-value" [attr.data-index]="i">{{ homeFilter[dataIndex].value === '' ? 'No Filter'
                            :
                            deptFilter[dataIndex].value }}</p>
                        <div class="indicator">
                            <i class="fa fa-chevron-right"></i>
                        </div>
                    </div>
                </div>

                <div id="vendor" *ngIf="category === 'Vendor Contract'">
                    <div class="select-filter" *ngFor="let item of vendorsFilter; let i = index"
                        (click)="contractsPanel(i)">
                        <p>{{ item.name }}</p>
                        <p *ngIf="i == 2">{{ endVal != undefined ? startVal + " — " + endVal : '' }}</p>
                        <p class="item-value" [attr.data-index]="i">{{ vendorsFilter[dataIndex].value === '' ? 'No
                            Filter'
                            : vendorsFilter[dataIndex].value }}</p>
                        <div class="indicator">
                            <i class="fa fa-chevron-right"></i>
                        </div>
                    </div>
                </div>

                <div id="contracts" *ngIf="contractsBtn && category === 'Departments'">
                    <div class="select-filter" *ngFor="let item of contractsFilter; let i = index"
                        (click)="contractsPanel(i)">
                        <p>{{ item.name }}</p>
                        <p *ngIf="i == 0">{{ endVal != undefined ? startVal + " — " + endVal : '' }}</p>
                        <p class="item-value" [attr.data-index]="i">{{ contractsFilter[dataIndex].value === '' ? 'No
                            Filter' : contractsFilter[dataIndex].value }}</p>
                        <div class="indicator">
                            <i class="fa fa-chevron-right"></i>
                        </div>
                    </div>
                </div>
                <div id="nonContracts" *ngIf="!contractsBtn && category === 'Departments'">
                    <div class="select-filter" *ngFor="let item of deptFilter; let i = index"
                        (click)="contractsPanel(i)">
                        <p>{{ item.name }}</p>
                        <p class="item-value" [attr.data-index]="i">{{ deptFilter[dataIndex].value === '' ? 'No Filter'
                            :
                            deptFilter[dataIndex].value }}</p>
                        <div class="indicator">
                            <i class="fa fa-chevron-right"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer>
            <div class="btn-group" (click)="closeOnSave()">
                <div class="save">
                    <button>Save</button>
                </div>
            </div>
        </footer>
    </div>

    <!-- Filter Panels-->
    <div id="vendor-panel" [ngClass]="{'show-expense-slider-panel': panelStatus}">
        <div id="v-list" class="vendor-list">

            <!-- Depts Contracts -->
            <div class="list-wrapper" *ngIf="contractsBtn && category === 'Departments'">
                <!-- Slider for Min/Max -->
                <div class='slider-wrapper'
                    *ngIf="contractsFilter[dataIndex].name == 'Exp. Min.' || contractsFilter[dataIndex].name == 'Exp. Max.'">
                    <p class='slider-count'>${{ _fn.numberWithCommas(sliderValue) }}</p>
                    <div class="slider">
                        <mat-slider (input)="sliderMove($event)" (change)="sliderUpdate($event)" [step]="1000"
                            [value]="sliderValue" [max]="10000000"></mat-slider>
                    </div>
                </div>

                <div *ngIf="contractsFilter[dataIndex].name == 'Vendor'" class="banner">
                    VENDORS ASSOCIATED WITH DEPARTMENT
                </div>
                <div *ngIf="contractsFilter[dataIndex].name == 'Vendor'"><br></div>
                <div class="repeating-block" *ngFor="let item of contractsFilter[dataIndex].data; let i = index"
                    (click)="tickBox(i, '#contracts')">
                    <div class="row-btn"></div>
                    <div class="check" [ngClass]="{'checked': checkedBox === i}">
                        <i class="fas fa-check"></i>
                    </div>
                    <div *ngIf="contractsFilter[dataIndex].name != 'Vendor'">
                        <p>{{ item.element }}</p>
                    </div>
                    <div *ngIf="contractsFilter[dataIndex].name == 'Vendor'">
                        <p class="vendor-name">{{ item.element}}</p>
                        <p class="vendor-details">Vendor Number {{ item.number }}</p>
                    </div>
                </div>
                <div *ngIf="contractsFilter[dataIndex].name == 'Date Range'" class="calendar">
                    <calendar (emitStartDate)="startDate($event)" (emitEndDate)="endDate($event)"></calendar>
                </div>
            </div>

            <!-- Home -->
            <div class="list-wrapper" *ngIf="!contractsBtn && category === 'Home'">
                <div class="repeating-block" *ngFor="let item of homeFilter[dataIndex].data; let i = index"
                    (click)="tickBox(i, '#home')">
                    <div class="row-btn"></div>
                    <div id="{{i}}" class="check" [ngClass]="{'checked': checkedBox === i}">
                        <i class="fas fa-check"></i>
                    </div>
                    <div>
                        <p>{{ item.element }}</p>
                    </div>
                </div>
            </div>

            <!-- Depts Non Contracts -->
            <div class="list-wrapper" *ngIf="!contractsBtn && category === 'Departments'">
                <div class="repeating-block" *ngFor="let item of deptFilter[dataIndex].data; let i = index"
                    (click)="tickBox(i, '#nonContracts')">
                    <div class="row-btn"></div>
                    <div id="{{i}}" class="check" [ngClass]="{'checked': checkedBox === i}">
                        <i class="fas fa-check"></i>
                    </div>
                    <div>
                        <p>{{ item.element }}</p>
                    </div>
                </div>
            </div>

            <!-- Vendors -->
            <div class="list-wrapper" *ngIf="category === 'Vendor Contract'">
                <div *ngIf="vendorsFilter[dataIndex].name == 'Related Contract'" class="banner">
                    VENDORS ASSOCIATED WITH DEPARTMENT
                </div>
                <div *ngIf="vendorsFilter[dataIndex].name == 'Related Contract'"><br></div>
                <div class="repeating-block" *ngFor="let item of vendorsFilter[dataIndex].data; let i = index"
                    (click)="tickBox(i, '#vendor')">
                    <div id="{{i}}" class="check" [ngClass]="{'checked': checkedBox === i}">
                        <i class="fas fa-check"></i>
                    </div>
                    <div *ngIf="vendorsFilter[dataIndex].name != 'Related Contract'">
                        <p>{{ item.element }}</p>
                    </div>
                    <div *ngIf="vendorsFilter[dataIndex].name == 'Related Contract'">
                        <p class="vendor-name">{{ item.element}}</p>
                        <p class="vendor-details">Vendor Number {{ item.number }}</p>
                    </div>
                </div>
                <div *ngIf="vendorsFilter[dataIndex].name == 'Date Range'" class="calendar">
                    <calendar (emitStartDate)="startDate($event)" (emitEndDate)="endDate($event)"></calendar>
                </div>
            </div>

        </div>
    </div>
</div>