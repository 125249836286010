import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ChartsModule } from 'ng2-charts';
import { FormsModule } from '@angular/forms';
import { FavCompareContactComponent } from '../../components/_reusable/fav-compare-contact/fav-compare-contact.component';
import { SidebarItemComponent } from '../../components/_reusable/sidebar-item/sidebar-item.component';
import { AreaLineChartComponent } from '../../components/_reusable/area-line-chart/area-line-chart.component';
import { MultiLineChartComponent } from '../../components/_reusable/multi-line-chart/multi-line-chart.component';
import { HBarChartComponent } from '../../components/_reusable/h-bar-chart/h-bar-chart.component';
import { BarChartComponent } from '../../components/_reusable/bar-chart/bar-chart.component';
import { BackBtnComponent } from '../../components/_reusable/back-btn/back-btn.component';
import { BtnGroupComponent } from '../../components/_reusable/btn-group/btn-group.component';
import { DoughnutChartComponent } from '../../components/_reusable/doughnut-chart/doughnut-chart.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { BudgetForecastComponent} from '../../components/_reusable/budget-forecast/budget-forecast.component';
import { AreaChartHeaderComponent } from '../../components/_reusable/area-chart-header/area-chart-header.component';
import { DropdownMenusComponent } from '../../components/_reusable/dropdown-menus/dropdown-menus.component';
import { DropdownComponent } from '../../components/_reusable/dropdown/dropdown.component';
import { GraphTableComponent } from '../../components/_reusable/graph-table/graph-table.component';
import { FilterIconComponent } from '../../components/_reusable/filter-icon/filter-icon.component';
import { PageNameComponent } from '../../components/_reusable/page-name/page-name.component';
import { SearchBarComponent } from '../../components/_reusable/search-bar/search-bar.component';
import { DeptTableComponent } from '../../components/_reusable/dept-table/dept-table.component'
import { BudgetGraphsComponent } from '../../components/_reusable/budget-graphs/budget-graphs.component'
import { DoughnutScrollComponent } from '../../components/_reusable/doughnut-scroll/doughnut-scroll.component'
import { TabGroupComponent } from '../../components/_reusable/tab-group/tab-group.component';
import { DeptFilterTemplateComponent } from '../../components/_reusable/dept-filter-template/dept-filter-template.component';
import { DeptFiltersComponent } from '../../components/dept-filters/dept-filters.component';
import { StackedBarChartComponent } from '../../components/_reusable/stacked-bar-chart/stacked-bar-chart.component'
import { BurnRateSidebarComponent } from '../../components/_reusable/burn-rate-sidebar/burn-rate-sidebar.component';
import { SidebarToggleIconComponent } from '../../components/_reusable/sidebar-toggle-icon/sidebar-toggle-icon.component';
import { ProgressBarComponent } from '../../components/_reusable/progress-bar/progress-bar.component';
import { GreenBoxComponent } from '../../components/_reusable/green-box/green-box.component';
import { LineChartComponent } from '../../components/_reusable/line-chart/line-chart.component';
import { ToggleSwitchComponent } from '../../components/_reusable/toggle-switch/toggle-switch.component';
import { CalendarComponent } from '../../components/_reusable/calendar/calendar.component';
import { CalendarCreditComponent } from '../../components/_reusable/calendar-credit/calendar-credit.component';

import { DemoMaterialModule } from '../material-module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatSliderModule } from '@angular/material/slider';

@NgModule({
  declarations: [
    FavCompareContactComponent,
    SidebarItemComponent,
    AreaLineChartComponent,
    MultiLineChartComponent,
    HBarChartComponent,
    BarChartComponent,
    BackBtnComponent,
    BtnGroupComponent,
    DoughnutChartComponent,
    BudgetForecastComponent,
    AreaChartHeaderComponent,
    DropdownMenusComponent,
    DropdownComponent,
    GraphTableComponent,
    FilterIconComponent,
    PageNameComponent,
    SearchBarComponent,
    DeptTableComponent,
    BudgetGraphsComponent,
    DoughnutScrollComponent,
    TabGroupComponent,
    DeptFilterTemplateComponent,
    DeptFiltersComponent,
    StackedBarChartComponent,
    BurnRateSidebarComponent,
    SidebarToggleIconComponent,
    ProgressBarComponent,
    GreenBoxComponent,
    LineChartComponent,
    ToggleSwitchComponent,
    CalendarComponent,
    CalendarCreditComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgxPaginationModule,
    ChartsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    DemoMaterialModule,
    InfiniteScrollModule,
    FormsModule,
    MatSliderModule
  ],
  exports: [
    FavCompareContactComponent,
    SidebarItemComponent,
    AreaLineChartComponent,
    MultiLineChartComponent,
    HBarChartComponent,
    BarChartComponent,
    BackBtnComponent,
    BtnGroupComponent,
    DoughnutChartComponent,
    BudgetForecastComponent,
    AreaChartHeaderComponent,
    DropdownMenusComponent,
    DropdownComponent,
    GraphTableComponent,
    FilterIconComponent,
    PageNameComponent,
    SearchBarComponent,
    DeptTableComponent,
    BudgetGraphsComponent,
    DoughnutScrollComponent,
    TabGroupComponent,
    DeptFilterTemplateComponent,
    DeptFiltersComponent,
    StackedBarChartComponent,
    BurnRateSidebarComponent,
    SidebarToggleIconComponent,
    ProgressBarComponent,
    GreenBoxComponent,
    LineChartComponent,
    ToggleSwitchComponent,
    CalendarComponent,
    CalendarCreditComponent,
    MatSliderModule
  ]
})
export class SharedModule { }
