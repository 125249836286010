import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, Input, Output, EventEmitter, AfterContentInit, OnDestroy, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { GlobalFnService } from 'src/app/services/global-functions.service';
import { WindowWidthService } from 'src/app/services/windowWidth.service';
import * as jsonData from "../../../department/all-departments/departments.json";

@AutoUnsubscribe()

@Component({
  selector: 'dept-table',
  templateUrl: './dept-table.component.html',
  styleUrls: ['./dept-table.component.scss']
})
export class DeptTableComponent implements OnInit, AfterContentInit, OnDestroy {

  @Input() itemsPerPageInput: any;
  @Input() data: any;
  @Input() backgroundColors: any;
  @Input() firstColumnWidth: any;
  @Input() pageValues: any;
  @Input() pageLink: string;
  @Input() comparison: boolean = false;
  @Input() infiniteScroll:boolean = true;
  @Input() abreviations:any;
  @Input() deleteThis:number;

  @Output() clickEvt = new EventEmitter<any>();
  @Output() numChecked = new EventEmitter<any>();

  protected subscription1$: Subscription

  jsonArray: any = []
  keyArray: any = [];
  columnDetails: any = [];
  columnWidth: any;
  shortColumnWidth: number = 135;
  p: number = 1;
  recentSortTarget: any;
  columnNames: any;
  totalWidth: number = 0;
  str: any;
  columnRef: any;
  currentTab: any;
  objectCount: number = 0;
  screenSize: number;
  truncatedValues: any = [];
  scrollingEnd: boolean = false;
  startingPoint: number;
  boxesChecked:any = [];
  scrollingDifference: any;
  scrollable: boolean;
  firstIndex: any;
  pageValuesArray: any = [];
  itemsPerPage:number;

  @ViewChild('theTable', { static: false }) deptTable: ElementRef;
  @ViewChild('colOne', { static: false }) colOne: ElementRef;
  @ViewChild('scrollingContent', { static: false }) scrollingContent: ElementRef;

  constructor(
    private _changeDetect: ChangeDetectorRef,
    private _winWidth: WindowWidthService,
    private router: Router,
    private _fn: GlobalFnService
  ) { }

  ngOnInit(): void {
    this.subscription1$ = this._winWidth.currentVal.subscribe(currentVal => this.screenSize = currentVal);

    this.columnNames = Object.keys(this.data[0]);

    this.currentTab = this.columnNames[0];

    this.getColumnNames()
    this.itemsPerPage = this.screenSize > 1100 ? this.itemsPerPageInput : 15;
  }

  ngAfterContentInit() {
    this.columnRef = Array.from(document.getElementsByClassName('colRef'));
  }

  ngOnChanges() {
    if (this.data && this.data.length > 0) {
      this.columnNames = [];
      this.columnNames = Object.keys(this.data[0]);
      this.getColumnNames()
      this.generateTable();
      this.trimColumnOne();
      if(this._fn.gridAreaNames != undefined){
        this.convertClassToArray()
      }
      setTimeout(() => {
        this.tableMeasurements()
      }, 1000);
    }
    this.deleteBox();
    this.numChecked.emit(this.boxesChecked)
  }

  ngDoCheck(){
    if(this.abreviations != undefined){
      if(this.abreviations.length > 0){
        this.truncatedValues = this.abreviations;
        return this.truncatedValues
      }
    }
    this.trimColumnOne()
  }

  ngOnDestroy() { }

  convertClassToArray() {
    // Create array from col col2 col3... string to match array in drag/drop dept-filters component
    this.pageValuesArray = [];
    this.pageValuesArray = this._fn.gridAreaNames.split(" ");
    var firstIndex = this.pageValuesArray[0].slice(1),
      lastIndex = this.pageValuesArray[this.pageValuesArray.length - 1].slice(0, -1);
    this.pageValuesArray[0] = firstIndex;
    this.pageValuesArray.pop();
    this.pageValuesArray.push(lastIndex);

    // this.trimColumnOne();
    this._changeDetect.detectChanges();
    this.pageValuesArray.pop();

    // Remove space in grid value ('col1 col2 col3 ')
    if(this._fn.gridAreaNames.lastIndexOf(" ")){
      this._fn.gridAreaNames.replace(" '", "'")
    }
  }

  leftNav() {
    this.pageValuesArray.push(this.pageValuesArray.shift());
    this.pageValues = ""
    this.shiftTableColumns()
  }

  rightNav() {
    this.pageValuesArray.unshift(this.pageValuesArray.pop());
    this.pageValues = "";
    this.shiftTableColumns()
  }

  shiftTableColumns(){
    for(var i = 0; i < this.pageValuesArray.length; i++){
      this.pageValues += this.pageValuesArray[i] + " ";
    }
    this.pageValues = "'" + this.pageValues + "'";
  }

  // Apply left/right navigation if columns are wider than body wrapper
  tableMeasurements() {
    if (this.scrollingContent != undefined) {
      if (this.scrollingContent.nativeElement.offsetWidth > this.deptTable.nativeElement.offsetWidth) {
        this.scrollable = true;
      } else {
        this.scrollable = false;
        this.scrollingContent.nativeElement.style.width = "100%";
      }
    }
  }
  getColumnNames() {
    if (this.columnNames != undefined) {
      var arr:any = [];
      this.firstIndex = this.columnNames[0];
      for (var i = 0; i < this.columnNames.length; i++) {
        if (i != 0 && this.columnNames[i].charAt(0) != '_') {
          arr.push(this.columnNames[i])
        }
      }
    }
    this.columnNames = arr;
  }

  // Trim Column One Value on Mobile
  trimColumnOne() {
    this.truncatedValues = [];
    if (this.screenSize < 1100) {
      for (var i = 0; i < this.jsonArray.length; i++) {
        var newValue: any;
        if (this.jsonArray[i][this.firstIndex].length > 10) {
          newValue = this.jsonArray[i][this.firstIndex].substring(0, 10) + "...";
          this.truncatedValues.push(newValue)
        } else {
          newValue = this.jsonArray[i][this.firstIndex];
          this.truncatedValues.push(newValue)
        }
      }
    } else {
      for (var i = 0; i < this.jsonArray.length; i++) {
        this.truncatedValues.push(this.jsonArray[i][this.firstIndex])
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.tableMeasurements();
    this.trimColumnOne()
    if (this.screenSize > 1100) {
      this.itemsPerPage = this.itemsPerPageInput;
    } else {
      if (this.itemsPerPage < 15) this.itemsPerPage = 15;
    }
  }

  isString(type: any) {
    if (typeof (type) == 'string') return true
  }

  filterTable(e: any) {
    this.currentTab = e;
  }

  selectItem(item: any, e:any, i:number) {
    if (this.comparison) {
      this.clickCheckbox(e, i);
    }
  }

  getTableData() {
    if (!this.data) this.data = jsonData.tableData;
  }

  generateTable() {
    this.getTableData();
    this.keyArray.length = 0;
    for (let key in this.data[0]) {
      this.keyArray.push(key)
    }
    this.jsonArray.length = 0;
    for (let key in this.data) {
      if (this.data.hasOwnProperty(key)) {
        this.jsonArray.push(this.data[key])
      }
    }
    if (this.comparison) {
      if (this.deleteThis == undefined) this.boxesChecked = []
      this.numChecked.emit(this.boxesChecked)
    }
  }

  clickCheckbox(event, i) {
    const target = event.currentTarget;
    if (!target.classList.contains('checked')) {
      if (this.boxesChecked.length < 2) {
        this.boxesChecked.push({id: i, name: this.data[i]._abreviation});
      }
    } else {
      this.boxesChecked = this.boxesChecked.filter(num => num.id !== i);
    }
    this.numChecked.emit(this.boxesChecked)
  }

  deleteBox() {
    if (this.deleteThis === 0) {
      if (this.boxesChecked.length > 1) this.boxesChecked = this.boxesChecked.slice(1,2)
      else this.boxesChecked = [];
      this.deleteThis = undefined;
    }
    if (this.deleteThis === 1) {
      this.boxesChecked = this.boxesChecked.slice(0,1);
      this.deleteThis = undefined;
    }
  }

  clickLink() {
    if (this.pageLink) this.router.navigate(['/' + this.pageLink])
  }

  sortClick(e, sortBy) {
    this.currentTab = sortBy;
    const classes = e.currentTarget.parentElement.classList
    if (this.recentSortTarget && this.recentSortTarget !== e.currentTarget.parentElement) this.recentSortTarget.classList.remove('sort-selected', 'sort-up');
    this.recentSortTarget = e.currentTarget.parentElement;
    if (classes.contains('sort-selected')) {
      if (classes.contains('sort-up')) {
        classes.remove('sort-up')
        this.jsonArray = this.sortDown(this.jsonArray, sortBy)
      } else {
        classes.add('sort-up');
        this.jsonArray = this.sortUp(this.jsonArray, sortBy)
      }
    } else {
      classes.add('sort-selected')
      this.jsonArray = this.sortDown(this.jsonArray, sortBy)
    }
    this.trimColumnOne();
  }

  sortDown(array, sortBy) {
    let tempArray = array.slice()
    return tempArray.sort((a, b) => a[sortBy] > b[sortBy] ? 1 : -1)
  }

  sortUp(array, sortBy) {
    let tempArray = array.slice()
    return tempArray.sort((a, b) => a[sortBy] < b[sortBy] ? 1 : -1)
  }

  onScroll() {
    if (this.screenSize <= 1100) this.itemsPerPage += 15;
  }

  checkForId(num) {
    let truth = false;
    this.boxesChecked.forEach( item => {
      if (item.id === num ) truth = true;
    })
    return truth
  }

  // getItemNames() {
  //   const names = []
  //   if (this.boxesChecked[0] !== undefined) {
  //     names.push(this.data[0]._abreviation)
  //   }
  //   if (this.boxesChecked[1] !== undefined) {
  //     names.push(this.data[1]._abreviation)
  //   }
  //   return names
  // }

}
