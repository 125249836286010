import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ObligationsRoutingModule, obligationsRoutingComponents } from './obligations-routing.module';
import { SharedModule } from '../../modules/shared/shared.module';

@NgModule({
  declarations: [
    obligationsRoutingComponents
  ],
  imports: [
    CommonModule,
    ObligationsRoutingModule,
    SharedModule
  ]
})
export class ObligationsModule { }
