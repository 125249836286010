<div id='settings-page'>
    <div class='fixed-top'>
        <h6>{{ currentTab }}</h6>
        <div class='search-container'>
            <search-bar [placeholder]="'Search Settings'"></search-bar>
        </div>
    </div>

    <div class='unfixed-content' *ngIf="currentTab === 'Settings'">

        <img class='profile-pic' src="../../../assets//global/settings.png" />
        <svg class='camera-icon' xmlns="http://www.w3.org/2000/svg" width="31.407" height="26.061" viewBox="0 0 31.407 26.061">
            <defs></defs>
            <g transform="translate(-0.5 -3.5)">
                <path class="a"
                d="M30.907,25.887a2.673,2.673,0,0,1-2.673,2.673H4.173A2.673,2.673,0,0,1,1.5,25.887v-14.7A2.673,2.673,0,0,1,4.173,8.51H9.52L12.194,4.5h8.02l2.673,4.01h5.347a2.673,2.673,0,0,1,2.673,2.673Z"
                transform="translate(0 0)" />
                <path class="a" d="M22.694,18.847A5.347,5.347,0,1,1,17.347,13.5a5.347,5.347,0,0,1,5.347,5.347Z"
                transform="translate(-1.143 -0.98)" />
                <g transform="translate(-168.645 -163.691)">
                    <circle class="b" cx="5" cy="5" r="5" transform="translate(180 176)" />
                    <line class="c" x2="6.64" transform="translate(181.5 181.82)" />
                    <line class="c" x2="6.64" transform="translate(184.82 178.5) rotate(90)" />
                </g>
            </g>
        </svg>

        <div class='user-info'>
            <div (click)="changeTab('Name')">
                <p>Name</p>
                <p>{{ user.name }}</p>
                <i class="fas fa-chevron-right vCenter"></i>
            </div>
            <div (click)="changeTab('User Name')">
                <p>User Name</p>
                <p>{{ user.username }}</p>
                <i class="fas fa-chevron-right vCenter"></i>
            </div>
            <div (click)="changeTab('Position')">
                <p>Position</p>
                <p>{{ user.position }}</p>
                <i class="fas fa-chevron-right vCenter"></i>
            </div>
            <div>
                <p>Email</p>
                <p>{{ user.email }}</p>
            </div>
        </div>

        <div class='options'>

            <div class='security-wrapper white-BG p-shadow p-radius'>
                <div class='security-header'>
                    <svg class='security-icon' xmlns="http://www.w3.org/2000/svg" width="16" height="19.556" viewBox="0 0 16 19.556">
                    <defs></defs>
                    <path class="a"
                        d="M12.5,1.5l-8,3.556v5.333a11.062,11.062,0,0,0,8,10.667,11.062,11.062,0,0,0,8-10.667V5.056Zm0,9.769h6.222A9.516,9.516,0,0,1,12.5,19.216V11.278H6.278V6.211L12.5,3.447Z"
                        transform="translate(-4.5 -1.5)" />
                </svg>
                    <p>Security</p>
                </div>
                <div class='security-options'>
                    <div (click)="changeTab('Change Password')">
                        <p>Change Password</p>
                        <i class="fas fa-chevron-right vCenter"></i>
                    </div>
                    <div (click)="changeTab('Update Email')">
                        <p>Update Email</p>
                        <i class="fas fa-chevron-right vCenter"></i>
                    </div>
                </div>
            </div>

            <div class='notifications-wrapper white-BG p-shadow p-radius'>
                <div class='notifications-header'>
                    <svg class='bell-icon' xmlns="http://www.w3.org/2000/svg" width="15.287" height="18.15" viewBox="0 0 15.287 18.15">
                    <defs></defs>
                    <g transform="translate(-176 -63.276)">
                        <g transform="translate(176 63.276)">
                        <path class="a"
                            d="M123.3,254.552H109.133a.555.555,0,0,1-.294-1.028L111,251.563v-4.68a5.224,5.224,0,0,1,4.316-5.13l.034-1.314a.9.9,0,1,1,1.8,0l-.034,1.314a5.234,5.234,0,0,1,4.311,5.13v4.68l2.166,1.961A.555.555,0,0,1,123.3,254.552Z"
                            transform="translate(-108.573 -239.536)" />
                        <path class="a" d="M123.893,271.3a2.015,2.015,0,0,1-3.967,0Z" transform="translate(-114.261 -254.811)" />
                        </g>
                    </g>
                </svg>
                    <p>Notifications</p>
                </div>
                <div class='notifications-options white-BG p-shadow p-radius'>
                    <div>
                        <p>Desktop</p>
                        <div class='toggle' [class.switch-on]='switchStatus[0]' (click)='toggleSwitch(0)'>
                            <div class='switch'></div>
                        </div>
                    </div>
                    <div>
                        <p>Email</p>
                        <div class='toggle' [class.switch-on]='switchStatus[1]' (click)='toggleSwitch(1)'>
                            <div class='switch'></div>
                        </div>
                    </div>
                    <div>
                        <p>Mobile</p>
                        <div class='toggle' [class.switch-on]='switchStatus[2]' (click)='toggleSwitch(2)'>
                            <div class='switch'></div>
                        </div>
                    </div>
                    <div (click)="toggleNotificationMenu()" [ngClass]="{'showing-notifications': notificationToggle}">
                        <p>Notification Type</p>
                        <i class="fas fa-chevron-right vCenter"></i>
                    </div>
                    <div class='expanded-menu' *ngIf="notificationToggle">
                        <div>
                            <input type="radio" id="all-op" name="notification-radios" value="all-op" checked>
                            <label for="all-op">All</label>
                        </div>
                        <div>
                            <input type="radio" id="new-contract-op" name="notification-radios" value="new-contract-op">
                            <label for="new-contract-op">New Contract Awarded</label>
                        </div>
                        <div>
                            <input type="radio" id="low-budg-op" name="notification-radios" value="low-budg-op">
                            <label for="low-budg-op">Remaining Budget is less than 10% of total budget</label>
                        </div>
                        <div>
                            <input type="radio" id="large-op" name="notification-radios" value="large-op">
                            <label for="large-op">Large purchase (over $1,000,000.00)</label>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class='change-item' *ngIf="currentTab === 'Name' || currentTab === 'User Name' || currentTab === 'Position'">
        <div class='panel box-shadow white-BG'>
            <label for='user-info' class='label-text'>{{ currentTab }}</label>
            <input id='user-info' value="{{ userInfo }}" />
            <p>Your {{ currentTab }} can be viewed by other admin accounts from the global search or as a listed contact.</p>
        </div>
        <div class='panel white-BG'>
            <button (click)="changeTab('Settings')">Save</button>
        </div>
    </div>

    <div class='need-password' *ngIf="currentTab === 'Change Password' || currentTab === 'Update Email'">
        <div class='panel white-BG'>
            <p class='label-text'>Current Password</p>
            <p class='password-text'>{{ user.password }}</p>
            <div class='input-wrapper current-password'>
                <label for='current-password' class='label-text'>Enter Current Password</label>
                <input id='current-password' />
            </div>
            <p class='alternate-option'>
                Or send a temporary password to my email or phone number
                <i class="fa fa-chevron-right"></i>
            </p>
            <div class='input-wrapper password-input' *ngIf="currentTab === 'Change Password'">
                <label for='new-password' class='label-text'>Create New Password</label>
                <input id='new-password' />
            </div>
            <div class='input-wrapper password-input' *ngIf="currentTab === 'Change Password'">
                <label for='confirm-password' class='label-text'>Confirm Password</label>
                <input id='confirm-password' />
            </div>
            <div class='input-wrapper email-input' *ngIf="currentTab === 'Update Email'">
                <label for='new-email' class='label-text'>Update Email</label>
                <input id='new-email' />
            </div>
        </div>
        <div class='panel box-shadow white-BG'>
            <button (click)="changeTab('Settings')">Save</button>
        </div>
    </div>

</div>