import { Component, OnInit,OnDestroy, ChangeDetectorRef, Input } from '@angular/core';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";

@AutoUnsubscribe()

@Component({
  selector: 'green-box',
  templateUrl: './green-box.component.html',
  styleUrls: ['./green-box.component.scss']
})
export class GreenBoxComponent implements OnInit, OnDestroy {
  @Input() text:any = 'placeholder'
  @Input() arrow:boolean = false;
  @Input() warning:boolean = false;
  @Input() fontSize:number = 14;

  constructor(
    private _changeDetect:ChangeDetectorRef
  ) { }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this._changeDetect.detectChanges();
  }

  ngAfterViewChecked() {}

  ngOnDestroy(){}
}
