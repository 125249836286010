import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ComparisonReportSelectComponent } from '../comparison-report-select/comparison-report-select.component';

const routes: Routes = [
  { path: '', component: ComparisonReportSelectComponent, children: [
      { path:'comparison-report-select', component: ComparisonReportSelectComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ComparisonReportSelectRoutingModule { }

export const comparisonReportSelectRoutingComponents = [
  ComparisonReportSelectComponent
]
