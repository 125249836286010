<div id="main-content" data-page="home">
    <div class="mobile-bg"></div>
    <div class="header-search">
        <h2>Home</h2>
        <div class="search" *ngIf="screenSize > 1100">
            <search-bar [backgroundColor]="'white'" [placeholder]="'Search'" [filterless]="true" (filterStatus)="_fn.transparentBG($event)"></search-bar>
        </div>
        <div class="search" *ngIf="screenSize <= 1100">
            <search-bar [placeholder]="'Search'" [filterless]="true"></search-bar>
        </div>
    </div>

    <div class='row-two'>
        <section class='remaining-budget p-shadow white-BG p-radius'>
            <budget-graphs></budget-graphs>
        </section>

        <div class='desktop-ss-title' *ngIf="screenSize > 1100">
            <span>FY22 Spending Summary</span>
            <search-bar [placeholder]="'Search Departments'" (filterStatus)="_fn.transparentBG($event)"></search-bar>
        </div>

    </div>
    <section class="spending-summary">
        <div class="data-selection">
            <div class="header">
                <p *ngIf='screenSize <=  1100'>
                    FY22 Spending Summary
                </p>
                <div class="btn-group">
                    <btn-group [btns]="btnNames" (clickEvent)="tabChange($event)"></btn-group>
                </div>
            </div>
            <div class='doughnut-scroll-wrapper'>
                <doughnut-scroll [backgroundColors]='backgroundColors' [data]="tableData[tableIndex]" [dataArray]="chartData[tableIndex]"></doughnut-scroll>
            </div>
            <div class='table-mobile-searchbar' *ngIf="screenSize <= 1100">
                <search-bar [placeholder]="'Search Departments'" (filterStatus)="_fn.transparentBG($event)"></search-bar>
            </div>
        </div>
        <div class="table-comp">
            <dept-table [abreviations]="abreviationArray" [itemsPerPageInput]='7' [backgroundColors]='backgroundColors' [data]="tableData[tableIndex]" [pageValues]="_fn.gridAreaNames" [pageLink]="tableLinks[tableIndex]"></dept-table>
        </div>
    </section>
    <div id="filter-container" [ngClass]="{'show-filters': _modalBG.panelStatus[3].filters.value}">
        <dept-filters [data]="tableData[tableIndex]" [filterList]="tableComponent.columnNames" [pageValues]="_fn.gridAreaNames" (columnNamesEvt)="receiveNewColNames($event)" (abreviationEvt)="getNewData($event)"></dept-filters>
    </div>
</div>