import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VendorSpendingComponent } from '../vendor-spending/vendor-spending.component';

const routes: Routes = [
  { path: '', component: VendorSpendingComponent, children: [
      { path:'vendor-spending', component: VendorSpendingComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VendorSpendingRoutingModule { }

export const vendorSpendingRoutingComponents = [
  VendorSpendingComponent
]

