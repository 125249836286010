import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { WindowWidthService } from 'src/app/services/windowWidth.service';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from 'rxjs';
import * as rates from './rates.json'
import { ModalBgService } from 'src/app/services/modal-bg.service';
import { DeptTableComponent } from '../../components/_reusable/dept-table/dept-table.component';
import { GlobalFnService } from 'src/app/services/global-functions.service';
import { DeptFiltersComponent } from 'src/app/components/dept-filters/dept-filters.component';

@AutoUnsubscribe()

@Component({
  selector: 'app-burn-rate-rank',
  templateUrl: './burn-rate-rank.component.html',
  styleUrls: ['./burn-rate-rank.component.scss']
})
export class BurnRateRankComponent implements OnInit, OnDestroy, AfterViewInit {

  protected subscription1$: Subscription;
  protected subscription2$: Subscription;

  // Get Column Names from Table
  @ViewChild(DeptTableComponent) tableComponent: any = [];
  columnNames: string;
  @Output() visibleFilters: any = this.tableComponent.columnNames;
  @Output() gridAreaNames: any;

  @ViewChild(DeptFiltersComponent) dFilters:any;

  tableData = rates.tableData;
  screenSize: any;
  dataIndex: number;
  filterStatus: any;

  constructor(
    private _windowWidth: WindowWidthService,
    public _modalBG: ModalBgService,
    private _cd: ChangeDetectorRef,
    public _fn: GlobalFnService
  ) { }

  ngOnInit(): void {
    this.subscription1$ = this._windowWidth.currentVal.subscribe(currentVal => this.screenSize = currentVal);
    this.subscription2$ = this._modalBG.currentVal.subscribe(currentVal => this.filterStatus = currentVal);
  }

  ngAfterViewInit() {
    this.dFilters.showAbreviations = false;
    this._fn.createTableGridArea(this.tableData[0] );
    this._fn.createFilterGridArea(this.dFilters, this.tableData[0]);
    this._cd.detectChanges()
  }

  ngOnDestroy() { }

  // Create new grid-template-area
  receiveNewColNames(e: any) {
    this.gridAreaNames = "";
    for (var i = 0; i < e.length; i++) {
      this.gridAreaNames += e[i] + " "
    }
    this.gridAreaNames = "'" + this.gridAreaNames + "'";
  }

  changeDD(e) {
    this.tableData = this._fn.changeDD(this.tableData)
  }

}

