<div id="main-content" data-page="obligations">
    <div class="header-content">
        <div class="page-name">
            <page-name [pageName]='"Obligations"'></page-name>
        </div>
        <div [ngClass]="{'mobile-background':screenSize < 1100}">
            <dropdown-menus (ddInfo)='changeDD($event)'></dropdown-menus>
        </div>
    </div>
    <div class="slider">
        <div>
            <div id="expenditure-trends" class="p-shadow p-radius white-BG">
                <area-chart-header></area-chart-header>
                <div class="area-chart" [ngClass]="{'tabletHeight': isIPadPro}">
                    <app-area-line-chart [monthData]="currentYear" [duration]="duration" [screensize]="screenSize"></app-area-line-chart>
                </div>
            </div>
            <div id="budgeted-forecast" class="p-shadow p-radius white-BG" [routerLink]="['/vendor-breakdown']">
                <p class="sub-title-two">
                    Top Vendor Breakdown
                </p>
                <div>
                    <div class="graph">
                        <div>
                            <doughnut-chart [cutOutPercent]="70"></doughnut-chart>
                        </div>
                    </div>
                    <div class="values">
                        <graph-table [chartAmount]="screenSize > 1100 ? filteredChartsArray[0].graphOne.amount : filteredChartsArray[0].graphOne.mobileAmount" [chartColor]="filteredChartsArray[0].graphOne.backgroundColor" [chartData]="filteredChartsArray[0].graphOne.data" [chartCategories]="filteredChartsArray[0].graphOne.categories"></graph-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="data-table">
        <div class="wrapper">
            <div class="btn-group">
                <btn-group [btns]='["Posted", "Pending"]' (clickEvent)="btnClick($event)"></btn-group>
            </div>
            <div class='search'>
                <search-bar [placeholder]="'Search Dept, Contact, Etc..'" (filterStatus)="_fn.transparentBG($event)"></search-bar>
            </div>
        </div>
        <div>
            <dept-table [data]="tableValue" [itemsPerPageInput]="10" [firstColumnWidth]="200" [pageValues]="_fn.gridAreaNames" [pageLink]="'vendor-contract-details'"></dept-table>
        </div>
    </div>
    <div id="filter-container" [ngClass]="{'show-filters': _modalBG.panelStatus[3].filters.value}">
        <dept-filters [data]="tableValue" [filterList]="tableComponent.columnNames" [pageValues]="_fn.gridAreaNames" (columnNamesEvt)="receiveNewColNames($event)"></dept-filters>
    </div>
</div>