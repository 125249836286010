<div [ngClass]="{'inf-scroll-wrap': infiniteScroll && screenSize <= 1100}" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="150" (scrolled)="onScroll()" [scrollWindow]="true" [immediateCheck]="true">

    <div id="data-table" [ngClass]="abreviations != undefined ? 'shortColumnOne' : ''">
        <!-- <div *ngIf="scrollable" class="goLeft" (click)="leftNav()">
            <i class="fa fa-chevron-left"></i>
        </div>
        <div *ngIf="scrollable" class="goRight" (click)="rightNav()">
            <i class="fa fa-chevron-right"></i>
        </div> -->
        <div class="header" [attr.data.index]="0">
            <div class="column-one">
                <div class="relative">
                    <p class="column-one-header">
                        {{ firstIndex }}
                        <i class="fas fa-arrow-down"></i>
                    </p>
                    <i class="fas fa-arrow-down" style="display: none;"></i>
                    <div class="vals">
                        <div class="column-one-data" (click)="selectItem(item, $event, i)" *ngFor="let item of truncatedValues | paginate: { itemsPerPage: itemsPerPage, currentPage: p }; let i = index" [attr.data-index]="i" [ngClass]="{'unselectable': boxesChecked.length > 1, 'checked': checkForId(i)}">
                            <input class='checkbox' type="checkbox" *ngIf='comparison'>
                            <p (click)='clickLink()' [ngClass]="{'page-link': pageLink}" *ngIf="isString(item)">{{ item }}</p>
                            <div class="key-value" (click)='clickLink()' *ngIf="!isString(item) && item != undefined">
                                <p [ngClass]="{'page-link': pageLink}">{{ item.name }}</p>
                                <span *ngIf="item.status === 'up'"><img src="../../../../assets/global/positive-triangle.svg" /></span>
                                <span *ngIf="item.status === 'down'"><img src="../../../../assets/global/negative-triangle.svg" /></span>
                                <span *ngIf="item.status === 'neutral'"><img src="../../../../assets/global/neutral-rectangle.svg" /></span>
                                <span>{{ item.percent }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="btn" (click)="sortClick($event, columnNames[0])"></div>
                </div>
            </div>
        </div>

        <div #theTable class="body-wrapper">
            <div #scrollingContent class="body-columns" [style.grid-template-areas]="pageValues">
                <div class="colRef header" *ngFor="let i = index; let column of columnNames" [attr.data-index]="i + 1" [attr.data-col]="'col' + (i+1)" [style.grid-area]="'col' + (i + 1)">
                    <div class="column relative">
                        <p class="parent">
                            {{ columnNames[i] }}
                            <i class="fas fa-arrow-down"></i>
                        </p>
                        <div *ngFor="let item of jsonArray | paginate: { itemsPerPage: itemsPerPage, currentPage: p }" (click)="selectItem(item)" class="cell-bg">
                            <div>
                                <p class="cell-value" *ngIf="isString(item[column])">{{ item[column] }}</p>
                                <div class="key-value" *ngIf="!isString(item[column]) && item[column] != undefined">
                                    <span *ngIf="item[column].status === 'up'"><img src="../../../../assets/global/positive-triangle.svg" /></span>
                                    <span *ngIf="item[column].status === 'down'"><img src="../../../../assets/global/negative-triangle.svg" /></span>
                                    <span *ngIf="item[column].status === 'neutral'"><img src="../../../../assets/global/neutral-rectangle.svg" /></span>
                                    <span>{{ item[column].percent }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="btn" (click)="sortClick($event, columnNames[i])"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer" *ngIf="jsonArray.length > itemsPerPage && (!infiniteScroll || screenSize > 1100)">
        <pagination-controls nextLabel=">" previousLabel="<" (pageChange)="p = $event"></pagination-controls>
    </div>

</div>