import { Component, OnDestroy, OnInit } from '@angular/core';
import { DeptFilterChildService } from 'src/app/services/dept-filters-child.service';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from 'rxjs';

@AutoUnsubscribe()

@Component({
  selector: 'dept-filter-template',
  templateUrl: './dept-filter-template.component.html',
  styleUrls: ['./dept-filter-template.component.scss']
})
export class DeptFilterTemplateComponent implements OnInit, OnDestroy {

  protected subscription1$: Subscription;
  protected subscription2$: Subscription;

  childPanelStatus:boolean;
  filterTitle:string;

  constructor(
    private _deptFilters:DeptFilterChildService
  ) { }

  ngOnInit(): void {
    this.subscription1$ = this._deptFilters.currentVal.subscribe(currentVal => this.childPanelStatus = currentVal);
    this.subscription2$ = this._deptFilters.titleVal.subscribe(titleVal => this.filterTitle = titleVal);
  }

  ngOnDestroy(){}

  // Update Child Component Filter Title
  closePanel(){
    this.childPanelStatus = !this.childPanelStatus;
    this._deptFilters.changeValue(this.childPanelStatus, this.filterTitle)
  }

}
