<div id="main-content" data-page="global-search-contact">
    <div *ngIf="screenSize <= 1100" class="header-content">
        <div class="page-name">
            <page-name [pageName]='"Contact"'></page-name>
        </div>
    </div>

    <div id="global-search-contact" *ngFor="let person of contactDetails">
        <div class="header">
            <div>
                <img src="../../../assets//global/bobevans.png" />
            </div>
            <div>
                <p>{{ person.fullName }}</p>
                <p>{{ person.role }}</p>
            </div>
            <div class="relative">
                <div class="btn-group vCenter">
                    <button class="conseqta-btn">
                  <i class="fas fa-envelope"></i>
                  <div>Email</div>
               </button>
                    <button class="conseqta-btn">
                  <i class="fas fa-phone-alt"></i>
                  <div>Call</div>
               </button>
                    <button class="conseqta-btn">
                  <i class="fas fa-copy"></i>
                  <div>Copy Info</div>
               </button>
                    <button class="conseqta-btn">
                  <i class="fas fa-save"></i>
                  <div>Save Info</div>
               </button>
                </div>
            </div>
        </div>

        <div class="content">
            <div class="columnOne p-radius p-shadow white-BG">
                <div class='columnOne-divider'></div>
                <div>
                    <div class="detail-grid">
                        <p class='detail-label'>Org Title</p>
                        <p>{{ person.orgTitle }}</p>
                    </div>
                    <div class="detail-grid">
                        <p class='detail-label'>Org Code</p>
                        <p>{{ person.orgCode }}</p>
                    </div>
                    <div class="detail-grid">
                        <p class='detail-label'>Email</p>
                        <p>{{ person.email }}</p>
                    </div>
                    <div class="detail-grid">
                        <p class='detail-label'>Phone</p>
                        <p>{{person.phone}}</p>
                    </div>
                    <div class="detail-grid">
                        <p class='detail-label'>Address</p>
                        <address>
                     {{person.address}},<br>
                     {{ person.city + " " + person.state + ", " + person.zip }}
                  </address>
                    </div>
                    <div class="detail-grid">
                        <p class='detail-label'>Supervisor Name</p>
                        <p><a href="/global-search-contact">Supervisor's Name</a></p>
                    </div>
                    <div class="detail-grid">
                        <p class='detail-label'>Supervisor Email</p>
                        <p><a href="mailto: supervisor@somedomain.com">supervisor@somedomain.com</a></p>
                    </div>
                    <div class="detail-grid">
                        <p class='detail-label'>Supervisor Phone</p>
                        <p><a href="tel:340-229-2332">340-229-2332</a></p>
                    </div>
                </div>
            </div>
            <div class="columnTwo p-radius p-shadow white-BG">
                <div class="related-contacts">
                    <i class="fa fa-user"></i>
                    <p>Related Contacts</p>
                </div>
                <div class="contact-group" *ngFor="let contact of person.relatedContacts">
                    <div>
                        <img src="{{ contact.img }}" />
                    </div>
                    <div>
                        <p>{{ contact.supervisorName }}</p>
                        <p>{{ contact.supervisorTitle }}</p>
                    </div>
                    <div class="chevron">
                        <i class="fa fa-chevron-right"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>