<div class="desktop-filters">
    <header>
        <div class="part-one">
            <div class="relative filter">
                <img src="../../../assets/global/filter.png" />
            </div>
            <div class="title">
                <p>Filter</p>
            </div>
            <div class="close" (click)="panelToggle()">
                <img src="../../../assets/dept-name/closeFilterPanel.svg" />
            </div>
        </div>
        <div class="part-two">
            <div>
                <div class="icon-text-group">
                    <div>
                        <i class="fas fa-th"></i>
                    </div>
                    <div class="name">
                        <p>All</p>
                    </div>
                </div>
                <div class="icon-text-group">
                    <div>
                        <i class="fas fa-star"></i>
                    </div>
                    <div class="name">
                        <p>Favorited</p>
                    </div>
                </div>
                <div class="icon-text-group">
                    <div>
                        <i class="fas fa-dollar-sign"></i>
                    </div>
                    <div class="name">
                        <p>Top Spenders</p>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <div class="content">
        <div class="group-wrapper" *ngFor="let item of groupData; let i = index">
            <div class="chevron">
                <i class="fa fa-chevron-right vhCenter"></i>
            </div>
            <div>
                <p>{{ item.title }}</p>
            </div>
            <div class="selection-row" *ngFor="let val of item.values">
                <div class="faux-radio-btn" [ngClass]="{'selected-row':val.selected}" [ngClass]="{'selected-row':val.element === selectedItems[i]}">
                    <div [ngClass]="{'active-item':val.element === selectedItems[i]}"></div>
                </div>
                <div class="amount">{{ val.element }}</div>
                <button (click)="selectRow(i, $event)"></button>
            </div>
        </div>
    </div>
    <footer>
        <div class="btn-group">
            <div class="save">
                <button>Save</button>
            </div>
            <div class="clear" (click)="clearAll()">
                <button>Clear</button>
            </div>
        </div>
    </footer>
</div>