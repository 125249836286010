import { ChangeDetectorRef, Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { Chart } from 'chart.js';
import * as chartJson from './chart-data.json';

@Component({
  selector: 'bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit {

  chart: any;
  chartDataset: any = [];

  @ViewChild('chartRef', { static: false }) chartRef: ElementRef;
  @Input() chartColors: string[] = ["#81D36B", "#ACACAC"];
  @Input() chartData:any;

  constructor(
    private _changeDetect:ChangeDetectorRef
  ) {}

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.createBarChart();
    this._changeDetect.detectChanges()
  }

  ngOnChanges(){
    if(this.chart != undefined){
      this.chart.chart.config.data.datasets[0].data = this.chartData[0].data;
      this.chart.chart.config.data.datasets[1].data = this.chartData[1].data;
      this.chart.update()
    }
  }

  createBarChart() { 
    let ctx:any;
        ctx = this.chartRef.nativeElement;
    this.chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: this.chartData[0].labels,
        datasets: [{
          barThickness: 15,
          data: this.chartData[0].data,
          backgroundColor: [
            'rgba(92,197,235)',
            'rgba(92,197,235)',
            'rgba(92,197,235)'
          ],
          stack: 'Stack 0',
        },
        {
          barThickness: 15,
          data: this.chartData[1].data,
          backgroundColor: [
            'rgba(2,58,104)',
            'rgba(2,58,104)',
            'rgba(2,58,104)'
          ],
          stack: 'Stack 1',

        }]
      },
      options: {
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 5,
            right: 5,
            top: 5,
            bottom: 15
          }
        },
        scales: {
          xAxes: [{
            gridLines: {
              display: false,
            }
          }],
          yAxes: [{
            gridLines: {
              display: true,
              tickMarkLength: 0,
              drawBorder: false
            },
            ticks: {
              max: 15,
              min: 0,
              stepSize: 5,
              suggestedMin: 0,
              suggestedMax: 15,
              callback: function (label, index, labels) {
                switch (label) {
                  case 0:
                    return '';
                  case 5:
                    return '5M  ';
                  case 10:
                    return '10M  ';
                  case 15:
                    return '15M  ';

                }
              }
            }
          }]
        }
      }
    })
  }

  createDataset(array) {
    let datasets = []
    array.forEach((item, i) => {
      datasets.push({
        barPercentage:.5,
        categoryPercentage:1.2,
        data: item.data,
        backgroundColor: this.chartColors[i],
        stack: i
      })
    })
    return datasets
  }


}
