<div class="wrapper">
    <div *ngIf="
        currentTab === 'general' ||
        currentTab === 'security' ||
        currentTab === 'notifications'
    ">
        <div>
            <h2>Settings</h2>
        </div>
        <div class='search-container'>
            <search-bar [placeholder]="'Search Settings'" [filterless]="true"></search-bar>
        </div>
        <div class="relative">
            <button class="close" (click)="closePanel()">
                <div class="vhCenter">&#10005;</div>
            </button>
        </div>
    </div>
    <div class="navigation" *ngIf="
        currentTab === 'general' ||
        currentTab === 'security' ||
        currentTab === 'notifications'
    ">
        <div>
            <p [class.current-tab]='currentTab === "general"' (click)='changeTab("general")'>General</p>
        </div>
        <div>
            <p [class.current-tab]='currentTab === "security"' (click)='changeTab("security")'>Security</p>
        </div>
        <div>
            <p [class.current-tab]='currentTab === "notifications"' (click)='changeTab("notifications")'>Notifications</p>
        </div>
    </div>

    <div id='general-settings-tab' *ngIf='currentTab === "general"'>
        <form>
            <div>
                <p>Name</p>
                <input value="{{ user.name }}" />
            </div>
            <div>
                <p>User Name</p>
                <input value="{{ user.username }}" />
            </div>
            <div>
                <p>Position</p>
                <input value="{{ user.position }}" />
            </div>
            <div>
                <p>Email</p>
                <input value="{{ user.email }}" />
            </div>
            <div>
                <p>Profile Picture</p>
                <div>
                    <div>
                        <img src="../../../assets//global/settings.png" />
                    </div>
                    <p>
                        Government_ID_Official.jpg
                    </p>
                    <div>
                        <div>
                            <i class="fas fa-arrow-up"></i>
                            <p>Update Photo</p>
                        </div>
                        <div>
                            <i class="fas fa-trash"></i>
                            <p>Remove Photo</p>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div id='security-settings-tab' *ngIf='currentTab === "security"'>
        <form>
            <div>
                <p>Your Password</p>
                <p>{{ user.password }}</p>
                <p class='change-info-button' (click)='changeTab("password")'>
                    <i class="fas fa-arrow-up"></i> Change Password
                </p>
            </div>
            <div>
                <p>Your Email</p>
                <p>{{ user.email }}</p>
                <p class='change-info-button' (click)='changeTab("email")'>
                    <i class="fas fa-arrow-up"></i> Update Email
                </p>
            </div>
            <div>
                <p>Your Phone Number</p>
                <p>{{ user.phoneNumber }}</p>
                <p class='change-info-button' (click)='changeTab("phone")'>
                    <i class="fas fa-arrow-up"></i> Update Phone Number
                </p>
            </div>
        </form>
    </div>

    <div id='notifications-settings-tab' *ngIf='currentTab === "notifications"'>
        <form>
            <div class='notifications-toggles'>
                <div>
                    <span>Desktop</span>
                    <div class='toggle' [ngClass]="{'switched-off': !user.notificationSettings.desktop}" (click)='toggleSwitch("desktop")'>
                        <div class='switch'></div>
                    </div>
                </div>
                <div>
                    <span>Email</span>
                    <div class='toggle' [ngClass]="{'switched-off': !user.notificationSettings.email}" (click)='toggleSwitch("email")'>
                        <div class='switch'></div>
                    </div>
                </div>
                <div>
                    <span>Mobile</span>
                    <div class='toggle' [ngClass]="{'switched-off': !user.notificationSettings.mobile}" (click)='toggleSwitch("mobile")'>
                        <div class='switch'></div>
                    </div>
                </div>
            </div>
            <div class='notification-type-select'>
                <p>Notification Type</p>
                <label class="radio_btn">All
                    <input type="radio" checked="checked" name="radio">
                    <span class="checkmark"></span>
                  </label>
                <label class="radio_btn">New Contract Awarded
                    <input type="radio" name="radio">
                    <span class="checkmark"></span>
                  </label>
                <label class="radio_btn">Remaining Budget is less than 10% of total budget
                    <input type="radio" name="radio">
                    <span class="checkmark"></span>
                  </label>
                <label class="radio_btn">Large purchase (over $1,000,000.00)
                    <input type="radio" name="radio">
                    <span class="checkmark"></span>
                  </label>
            </div>
        </form>
    </div>

    <div id='password-tab' *ngIf='currentTab === "password"'>
        <form>
            <div class='sub-tab-header'>
                <div (click)='changeTab("security")'><i class="fa fa-chevron-left"></i></div>
                <h2 class='sub-tab-title'>Change Password</h2>
            </div>

            <div class='current-info'>
                <span>Current Password</span>
                <span>{{ user.password }}</span>
            </div>

            <div class='new-info-input'>
                <label for='current-password'>Enter Current Password</label>
                <input type='password' id='current-password'>
                <p class='input-info'>
                    Or send a temporary password to my email or phone Number
                    <i class="fa fa-chevron-right"></i>
                </p>
            </div>

            <div class='new-info-input'>
                <label for='new=password'>Create New Password</label>
                <input type='password' id='current-password'>
            </div>

            <div class='new-info-input'>
                <label for='confirm-password'>Confirm Password</label>
                <input type='password' id='confirm-password'>
            </div>

        </form>
    </div>

    <div id='email-tab' *ngIf='currentTab === "email"'>
        <form>
            <div class='sub-tab-header'>
                <div (click)='changeTab("security")'><i class="fa fa-chevron-left"></i></div>
                <h2 class='sub-tab-title'>Update Email</h2>
            </div>

            <div class='current-info'>
                <span>Current Email</span>
                <span>{{ user.email }}</span>
            </div>

            <div class='new-info-input'>
                <label for='current-password-email'>Enter Current Password</label>
                <input type='password' id='current-password-email'>
                <p class='input-info'>
                    Or send a temporary password to my email or phone Number
                    <i class="fa fa-chevron-right"></i>
                </p>
            </div>

            <div class='new-info-input'>
                <label for='update-email'>Update Email</label>
                <input id='update-email'>
            </div>

        </form>
    </div>

    <div id='phone-tab' *ngIf='currentTab === "phone"'>
        <form>
            <div class='sub-tab-header'>
                <div (click)='changeTab("security")'><i class="fa fa-chevron-left"></i></div>
                <h2 class='sub-tab-title'>Update Phone Number</h2>
            </div>

            <div class='current-info'>
                <span>Current Phone Number</span>
                <span>{{ user.phoneNumber }}</span>
            </div>

            <div class='new-info-input'>
                <label for='current-password-phone'>Enter Current Password</label>
                <input type='password' id='current-password-phone'>
                <p class='input-info'>
                    Or send a temporary password to my email or phone Number
                    <i class="fa fa-chevron-right"></i>
                </p>
            </div>

            <div class='new-info-input'>
                <label for='update-phone'>Update Phone Number</label>
                <input id='update-phone'>
            </div>

            <div class='phone-toggle'>
                <span>Send Notifications to this Phone Number</span>
                <div class='toggle' [ngClass]="{'switched-off': !user.notificationSettings.sendToPhone}" (click)='toggleSwitch("sendToPhone")'>
                    <div class='switch'></div>
                </div>
            </div>

        </form>
    </div>

    <div class="save-cancel">
        <div class="btn-group">
            <div>
                <button (click)='closePanel()' type="button" class="conseqta-btn">Save</button>
            </div>
            <div>
                <button (click)='closePanel()' type="button">Cancel</button>
            </div>
        </div>
    </div>
</div>