import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-sidebar-item',
  templateUrl: './sidebar-item.component.html',
  styleUrls: ['./sidebar-item.component.scss']
})
export class SidebarItemComponent implements OnInit {

  @Input() vendorName:any;
  @Input() amount:any;
  @Input() expenseName:any;
  @Input() span:any

  constructor() { }

  ngOnInit(): void {}

}
