<div #wrapper id="trendsChart" [ngClass]="{
    'landing-pg-desktop': currentRoute === '/landing' && screenSize > 1099,
    'landing-pg-tablet-browser': currentRoute === '/landing' && screenSize > 675 && screenSize < 1101 && !isIPad,
    'landing-pg-tablet-device': currentRoute === '/landing' && screenSize > 675 && screenSize < 1101 && isIPad,
    'landing-pg-mobile': currentRoute === '/landing' && screenSize < 676,
    'expenditures-tablet-device':currentRoute === '/expenditures' && isIPad && screenSize > 675,
    'commitments-obligations': currentRoute === '/commitments' || currentRoute === '/obligations' && screenSize < 1101,
    'vendors-drilldown':currentRoute === '/vendors-drilldown-overview' && screenSize > 1100
}">
    <div #slider class="slider">
        <canvas #trendsCanvas id="myCanvas"></canvas>
        <div #chartValues id="values"></div>
    </div>
</div>