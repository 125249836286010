<div id="calendar">
    <div class="inputs">
        <div id="start-date" [ngClass]="{'active-date':!startDateChosen}" (click)="reset()">{{ startDate }}</div>
        <div class="dash">&mdash;</div>
        <div id="end-date" [ngClass]="{'active-date':startDateChosen}" (click)="setEndDate()">{{ endDate }}</div>
    </div>
    <div class="navigation">
        <div (click)="monthBackward()"><i class="fa fa-chevron-left"></i></div>
        <div (click)="monthForward()"><i class="fa fa-chevron-right"></i></div> 
    </div>
    <div class="month-wrapper">
        <div class="month">
            <div class="month-yr">
                <span class="current-mo">{{ months[cVarMo] }}</span>&nbsp;
                <span class="current-yr">{{ currentYear }}</span>
            </div>
            <div class="days">
                <p *ngFor="let day of days; let i = index">{{day}}</p>
            </div>
            <div id="numbers">
                <div *ngFor="let n of divs; let i = index" #currentElems (click)="getCD($event,cVarMo)"></div>
            </div>
        </div>
        <div class="month">
            <div class="month-yr">
                <span class="current-mo">{{ months[nVarMo] }}</span>&nbsp;
                <span class="current-yr">{{ nextYear }}</span>
            </div>
            <div class="days">
                <p *ngFor="let day of days; let i = index">{{day}}</p>
            </div>
            <div id="numbers">
                <div *ngFor="let n of divs; let i = index" #nextElems (click)="getCD($event,nVarMo)"></div>
            </div>
        </div>
    </div>
</div>