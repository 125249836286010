import { Injectable, ViewChild } from '@angular/core';
import { ModalBgService } from './modal-bg.service';

@Injectable({
    providedIn: 'root'
})

export class GlobalFnService {

    randomNumber: number;
    sidebarVisible: any;
    pageData: any = [];
    gridAreaNames: any;
    deptFilters: any;

    constructor(
        public _modalBG: ModalBgService
    ) { }

    // Open Table Filters
    transparentBG(e: any) {
        this._modalBG.panelStatus[3].filters.value = true;
        this._modalBG.changeValue(3, 'filters', this._modalBG.panelStatus[3].filters.value);
    }

    sidebarBG(component: any) {
        component != undefined ? component.closeAllMenus() : '';
        this._modalBG.panelStatus[4].sidebar.value = true;
        this._modalBG.changeValue(4, 'sidebar', this._modalBG.panelStatus[4].sidebar.value);
    }

    //Scroll to top of screen when clicked
    scrollTop() {
        window.scroll(0, 0);
    }

    // Random Num Between
    randomize() {
        var x = Math.ceil(Math.random() * 100);
        this.randomNumber = x;
    }

    // Toggle Sidebars
    sidebarToggle(e: any) {
        this.sidebarVisible = e;
        this._modalBG.closeAll()
        return this.sidebarVisible;
    }

    //Change Information on Drop Down Menu change
    changeDD(arr) {
        arr = [arr[arr.length - 1], ...arr]
        arr.length = arr.length - 1
        return arr;
    }

    // Change Area Chart Data on DD Menu Click for Expenditures, Obligations, & Commitments
    randomizeChartData(comp: any) {
        for (var j = 0; j < comp.chartsArray.length; j++) {
            for (var i = 0; i < comp.chartsArray[j].length; i++) {
                if (comp.chartsArray[j][i] != null) {
                    comp.chartsArray[j][i] = Math.floor(Math.random() * 100);
                }
            }
        }
        comp.lineChart.update();
    }

    shuffleArray(arr: any) {
        for (let i = arr.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [arr[i], arr[j]] = [arr[j], arr[i]];
        }
    }

    // Randomize Doughnut Chart Data for Expenditures, Obligations, & Commitments
    genNewDoughnutData(comp: any) {
        for (var i = 0; i < comp.dataArray.length; i++) {
            comp.dataArray[i] = Math.floor(Math.random() * 30)
        }
        comp.chart.update();
    }

    //Return a number as a string with proper comma placement
    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    abrevNum(num) {
        let numArray = num.split(',');
        let letterArray = ['', 'k', 'M', 'B', 'T', 'Q'];
        return numArray[0] + letterArray[numArray.length];
    }

    // Create Table Grid Template Areas
    createTableGridArea(data: any) {
        if (data != undefined) {
            var colCount = "";
            for (var i = 1; i <= Object.keys(data).length - 1; i++) {
                if (Object.keys(data)[i].charAt(0) != "_") {
                    colCount += 'col' + i + " ";
                }
            }
            colCount = "'" + colCount + "'";
            this.gridAreaNames = colCount;
            return this.gridAreaNames
        }
    }

    // Create Filter Grid Template Areas. Fires on Page Load
    createFilterGridArea(comp: any, data: any) {
        if (data != undefined) {
            var colCount = "";
            for (var i = 1; i <= Object.keys(data).length - 1; i++) {
                if (Object.keys(data)[i].charAt(0) != "_") {
                    colCount += "col" + i + "''"
                }
            }
            colCount = "'" + colCount.slice(0, colCount.length - 1);
            comp.rowOrder = colCount;
            this.deptFilters = comp.rowOrder;
        }
    }

    // Update Table on Column Hide/Show     
    changeColumnVisibility(arr: any) {
        var str: any = "";
        for (var i = 0; i < arr.length; i++) {
            str += arr[i] + " "
        }
        str = "'" + str + "'";
        this.gridAreaNames = str;
    }

    // Change Stacking Filter Grid
    resetTable(val: any) {
        var str: any = "";
        for (var i = 1; i <= val.length; i++) {
            str += 'col' + i + " ";
        }
        str = "'" + str.slice(0, str.length - 1) + "'";
        this.gridAreaNames = str;
    }

    altReset() {
        setTimeout(() => {
            let val = [];
            val = Array.from(document.querySelectorAll('.body-columns > div'));
            var str: any = "";
            for (var i = 1; i <= val.length; i++) {
                str += 'col' + i + " ";
                val[i] != undefined ? val[i].classList.remove('displayNone') : '';
            }
            str = "'" + str.slice(0, str.length - 1) + "'";
            this.gridAreaNames = str;
        }, 150)
    }

    // Create Array for Table Columns
    restoreColumns() {
        var elem = Array.from(document.querySelectorAll(".body-columns > div")),
            colGroup: any = [];
        for (var i = 0; i < elem.length; i++) {
            elem[i].classList.remove('displayNone');
            colGroup.push(elem[i].getAttribute("data-col"));
        }
        this.resetTable(colGroup)
    }
}