<div id="main-content" data-page="spending-milestone">
    <div class="header-content">
        <div class="page-name">
            <page-name [pageName]='"Spending Milestone"'></page-name>
        </div>
        <div class="dropdown" *ngIf="screenSize > 1100">
            <dropdown [options]="dropdownOptions" (ddInfo)='changeDD()'></dropdown>
        </div>
    </div>

    <div class="page-content">
        <section class='milestone-table p-shadow p-radius white-BG'>
            <div class='spending-allocation-title'>
                <p>Spending Allocation Percent</p>
                <p>{{ sliderValue }}%</p>
            </div>

            <div class="slider">
                <mat-slider (input)="sliderMove($event)" (change)="sliderUpdate($event)" [value]="sliderValue" [step]=".5"></mat-slider>
            </div>

            <div id="view-btns" class='btn-group' *ngIf='screenSize <= 1100'>
                <btn-group [btns]='["1D", "1W", "1M", "3M", "6M", "1Y"]' (click)="changeDD()"></btn-group>
            </div>
            <dept-table [data]="tableData" [itemsPerPageInput]="10" [firstColumnWidth]="200" [pageValues]="_fn.gridAreaNames" [pageLink]="'department-drilldown'"></dept-table>
        </section>
    </div>
</div>