<div id="department-contact-info" class="show-department-contact">
    <div class="contact-card" [ngClass]="{'show-contact-card': screenSize < 676 && deptContactCard}">
        <div class="header">
            <div>
                <p>Department Contact</p>
                <p>One Contact</p>
            </div>
            <div class="close" (click)="closeSubMenu()">
                <img src="../../../assets/dept-name/closeFilterPanel.svg" />
            </div>
        </div>
        <div id="contact-card-content">
            <div id="global-search-contact">
                <div class="sub-header">
                    <div>
                        <img src="../../../assets//global/bobevans.png" />
                    </div>
                    <div>
                        <p>Joe Shmoe</p>
                        <p>CFO of Chickfila</p>
                    </div>
                    <div class="relative btn-group-wrap">
                        <div class="btn-group" [ngClass]="{'vCenter': screenSize > 1100}">
                            <button class="conseqta-btn">
                                <i class="fas fa-envelope"></i>
                                <div>Email</div>
                            </button>
                            <button class="conseqta-btn">
                                <i class="fas fa-phone-alt"></i>
                                <div>Call</div>
                            </button>
                            <button class="conseqta-btn">
                                <i class="fas fa-copy"></i>
                                <div>Copy Info</div>
                            </button>
                            <button class="conseqta-btn">
                                <i class="fas fa-save"></i>
                                <div>Save Info</div>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="content">
                    <div class="columnOne">
                        <div>
                            <div class="detail-grid">
                                <p>Org Title</p>
                                <p>Organization 1</p>
                            </div>
                            <div class="detail-grid">
                                <p>Org Code</p>
                                <p>93240820492</p>
                            </div>
                            <div class="detail-grid">
                                <p>Email</p>
                                <p>fullname@gmail.com</p>
                            </div>
                            <div class="detail-grid">
                                <p>Phone</p>
                                <p>342-322-1135</p>
                            </div>
                            <div class="detail-grid">
                                <p>Address</p>
                                <p>
                                    123 Muffin Lane,<br> Something VA, 20147
                                </p>
                            </div>
                            <div class="detail-grid">
                                <p>Supervisor Name</p>
                                <p><a href="">Supervisor's Name</a></p>
                            </div>
                            <div class="detail-grid">
                                <p>Supervisor Email</p>
                                <p><a href="">supervisor@aol.com</a></p>
                            </div>
                            <div class="detail-grid">
                                <p>Supervisor Phone</p>
                                <p><a href="tel:340-229-2332">340-229-2332</a></p>
                            </div>
                        </div>
                    </div>
                    <div class="columnTwo">
                        <div class="related-contacts">
                            <i class="fa fa-user"></i>
                            <p>Related Contacts</p>
                        </div>
                        <div>
                            <div class="contact-group" *ngFor="let contact of contactGroup">
                                <div>
                                    <img src="{{ contact.img }}" />
                                </div>
                                <div>
                                    <p>{{ contact.supervisorName }}</p>
                                    <p>{{ contact.supervisorTitle }}</p>
                                </div>
                                <div class="chevron">
                                    <i class="fa fa-chevron-right"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>