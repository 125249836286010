<div class="repeating-element p-radius p-shadow white-BG">
    <div>
        <p class="vendor-name-blue">{{ vendorName }}</p>
        <p>{{ amount }}</p>
        <div class="relative">
            <i class="fa fa-chevron-right vCenter"></i>
        </div>
    </div>
    <div>
        <p>{{ expenseName }}</p>
        <p>{{ span }}</p>
    </div>
    <div class="btn-mask"></div>
</div>