import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalBgService {

  panelStatus = [
    { "notifications": { value: false }},
    { "userProfile":   { value: false }},
    { "settings":      { value: false }},
    { "filters":       { value: false }},
    { "sidebar":       { value: false }},
    { "contact":       { value: false }}
  ]

  modalActive: boolean = false;
  status: boolean;

  private valueSource = new BehaviorSubject(this.panelStatus);
  currentVal = this.valueSource.asObservable();

  changeValue(index: number, name:any, val: boolean) {
    for(var i = 0; i < this.panelStatus.length; i++){
      Object.values(this.panelStatus[i])[0].value = false
    }
    this.panelStatus[index][name].value = val;
    
    for(var i = 0; i < this.panelStatus.length; i++){
      if( Object.values(this.panelStatus[i])[0].value ){
        this.modalActive = true;
        return this.modalActive
      } else {
        this.modalActive = false;
      }
    }
  }

  closeAll(){
    for(var i = 0; i < this.panelStatus.length; i++){
      Object.values(this.panelStatus[i])[0].value = false
    }
    this.modalActive = false;
  }

  constructor() { }
}
