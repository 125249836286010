import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AllDepartmentsComponent } from '../all-departments/all-departments.component';

const routes: Routes = [
  { path: '', component: AllDepartmentsComponent, children: [
      { path:'departments', component: AllDepartmentsComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DepartmentsRoutingModule { }

export const departmentsRoutingComponents = [
  AllDepartmentsComponent
]

