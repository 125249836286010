<div id="main-content" class='dept-page' data-page="dept-drilldown">
    <div class="header-content">
        <div class="page-name">
            <page-name [pageName]='"Department of Anti-Trust and Fraud"'></page-name>
        </div>
        <div class='top-button-container'>
            <button class="conseqta-btn conseqta-btn-reverse" [routerLink]="['/credit-card-transactions']">Card
                Trans.</button>
            <button class="conseqta-btn conseqta-btn-reverse"
                [routerLink]="['/comparison-report-select']">Compare</button>
            <button class="conseqta-btn" (click)="openContact()">Contact</button>
        </div>
        <div class='content-control-buttons'>
            <btn-group [btns]="screenSize > 675 ? btnNames : mobileBtnNames" (click)="viewChange($event)"></btn-group>
        </div>
    </div>

    <div class="page-content">

        <div class='row-two' *ngIf="viewSelection === 'Overview'">
            <section class='remaining-budget top-panel p-shadow white-BG p-radius'>
                <budget-graphs></budget-graphs>
            </section>

            <div class='desktop-ss-title' *ngIf="screenSize > 1100">
                <span>FY22 Spending Summary</span>
                <search-bar [placeholder]="'Search Departments'" (filterStatus)="_fn.transparentBG($event)">
                </search-bar>
            </div>
        </div>

        <section class='vendors-layout' *ngIf="viewSelection === 'Vendors'">
            <div class="top-panel p-radius p-shadow white-BG">
                <div class='vendor-number'>
                    <p>Dept. Contracts - {{ vendorTab }}</p>
                    <span>34</span><span>Vendors</span>
                </div>
                <div class='expiring'>
                    <div class='bars'>
                        <div class='top bar'></div>
                        <div class='mid bar'></div>
                        <div class='bot bar'></div>
                    </div>
                    <p>3 Expiring in the next 90 days</p>
                </div>
            </div>
        </section>

        <section class='contracts-layout' *ngIf="viewSelection === 'Contracts'">
            <div class="top-panel p-radius p-shadow white-BG" [routerLink]="['/contract-spending']">
                <div class='breakdown-header'>
                    Spending Breakdown
                </div>
                <div class='spent-values'>
                    <div>
                        <p>$540,000</p>
                        <p>Spent This Month</p>
                    </div>
                    <div>
                        <p>$500,000</p>
                        <p>Spent Last Month</p>
                    </div>
                </div>
                <i class="fa fa-chevron-right"></i>
            </div>
        </section>

        <section
            [ngClass]="{'assets-layout': viewSelection === 'Assets', 'subdepartments-layout': viewSelection === 'Subdepartments'}"
            *ngIf="viewSelection === 'Assets' || viewSelection === 'Subdepartments'">

            <div class="top-panel p-padding p-radius p-shadow white-BG"
                [routerLink]="[viewSelection === 'Subdepartments' ? '/sub-dept-analysis' : '/vendor-breakdown']">
                <div class='chevron-box'>
                    <i class="fa fa-chevron-right"></i>
                </div>
                <div class='top-title'>
                    Spending Breakdown
                </div>
                <div class='pie-and-table'>
                    <div class='chart-wrapper'>
                        <div>
                            <doughnut-chart [cutOutPercent]="70"></doughnut-chart>
                        </div>
                    </div>

                    <div class='chart-legend' *ngIf="viewSelection === 'Assets'">
                        <div class='legend-row' *ngFor="let item of assetLegendInfo">
                            <div class='chart-name'>
                                <div class='legend-color' [style.background-color]="item.color"></div>
                                <p>{{ item.name }}</p>
                            </div>
                            <div class='chart-info'>
                                <p>{{ item.percent }}</p>
                                <p>{{ item.cash }}</p>
                            </div>
                        </div>
                    </div>

                    <div class='chart-legend' *ngIf="viewSelection === 'Subdepartments'">
                        <div class='legend-row' *ngFor="let item of subdeptsLegendInfo">
                            <div class='chart-name'>
                                <div class='legend-color' [style.background-color]="item.color"></div>
                                <p>{{ screenSize > 1100 ? item.name : item.initials }}</p>
                            </div>
                            <div class='chart-info'>
                                <p>{{ item.cash }}</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </section>

        <!-- Begin box containing table in all views -->
        <section [ngClass]="{'spending-summary': viewSelection === 'Overview'}" class="p-radius p-shadow white-BG">
            <!-- Begin overview layout -->
            <div class="data-selection" *ngIf="viewSelection === 'Overview'">
                <div class="header">
                    <p *ngIf='screenSize <=  1100'>
                        FY22 Spending Summary
                    </p>
                    <div class="btn-group">
                        <btn-group [btns]="overviewBtns" (clickEvent)="tabChange($event)"></btn-group>
                    </div>
                </div>
                <div class='doughnut-scroll-wrapper'>
                    <doughnut-scroll [backgroundColors]='backgroundColors' [data]="tableData[tableIndex]"
                        [dataArray]="chartData[tableIndex]"></doughnut-scroll>
                </div>
                <div class='table-mobile-searchbar' *ngIf="screenSize <= 1100">
                    <search-bar [placeholder]="'Search Departments'" (filterStatus)="_fn.transparentBG($event)">
                    </search-bar>
                </div>
            </div>
            <!-- End overview layout -->

            <!-- Begin vendors/contracts layout -->
            <div class='search-btn-bar' *ngIf="viewSelection === 'Vendors' || viewSelection === 'Contracts'">
                <div class='btn-container'>
                    <btn-group [btns]="['All', 'Active', 'Expired']" (click)="vendorTabChange()"></btn-group>
                </div>
                <div class='search-container'>
                    <search-bar (filterStatus)="_fn.transparentBG($event)"></search-bar>
                </div>
            </div>
            <!-- End vendors/contracts layout -->

            <!-- Begin assets/subdepartments layout -->
            <div class="assets-layout" *ngIf="viewSelection === 'Assets' || viewSelection === 'Subdepartments'">
                <div class="table-header">
                    <p>Associated Assets</p>
                    <div class='search-container'>
                        <search-bar [placeholder]="'Search Assets'" (filterStatus)="_fn.transparentBG($event)">
                        </search-bar>
                    </div>
                </div>

                <div class='range-box' *ngIf="viewSelection === 'Assets'">
                    <p>$5,000,000 — $4,000,000</p>
                </div>
            </div>
            <!-- End assets/subdepartments layout -->

            <!-- Table for every view -->
            <div class="table-comp">
                <dept-table [abreviations]="abreviationArray" [itemsPerPageInput]='7'
                    [backgroundColors]='backgroundColors' [data]="tableData[tableIndex]"
                    [pageValues]="_fn.gridAreaNames" [pageLink]="tableLinks[tableIndex]"></dept-table>
            </div>
        </section>
        <!-- End box containing table in all views -->
    </div>
    <div id="filter-container" [ngClass]="{'show-filters': _modalBG.panelStatus[3].filters.value}">
        <dept-filters [filterList]="tableComponent.columnNames" [pageValues]="_fn.gridAreaNames"
            [data]="tableData[tableIndex]" (columnNamesEvt)="receiveNewColNames($event)"
            (abreviationEvt)="getNewData($event)"></dept-filters>
    </div>

    <div id="contact-container" class="contact-card"
        [ngClass]="{'show-filters': _modalBG.panelStatus[5].contact.value}">
        <contact-card></contact-card>
    </div>

</div>