<div id="graph-table-component">
    <div>
        <div class="data-row" *ngFor="let item of chartColor; let i = index" [ngClass]="{
            'home-pg-data-row': screenSize > 675 && currentPage === '/landing' || currentPage === '/name-of-dept'
        }">
            <div class="color" [ngStyle]="{'background-color': item}"></div>
        </div>
    </div>
    <div>
        <div class="data-row" *ngFor="let item of chartCategories; let i = index" [ngClass]="{
            'home-pg-data-row': screenSize > 675 && currentPage === '/landing' || currentPage === '/name-of-dept'
        }">
            <div class="title">{{item}}</div>
        </div>
    </div>
    <div>
        <div class="data-row" *ngFor="let item of chartAmount; let i = index" [ngClass]="{
            'home-pg-data-row': screenSize > 675 && currentPage === '/landing' || currentPage === '/name-of-dept'
        }">
            <div class="title amount">{{item}}</div>
        </div>
    </div>
</div>