<div #btnGroup class="fav-compare-contact" *ngIf="userRoute === '/name-of-dept' || userRoute === '/sub-dept-analysis' && this.windowWidth < 1101">
    <div class="favorite" (click)='toggleFavorite()'>
        <span [class.hidden]='favorited'><i class="far fa-star"></i></span>
        <span [class.hidden]='!favorited'><i class="fas fa-star blue-star"></i></span>
        <p class="margin0 vhCenter" *ngIf="windowWidth > 675 && windowWidth < tabletBreakpoint">Favorite</p>
    </div>
    <div class="compare relative">
        <p class="vhCenter margin0 width100">{{ windowWidth > tabletBreakpoint ? 'Compare' : 'Compare Department' }}</p>
    </div>
    <div *ngIf="windowWidth < tabletBreakpoint"></div>
    <button class="contact" (click)="contactCardToggle()">
        <i class="fas fa-user-circle"></i>
        <p class="margin0">Contact</p>
    </button>
</div>