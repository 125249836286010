import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SubDeptAnalysisComponent } from '../sub-dept-analysis/sub-dept-analysis.component';

const routes: Routes = [
  { path: '', component: SubDeptAnalysisComponent, children: [
      { path:'sub-dept-analysis', component: SubDeptAnalysisComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SubDeptAnalysisRoutingModule { }

export const subDeptAnalysisRoutingComponents = [
  SubDeptAnalysisComponent
]

