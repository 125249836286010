import { AfterContentInit, ChangeDetectorRef, Component, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import * as tableData from './departments.json';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from 'rxjs';
import { ModalBgService } from 'src/app/services/modal-bg.service';
import { DeptTableComponent } from '../../components/_reusable/dept-table/dept-table.component';
import { GlobalFnService } from 'src/app/services/global-functions.service';
import { DeptFiltersComponent } from 'src/app/components/dept-filters/dept-filters.component';

@AutoUnsubscribe()

@Component({
  selector: 'all-departments',
  templateUrl: './all-departments.component.html',
  styleUrls: ['./all-departments.component.scss']
})
export class AllDepartmentsComponent implements OnInit, OnDestroy {

  protected subscription2$: Subscription;

  result: any = [];
  p: any;
  itemsPerPage = 12;
  windowWidth: any;
  dataIndex: number;
  tableData = tableData.tableData;
  updateTable: boolean = true;
  tableIndex: number = 0;
  tableValue: any = [];
  tableArray: any = [];
  abreviationArray:any = [];

  // Get Column Names from Table
  @ViewChild(DeptTableComponent) tableComponent: any = [];
  columnNames: string;
  @Output() visibleFilters: any = this.tableComponent.columnNames;
  @Output() gridAreaNames: any;
  filterStatus: any;

  @ViewChild(DeptFiltersComponent) dFilters:any;

  constructor(
    public _modalBG: ModalBgService,
    private _cd: ChangeDetectorRef,
    public _fn: GlobalFnService
  ) { }

  ngOnInit(): void {
    this.windowWidth = window.innerWidth;
    this.subscription2$ = this._modalBG.currentVal.subscribe(currentVal => this.filterStatus = currentVal);

    for (let key in tableData.tableData) {
      if (tableData.tableData.hasOwnProperty(key)) {
        this.tableArray.push(tableData.tableData[key])
      }
    }
  }

  ngAfterViewInit(){
    this._fn.createTableGridArea(this.tableArray[0] );
    this._fn.createFilterGridArea(this.dFilters, this.tableArray[0]);
    this._cd.detectChanges()
  }

  ngOnDestroy() { }

  // Create new grid-template-area
  receiveNewColNames(e: any) {
    this.gridAreaNames = "";
    for (var i = 0; i < e.length; i++) {
      this.gridAreaNames += e[i] + " "
    }
    this.gridAreaNames = "'" + this.gridAreaNames + "'";
  }

  // Receive from filters, send full/abreviation to table (column 1)
  getNewData(e:any){
    if(e){
      for(var i = 0; i < this.tableArray.length; i++){
        this.abreviationArray.push(this.tableArray[i]._abreviation )
      }
    } else {
      this.abreviationArray = []
    }
  }
}
