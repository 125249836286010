import { Component, OnInit, ViewChild, ElementRef, Input, HostListener, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Chart } from 'chart.js';
import { CurrentRouteService } from 'src/app/services/current-route.service';
import { WindowWidthService } from 'src/app/services/windowWidth.service';
import * as chartValues from './area-chart.json';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from 'rxjs';

@AutoUnsubscribe()

@Component({
  selector: 'app-area-line-chart',
  templateUrl: './area-line-chart.component.html',
  styleUrls: ['./area-line-chart.component.scss']
})
export class AreaLineChartComponent implements OnInit, OnDestroy {

  protected subscription1$: Subscription;
  protected subscription2$: Subscription;

  lineData: any;
  lineChart: any;
  lineOptions: any;
  currentRoute: string;
  screenSize: number;
  isIPad: any;
  lineColor: any;
  chartWidth: number;
  jsonValues: any = [];

  // Populate X Axis w/ Current Month
  currentYear: any = new Date().getFullYear();
  currentMonth:any = new Date().getMonth()
  months:string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', "June", "July", 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  quarters:string[] = ['Q1', '', '', '', 'Q2', "", "", '', 'Q3', '', '', ''];
  years:string[] = ['2017', '', '', '', '2018', "", "", '', '2019', '', '', ''];
  timespan:string[] = this.months;
  selectedMonth: any = this.months[this.currentMonth];

  @Input() monthData: any;
  @Input() duration: any;
  @Input() screensize: any;
  @Input() chartsArray: any = [[60, 40, 40, 64, 22, 40, 60, 180, 55, null, null, null], [90, 45, 75, 80, 40, 50, 75, 75, 40, null, null, null]]

  @ViewChild('trendsCanvas', { static: false }) trendsCanvas: ElementRef;
  @ViewChild('chartValues', { static: false }) chartValues: ElementRef;
  @ViewChild('slider', { static: false }) slider: ElementRef;
  @ViewChild('wrapper', { static: false }) wrapper: ElementRef;

  constructor(
    private _userRoute: CurrentRouteService,
    private _windowWidth: WindowWidthService,
    private _changeDetect: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.subscription1$ = this._userRoute.currentVal.subscribe(currentVal => this.currentRoute = currentVal)
    this.subscription2$ = this._windowWidth.currentVal.subscribe(currentVal => this.screenSize = currentVal);

    // Detect iPad to configure canvas height
    this.isIPad = /Macintosh/.test(navigator.userAgent) && 'ontouchend' in document;

    // Set chart width & color
    for (let key in chartValues.values) {
      if (chartValues.values.hasOwnProperty(key)) {
        this.jsonValues.push(chartValues.values[key]);
      }
    }
  }

  ngAfterViewInit() {
    this.lineData = {
      labels: this.months,
      datasets: [
        {
          label: "",
          fill: false,
          lineTension: 0.1,
          backgroundColor: "",
          borderColor: "",
          borderCapStyle: 'square',
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: "",
          pointBackgroundColor: "",
          pointBorderWidth: 2,
          pointHoverRadius: 8,
          pointHoverBackgroundColor: "rgb(247,247,247)",
          pointHoverBorderColor: "",
          pointHoverBorderWidth: 2,
          pointRadius: [0],
          pointHitRadius: 10,
          data: this.chartsArray[0],
          spanGaps: false
        }, {
          label: "",
          fill: true,
          lineTension: 0.1,
          backgroundColor: "",
          borderColor: "transparent",
          borderCapStyle: 'square',
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: "transparent",
          pointBackgroundColor: "transparent",
          pointBorderWidth: 2,
          pointHoverRadius: 8,
          pointHoverBackgroundColor: "transparent",
          pointHoverBorderColor: "transparent",
          pointHoverBorderWidth: 2,
          pointRadius: [0],
          pointHitRadius: 10,
          data: this.chartsArray[1],
          spanGaps: false
        }
      ]
    }
    this.lineOptions = {
      responsive: true,
      maintainAspectRatio: true,
      tooltips: {
        enabled: false
      },
      layout: {
        padding: { left: 5, right: 5, top: 5, bottom: 5 }
      },
      scales: {
        yAxes: [{
          display: false
        }],
        xAxes: [{
          display: false
        }]
      },
      legend: {
        display: false
      }
    }

    this.setChartValues()

    this.lineChart = new Chart(this.trendsCanvas.nativeElement, {
      type: "line",
      data: this.lineData,
      options: this.lineOptions
    });
    this.lineChart.aspectRatio = 0;
    this.createLineGraphAxis(0);

    this._changeDetect.detectChanges()
  }

  ngOnDestroy(){}

  ngOnChanges(){
    if(this.lineChart != undefined){
      this.lineChart.chart.config.data.datasets[0].data = this.chartsArray[0];
      this.lineChart.chart.config.data.datasets[1].data = this.chartsArray[1];
      this.setChartValues();
      this.createLineGraphAxis(0)
      this.lineChart.update();
    }
  }

  setChartValues() {
    for (var i = 0; i < this.jsonValues.length; i++) {
      if (this.currentRoute.slice(1) === this.jsonValues[i].pageName) {
        var cnvs = this.lineData.datasets[0];
        this.lineData.datasets[1].backgroundColor = this.jsonValues[i].fillColor;
        this.slider.nativeElement.style.width = this.jsonValues[i].width;
        this.wrapper.nativeElement.style = "margin-top:" + this.jsonValues[i].marginTop + ";height:" + this.jsonValues[i].height;
        cnvs.borderColor = cnvs.pointBorderColor = cnvs.pointBackgroundColor = cnvs.pointHoverBorderColor = this.jsonValues[i].lineColor;
        if(this.screenSize > 675 && this.screenSize < 1101 && this.jsonValues[i].tabletStyle != ""){
          this.wrapper.nativeElement.style = this.jsonValues[i].tabletStyle
        }
        if(this.screenSize < 675 && this.jsonValues[i].mobileHeight != ""){
          this.wrapper.nativeElement.style.height = this.jsonValues[i].mobileHeight;
        }
        if(this.screenSize < 675 && this.jsonValues[i].mobileHeight == "") {
          this.wrapper.nativeElement.removeAttribute('style');
        }
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setChartValues()
  }

  createLineGraphAxis(item: any) {
    if (this.duration === 'Monthly') this.timespan = this.months;
    if (this.duration === 'Quarterly') this.timespan = this.quarters;
    if (this.duration === 'Yearly') this.timespan = this.years;
    this.chartValues.nativeElement.innerHTML = '';
    if (this.screensize < 675) {
      for (var i = 0; i < 12; i++) {
        this.chartValues.nativeElement.innerHTML += '<div class="relative" style="width:' + 8.3 + '%"><div class="dotted-line"></div>' + this.timespan[0 + i] + '</div>'
      }
    } else {
      for (var i = 0; i < 12; i++) {
        this.chartValues.nativeElement.innerHTML += '<div class="relative" style="width:' + 8.3 + '%"><div class="dotted-line"></div>' + (i % 2 == 0 ? this.timespan[0 + i] : '') + '</div>'
      }
    }
  }
}
