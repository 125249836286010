<div id="financial-dashboard" (window:resize)="onResize($event)" [ngClass]="{'no-scroll':_modalBG.modalActive}">
    <div id="fin-dash-sidebar" *ngIf="windowWidth > 1100 && showSidebar">
        <app-sidebar></app-sidebar>
    </div>
    <div class="partial-width" [ngClass]="{'fullWidth' : windowWidth <= 1100 || !showHeader,'no-scroll': modal[0].activePanel || modal[1].activePanel}">
        <div #finDashContent id="fin-dash-content" [ngClass]="{'skeleton-adjustment':showHeader, 'mt-0': userCurrentRoute === '/server-connect'}">
            <router-outlet></router-outlet>
        </div>
        <div id="transparent-bg" [ngClass]="{'show-panel':_modalBG.modalActive }" (click)="closeModal()">
        </div>
        <div id="notifications" [ngClass]="{'show-notifications':modal[0]['notifications'].value }">
            <notifications></notifications>
        </div>
        <div id="user-profile-mobile" *ngIf="windowWidth < 1101" [ngClass]="{'show-profile-mobile': modal[1]['userProfile'].value}">
            <app-user-profile-mobile></app-user-profile-mobile>
        </div>

        <div id="fin-dash-settings" [ngClass]="{'show-panel': modal[2]['settings'].value}">
            <app-settings></app-settings>
        </div>

        <div id="fin-dash-header" *ngIf="showHeader" [ngClass]="{'header-exception':bigHeader}">
            <app-header></app-header>
        </div>
    </div>
</div>