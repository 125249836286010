<div class='burn-rate-container'>
    <div class="burn-rate-title">
        <i class="fas fa-fire"></i>
        <p>Burn Rate Breakdown</p>
    </div>
    <div class="burn-rate-panels">
        <div>
            <div class="panel" *ngFor="let panel of sidebarData" [routerLink]="[panel.link]">
                <p class="title">{{ panel.title }}</p>
                <p class="amount" *ngIf="panel.amount != ''">{{ panel.amount }}</p>
                <p class="item" *ngIf="panel.item != ''">{{ panel.item }}</p>
                <div class="number-total" *ngIf="panel.number != ''">
                    <span>{{ panel.number }}</span>/<span>{{ panel.total }}</span>
                </div>
                <p class="status" *ngIf="panel.status != ''">{{ panel.status }}</p>
                <div class="percent" *ngIf="panel.percent != ''">
                    <div>
                        {{ panel.percent }} Increase
                    </div>
                </div>
                <p class="status" *ngIf="panel.category != ''">{{ panel.category }}</p>
            </div>
        </div>
    </div>
</div>