import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Output, ChangeDetectorRef } from '@angular/core';
import * as rates from './rates.json';
import { WindowWidthService } from 'src/app/services/windowWidth.service';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from 'rxjs';
import { ModalBgService } from 'src/app/services/modal-bg.service';
import { DeptTableComponent } from '../../components/_reusable/dept-table/dept-table.component';
import { GlobalFnService } from 'src/app/services/global-functions.service';
import { DeptFiltersComponent } from 'src/app/components/dept-filters/dept-filters.component';

@AutoUnsubscribe()

@Component({
  selector: 'app-burn-rate-category',
  templateUrl: './burn-rate-category.component.html',
  styleUrls: ['./burn-rate-category.component.scss']
})
export class BurnRateCategoryComponent implements OnInit, OnDestroy {

  protected subscription1$: Subscription;
  protected subscription2$: Subscription;

  // Get Column Names from Table
  @ViewChild(DeptTableComponent) tableComponent: any = [];
  columnNames: string;
  @Output() visibleFilters: any = this.tableComponent.columnNames;
  @Output() gridAreaNames: any;

  tableData = rates.tableData

  p: number = 1;
  itemsPerPage = [18, 8];
  result: any = [];
  tableVal: string = 'all';
  masterDataArray: any = [];
  abreviationArray: any = [];
  settingsStatus: boolean;
  currentIndex: number;
  currentSubIndex: number;
  currentPage: HTMLElement;
  pageTitle: string;
  screenSize: number;
  filterStatus: any;

  @ViewChild('header', { static: false }) header: ElementRef;
  @ViewChild('menuGroup', { static: false }) menuGroup: ElementRef;
  @ViewChild(DeptFiltersComponent) dFilters: any;

  constructor(
    private _windowWidth: WindowWidthService,
    public _modalBG: ModalBgService,
    private _cd: ChangeDetectorRef,
    public _fn: GlobalFnService
  ) { }

  ngOnInit(): void {
    this.subscription1$ = this._windowWidth.currentVal.subscribe(currentVal => this.screenSize = currentVal);
    this.subscription2$ = this._modalBG.currentVal.subscribe(currentVal => this.filterStatus = currentVal);
    this._cd.detectChanges()
  }

  ngAfterViewInit() {
    this._fn.createTableGridArea(this.tableData[0]);
    this._fn.createFilterGridArea(this.dFilters, this.tableData[0]);
    this._cd.detectChanges()
  }

  ngOnDestroy() { }

  // Create new grid-template-area
  receiveNewColNames(e: any) {
    this.gridAreaNames = "";
    for (var i = 0; i < e.length; i++) {
      this.gridAreaNames += e[i] + " "
    }
    this.gridAreaNames = "'" + this.gridAreaNames + "'";
  }

  // Receive from filters, send full/abreviation to table (column 1)
  getNewData(e: any) {
    if (e) {
      for (var i = 0; i < this.tableData.length; i++) {
        this.abreviationArray.push(this.tableData[i]._abreviation)
      }
    } else {
      this.abreviationArray = []
    }
  }

  changeDD(e:any) {
    this.tableData = this._fn.changeDD(this.tableData)
  }

}