import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DepartmentsRoutingModule, departmentsRoutingComponents } from './all-departments-routing.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { DeptFilterDsktopComponent } from  '../../components/_reusable/dept-filter-dsktop/dept-filter-dsktop.component';
import { SharedModule } from '../../modules/shared/shared.module';

@NgModule({
  declarations: [
    departmentsRoutingComponents,
    DeptFilterDsktopComponent
  ],
  imports: [
    CommonModule,
    DepartmentsRoutingModule,
    NgxPaginationModule,
    SharedModule
  ]
})
export class AllDepartmentsModule { }
