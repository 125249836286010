<div id="{{ currentRoute === '/notifications' ? 'notifications-pg' : 'notifications' }}" data-page="notifications">
    <div *ngIf='currentRoute === "/notifications"' class="header-content">
        <div class="page-name">
            <page-name [pageName]='"Notifications"'></page-name>
            <div class='search-bar'>
                <search-bar [filterless]="true"></search-bar>
            </div>
        </div>
    </div>
    <div [ngClass]="{'alt-grid': viewTwo}">
        <div class="return-to-view-one" (click)="toggleView()" *ngIf="viewTwo && currentRoute != '/notifications'">
            <i class="fas fa-chevron-left"></i>
        </div>
        <div *ngIf="currentRoute != '/notifications'" class="notification-count">
            <p>Notifications</p>
            <div>
                {{newItems}} NEW
            </div>
        </div>
        <button (click)="closePanel()" [ngClass]="{'slide-right':viewTwo}" *ngIf="currentRoute != '/notifications'">
            <div class="vhCenter">&#10005;</div>
        </button>
    </div>
    <div class="view-one" [ngClass]="{'hidden':viewTwo}">
        <div class="loops ">
            <div class="them " *ngFor="let item of result; " (click)="toggleView() ">
                <div>
                    <div class="relative ">
                        <div class="dot " *ngIf="item.dot "></div>
                    </div>
                    <div>DEPARTMENT NAME</div>
                    <div>
                        <p>3d ago</p>
                        <i class="fas fa-chevron-up "></i>
                    </div>
                </div>
                <div>
                    New Contract Awarded
                </div>
            </div>
        </div>
    </div>
    <div class="view-two" [ngClass]="{'hidden':!viewTwo}">
        <div>
            <div>
                <div class="dept-name-time ">
                    <p [routerLink]="[ '/department-drilldown'] ">ZSA</p>
                    <p>3h ago</p>
                </div>
                <div class="percent-increase ">
                    35% increase in spending in<br>Nonpayroll Expenses
                </div>
                <p class="comparison ">
                    Last year, Dept. A spent $3,000 on water<br> bottles. This year, Dept. A spent $2,500.
                </p>
                <div class="department-contact" [routerLink]="['/global-search-contact']">
                    <i class="fas fa-user "></i>
                    <p>View Department Contact</p>
                    <i class="fas fa-chevron-right "></i>
                </div>
            </div>

            <div class="graph-section ">
                <div class="side-by-side-comparison ">
                    <div>
                        <p class="last-year">How does it compare to last year</p>
                    </div>
                    <div class="graphs ">
                        <div id="barChart">
                            <canvas id="chartCanvas"></canvas>
                        </div>
                        <div class='amount-labels'>
                            <p [style.bottom.px]="calcLabelHeight(chartData[0]) ">$200,000</p>
                            <p [style.bottom.px]="calcLabelHeight(chartData[1]) ">$301,000</p>
                        </div>
                    </div>
                    <div class="values">
                        <p>2019</p>
                        <p>2020</p>
                    </div>
                </div>
            </div>

            <div>
                <div class="general-info ">
                    <div>
                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                    </div>
                    <p>General Information</p>
                </div>
                <div class="mock-table ">
                    <div>
                        <p>Expense Name</p>
                        <p>Expense Name</p>
                    </div>
                    <div>
                        <p>PSC Code</p>
                        <p>839048204902</p>
                    </div>
                    <div>
                        <div>
                            <p>Purchase Order</p>
                            <p>389248032903</p>
                        </div>
                        <div>
                            <button class="conseqta-btn" [routerLink]="['/vendors-po-drilldown-invoices']">View</button>
                        </div>
                    </div>
                    <div>
                        <div>
                            <p>Contract No</p>
                            <p>Contract #2</p>
                        </div>
                        <div>
                            <button class="conseqta-btn" [routerLink]="['/vendor-contract-details']">View</button>
                        </div>
                    </div>
                    <div>
                        <p>Description</p>
                        <p>On 04/06/2020, Wilber Cruz signed an agreement to purchase a new office building at 68 Riverview Ave. Alpharetta, GA 30004.</p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>