import { Component, ViewChild, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { WindowWidthService } from './services/windowWidth.service';
import { ActivatedRoute, Router, NavigationEnd, Data } from '@angular/router';
import { CurrentRouteService } from './services/current-route.service';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from 'rxjs';
import { ModalBgService } from './services/modal-bg.service';
import { GlobalFnService } from './services/global-functions.service';

@AutoUnsubscribe()

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  protected subscription1$: Subscription;
  protected subscription2$: Subscription;
  protected subscription3$: Subscription;
  protected subscription4$: Subscription;

  title = 'fin-dash';
  windowWidth: number;
  showHeader: boolean;
  showSidebar: boolean;
  routeData: any = [];
  notifications:boolean;
  userCurrentRoute: string;
  modalStatus:boolean;
  modal:any;
  bigHeader:boolean;

  @ViewChild('header', { static: false }) header: ElementRef;
  @ViewChild('deptTable', {static:false}) deptTable: ElementRef;

  constructor(
    private _wService: WindowWidthService,
    private _activatedRouter: ActivatedRoute,
    private _currentURL: CurrentRouteService,
    private _router: Router,
    public _modalBG:ModalBgService,
    public _fn: GlobalFnService
  ) { }

  ngOnInit() {
    this.subscription1$ = this._wService.currentVal.subscribe(currentVal => this.windowWidth = currentVal);
    this.subscription2$ = this._currentURL.currentVal.subscribe(currentVal => this.userCurrentRoute = currentVal);
    this.subscription3$ = this._modalBG.currentVal.subscribe(currentVal => this.modal = currentVal);

    this._wService.someWidth = window.innerWidth;
    this.windowWidth = this._wService.someWidth;
    this._wService.changeValue(this.windowWidth);
    this.userCurrentRoute = this._currentURL.currentRoute;

    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showSidebar = this._activatedRouter.firstChild.snapshot.data.showSidebar != false;
        this.showHeader = this._activatedRouter.firstChild.snapshot.data.showHeader != false;
        this.userCurrentRoute = this._router.url;
        this._currentURL.changeValue(this.userCurrentRoute);
        this._modalBG.modalActive = false;
        this._modalBG.closeAll();
        this.trimHeader()
      }
    })
  }

  ngOnDestroy(){}

  trimHeader(){
    (this.userCurrentRoute === '/vendor-main-landing') && this.windowWidth < 1100 ? this.bigHeader = true : this.bigHeader = false;
  }

  onResize(event: any) {
    this._modalBG.closeAll()
    this.windowWidth = window.innerWidth;
    this._wService.someWidth = this.windowWidth;
    this._wService.changeValue(this.windowWidth);
    this._fn.sidebarVisible = false;
    this.trimHeader()
  }

  closeModal(){
    this._fn.sidebarToggle(false)
  }

}


