import { Component, OnDestroy, OnInit } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { ModalBgService } from 'src/app/services/modal-bg.service';
import { Router } from '@angular/router'

@AutoUnsubscribe()

@Component({
  selector: 'app-user-profile-mobile',
  templateUrl: './user-profile-mobile.component.html',
  styleUrls: ['./user-profile-mobile.component.scss']
})
export class UserProfileMobileComponent implements OnInit, OnDestroy {

  panelOpen:boolean;

  constructor(
    private _modalBG:ModalBgService,
    private router: Router,
  ) { }

  ngOnInit(): void {}

  ngOnDestroy(){}

  closePanel(){
    this._modalBG.panelStatus[1].userProfile.value = false;
    this._modalBG.changeValue(1, 'userProfile', false);
  }

  clickLink() {
    this.closePanel();
    this.router.navigate(['/settings']);
  }

}
