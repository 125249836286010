import { Component, OnDestroy, OnInit } from '@angular/core';
import { WindowWidthService } from 'src/app/services/windowWidth.service';
import { Location } from '@angular/common';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from 'rxjs';

@AutoUnsubscribe()

@Component({
  selector: 'back-btn',
  templateUrl: './back-btn.component.html',
  styleUrls: ['./back-btn.component.scss']
})
export class BackBtnComponent implements OnInit, OnDestroy {

  protected subscription1$: Subscription;

  screenSize:number;

  constructor(
    private _windowSize: WindowWidthService,
    private _location:Location
  ) { }

  ngOnInit(): void {
    this.subscription1$ = this._windowSize.currentVal.subscribe(currentVal => this.screenSize = currentVal)
  }

  ngOnDestroy(){}

  previousPage(){
    this._location.back()
  }

}
