import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GlobalSearchContactComponent } from '../global-search-contact/global-search-contact.component';

const routes: Routes = [
  { path: '', component: GlobalSearchContactComponent, children: [
      { path:'global-search-contact', component: GlobalSearchContactComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GlobalSearchContactRoutingModule { }

export const globalSearchContactRoutingComponents = [
  GlobalSearchContactComponent
]
