import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { WindowWidthService } from 'src/app/services/windowWidth.service';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from 'rxjs';

@AutoUnsubscribe()

@Component({
  selector: 'app-notifications-page',
  templateUrl: './notifications-page.component.html'
})
export class NotificationsPageComponent implements OnInit, OnDestroy {
  
  protected subscription1$: Subscription

  screenSize: number;
  count: number = 0;

  constructor(
    private _wService: WindowWidthService,
    private location: Location
  ) {
  }

  ngOnInit(): void {
    this._wService.currentVal.subscribe(currentVal => this.screenSize = currentVal);
    this.screenSize = this._wService.someWidth;
  }

  ngOnDestroy(){}

  // If desktop, shoot user back one page
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (this.screenSize > 992) {
      this.count++;
      if (this.count === 1) {
        this.location.back();
      }
    }
  }

}
