import * as tableData from './table-data.json';
import * as filteredCharts from './filteredCharts.json';
import { Component, OnInit, OnDestroy, Output, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { WindowWidthService } from 'src/app/services/windowWidth.service';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from 'rxjs';
import { ModalBgService } from 'src/app/services/modal-bg.service';
import { DeptTableComponent } from '../../components/_reusable/dept-table/dept-table.component';
import { GlobalFnService } from '../../services/global-functions.service';
import { DeptFiltersComponent } from 'src/app/components/dept-filters/dept-filters.component';
import { AreaLineChartComponent } from 'src/app/components/_reusable/area-line-chart/area-line-chart.component';
import { DoughnutChartComponent } from 'src/app/components/_reusable/doughnut-chart/doughnut-chart.component';
import { GraphTableComponent } from 'src/app/components/_reusable/graph-table/graph-table.component';

@AutoUnsubscribe()

@Component({
  selector: 'app-commitments',
  templateUrl: './commitments.component.html',
  styleUrls: ['./commitments.component.scss']
})
export class CommitmentsComponent implements OnInit, OnDestroy, AfterViewInit {

  protected subscription1$: Subscription;

  filteredCharts: any = filteredCharts;
  filteredChartsArray: any = [];
  currentYear: any = new Date().getFullYear();
  duration: string = 'Monthly';
  screenSize: number;
  isIPadPro: any;
  dataIndex: number;
  tableIndex: number = 0;
  tableValue: any = [];
  tableArray: any = [];

  // Get Column Names from Table
  @ViewChild(DeptTableComponent) tableComponent: any = [];
  columnNames: string;
  @Output() visibleFilters: any = this.tableComponent.columnNames;
  @Output() gridAreaNames: any;
  filterStatus: any;

  @ViewChild(DeptFiltersComponent) dFilters: any;
  @ViewChild(AreaLineChartComponent) areaLineChart: any;
  @ViewChild(DoughnutChartComponent) doughnutChart: any;
  @ViewChild(GraphTableComponent) graphTable: any;

  constructor(
    private windowWidth: WindowWidthService,
    public _modalBG: ModalBgService,
    private _cd: ChangeDetectorRef,
    public _fn: GlobalFnService
  ) { }

  ngOnInit(): void {
    this.subscription1$ = this.windowWidth.currentVal.subscribe(currentVal => this.screenSize = currentVal);

    this.isIPadPro = /Macintosh/.test(navigator.userAgent) && 'ontouchend' in document;

    for (let key in filteredCharts.dataArray) {
      if (filteredCharts.dataArray.hasOwnProperty(key)) {
        this.filteredChartsArray.push(filteredCharts.dataArray[key])
      }
    }
    this.retrieveData(0);
  }

  ngAfterViewInit() {
    this.dFilters.showAbreviations = false;
    this._fn.createTableGridArea(this.tableArray[0][0]);
    this._fn.createFilterGridArea(this.dFilters, this.tableArray[0][0])
    this._cd.detectChanges();
  }

  ngOnDestroy() { }

  retrieveData(i: number) {
    for (let key in tableData.tableData) {
      if (tableData.tableData.hasOwnProperty(key)) {
        this.tableArray.push(tableData.tableData[key])
      }
    }
    i = this.tableIndex;
    this.tableValue = this.tableArray[i];
  }

  // Create new grid-template-area
  receiveNewColNames(e: any) {
    this.gridAreaNames = "";
    for (var i = 0; i < e.length; i++) {
      this.gridAreaNames += e[i] + " "
    }
    this.gridAreaNames = "'" + this.gridAreaNames + "'";
  }

  btnClick(i: number) {
    this.tableIndex = i;
    this.retrieveData(this.tableIndex)
  }

  changeDD(e: any) {
    this.tableValue = this._fn.changeDD(this.tableValue);
    this._fn.randomizeChartData(this.areaLineChart);
    this._fn.genNewDoughnutData(this.doughnutChart);
    this._fn.shuffleArray(this.graphTable.chartAmount);
  }


}




