import { ChangeDetectorRef, Component, OnInit, ViewChild, ElementRef, Input, Output } from '@angular/core';
import { Chart } from 'chart.js';
import * as chartJson from './chart-data.json';
import { GlobalFnService } from '../../../services/global-functions.service';

@Component({
  selector: 'stacked-bar-chart',
  templateUrl: './stacked-bar-chart.component.html',
  styleUrls: ['./stacked-bar-chart.component.scss']
})
export class StackedBarChartComponent implements OnInit {

  chart: any;
  graphTotals: any;
  graphHeights:number[] = [0, 0];

  @ViewChild('chartRef', { static: false }) chartRef: ElementRef;
  @Input() chartColors: string[] = ["#89D076", "#2A8CCC", "#5CC5EB", "#BED758", "#003A68"];
  @Input() chartData: any = chartJson.chartData
  @Input() graphLabels: string[] = ['Placeholder 1', 'Placholder 2']
  @Input() showLabels: boolean = false;
  @Input() graphHeight:number = 200;

  constructor(
    private _changeDetect:ChangeDetectorRef,
    public _fn:GlobalFnService
  ) {}

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.createBarChart();
    this.graphTotals = this.calcTotals(this.chartData);
    this.getHeights()
    this._changeDetect.detectChanges()
  }

  ngOnChanges(){
    if(this.chart != undefined){
      this.chart.chart.config.data.datasets = this.createDataset(this.chartData)
      this.chart.update()
      this.graphTotals = this.calcTotals(this.chartData);
      this.getHeights()
    }
  }

  createBarChart() { 
    let ctx:any;
        ctx = this.chartRef.nativeElement;
    this.chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: this.graphLabels,
        datasets: this.createDataset(this.chartData),
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          duration: 0
        },
        tooltips: {
          displayColors: true,
        },
        scales: {
          xAxes: [{
            stacked: true,
            display: false,
          }],
          yAxes: [{
            display: false,
            stacked: true,
            ticks: {
              beginAtZero: true,
            },
            type: 'linear',
          }]
        },
          legend: { display: false },
        }
    });

    this.chart.width = '100%';
  }

  createDataset(array) {
    let datasets = []
    array.forEach((contract, i) => {
      datasets.push({
        label: contract.name,
        backgroundColor: this.chartColors[i],
        data: contract.data
      })
    })
    return datasets
  }

  calcTotal(array, index) {
    let total = 0;
    array.forEach(contract => {
      total += contract.data[index]
    })
    return total
  }

  calcTotals(array) {
    let totals:number[] = [0];
    array.forEach(item => {
      item.data.forEach((n, i) => {
        if (totals[i] === undefined) totals[i] = 0;
        totals[i] += n
      })
    })
    return totals
  }

  calcPercent(array, arrayNumber, index) {
    const total = this.calcTotal(array, arrayNumber)
    return Math.round((array[index].data[arrayNumber] / total) * 100) + '%'
  }

  calcHeight(array, index) {
    const highestValue = Math.max(...array);
    return array[index] < highestValue ? (1 - array[index] / highestValue) * this.graphHeight : 0;
  }

  getHeights() {
    this.graphHeights = [this.calcHeight(this.graphTotals, 0), this.calcHeight(this.graphTotals, 1)]
  }


}
