import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OpenContactService {

  contactCardState:boolean;

  private valueSource = new BehaviorSubject(this.contactCardState);
  currentVal = this.valueSource.asObservable();

  changeValue(myValue:boolean){
    this.valueSource.next(myValue);
  }

  constructor() { }
}
