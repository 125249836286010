import { ChangeDetectorRef, Component, OnInit, ViewChild, ElementRef, Input, Output } from '@angular/core';
import { Chart } from 'chart.js';
import * as chartJson from './chart-data.json';

@Component({
  selector: 'h-bar-chart',
  templateUrl: './h-bar-chart.component.html',
  styleUrls: ['./h-bar-chart.component.scss']
})
export class HBarChartComponent implements OnInit {

  chart: any;
  chartDataset: any = [];

  @ViewChild('chartRef', { static: false }) chartRef: ElementRef;
  @Input() chartColors: string[] = ["#81D36B", "#ACACAC"];
  @Input() chartData: any = chartJson.chartData

  constructor(
    private _changeDetect:ChangeDetectorRef
  ) {}

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.createBarChart();
    this._changeDetect.detectChanges()
  }

  ngOnChanges(){
    if(this.chart != undefined){
      this.chart.update()
    }
  }

  createBarChart() { 
    let ctx:any;
        ctx = this.chartRef.nativeElement;
    this.chart = new Chart(ctx, {
      type: 'horizontalBar',
      data: {
        datasets: 
        this.createDataset(this.chartData)
        },
        options:{
          legend:{
            display:false
          },
          responsive:true,
          maintainAspectRatio:false,
          layout:{
            padding:{
              left:0,
              right:10,
              top:10,
              bottom:0
            }
          },
          scales:{
            xAxes: [{
              stacked:true,
              gridLines:{
                display:false,
                drawBorder:false
              },
              ticks:{
                display:false,
                suggestedMin:0,
                suggestedMax:100
              }
            }],
            yAxes: [{
              gridLines:{
                display:false,
                drawBorder:false
              },
              ticks:{
                display:false
              }
            }]
          }
        }
    })
  }

  createDataset(array) {
    let datasets = []
    array.forEach((item, i) => {
      datasets.push({
        barPercentage:.5,
        categoryPercentage:1.2,
        data: item.data,
        backgroundColor: this.chartColors[i],
        stack: i
      })
    })
    return datasets
  }


}
