import { ChangeDetectorRef, Component, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { WindowWidthService } from 'src/app/services/windowWidth.service';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from 'rxjs';
import { ModalBgService } from 'src/app/services/modal-bg.service';
import { DeptTableComponent } from '../../components/_reusable/dept-table/dept-table.component';
import { GlobalFnService } from 'src/app/services/global-functions.service';
import * as deptJson from "./deptData.json";
import * as contractJson from "./contractData.json";
import * as expenseJson from "./expenseData.json";
import * as vendorJson from "./vendor-data.json";
import * as assetJson from "./asset-data.json";
import { DeptFiltersComponent } from 'src/app/components/dept-filters/dept-filters.component';

@AutoUnsubscribe()

@Component({
  selector: 'app-dept-drilldown',
  templateUrl: './dept-drilldown.component.html',
  styleUrls: ['./dept-drilldown.component.scss']
})
export class DeptDrilldownComponent implements OnInit, OnDestroy {

  viewSelection: string = "Overview";
  backgroundColors: string[] = ["#81D36B", "#0083D8", "#5CC5EB", "#BED758", "#003A68"];
  deptData: any = deptJson.tableData;
  expenseData: any = expenseJson.tableData;
  contractData: any = contractJson.tableData;

  vendorData: any = vendorJson.tableData;
  assetData: any = assetJson.tableData;
  overviewData: any = [this.deptData, this.expenseData, this.contractData];
  tableData: any = this.overviewData;

  btnNames: string[] = ['Overview', 'Vendors', 'Contracts', 'Assets', 'Subdepartments'];
  mobileBtnNames: string[] = ['Overview', 'Vendors', 'Contracts', 'Assets', 'Sub-Depts'];
  overviewBtns: string[] = ["Dept.", "Exp. Category", "Contract"]
  overviewTableLinks: string[] = ["sub-dept-analysis", "burn-rate-category", "vendor-contract-details"];
  tableLinks: string[] = this.overviewTableLinks;
  chartData: number[][] = [[10, 30, 10, 20, 30], [30, 10, 20, 30, 20], [15, 25, 15, 10, 20]]
  tableIndex: number = 0;
  abreviationArray: any = [];

  assetLegendInfo = [
    { color: "#81D36B", name: "All Other", percent: "40%", cash: "$5,321,742.63" },
    { color: "#0083D8", name: "Building", percent: "30%", cash: "$3,312,545.21" },
    { color: "#5CC5EB", name: "Machinery", percent: "20%", cash: "$2,543,854.87" },
    { color: "#BED758", name: "Equipment", percent: "10%", cash: "$1,329,324.77" },
    { color: "#003A68", name: "Land", percent: "10%", cash: "$1,143,855.73" }
  ]

  subdeptsLegendInfo = [
    { color: "#81D36B", name: "All Other", initials: "All Other", cash: "$5,321,742.63" },
    { color: "#0083D8", name: "Department of Finance", initials: "DAT", cash: "$3,312,545.21" },
    { color: "#5CC5EB", name: "Operations and Performance Management", initials: "SSS", cash: "$2,543,854.87" },
    { color: "#BED758", name: "Anti-Trust North America Law Division", initials: "OPM", cash: "$1,329,324.77" },
    { color: "#003A68", name: "Department of Finance", initials: "DAT", cash: "$1,143,855.73" }
  ]

  protected subscription1$: Subscription;
  protected subscription2$: Subscription;

  // Get Column Names from Table
  @ViewChild(DeptTableComponent) tableComponent: any = [];
  columnNames: string;
  @Output() visibleFilters: any = this.tableComponent.columnNames;

  // Table/Filters
  @Output() gridAreaNames: any;

  @ViewChild(DeptFiltersComponent) dFilters: any;

  screenSize: number;
  filterStatus: any;

  constructor(
    private _windowWidth: WindowWidthService,
    public _modalBG: ModalBgService,
    private _cd: ChangeDetectorRef,
    public _fn: GlobalFnService
  ) { }

  ngOnInit(): void {
    this.subscription1$ = this._windowWidth.currentVal.subscribe(currentVal => this.screenSize = currentVal);
    this.subscription2$ = this._modalBG.currentVal.subscribe(currentVal => this.filterStatus = currentVal);
  }

  ngAfterViewInit() {
    this.setTableColumns()
    this._cd.detectChanges();
  }

  ngOnDestroy() { }

  setTableColumns() {
    this._fn.createTableGridArea(this.tableData[0][0]);
    this._fn.createFilterGridArea(this.dFilters, this.tableData[0][0]);
  }

  viewChange(e: any) {
    this.tabChange(0);
    let newView = e.target.buttonId;
    if (newView === 'Sub-Depts') newView = 'Subdepartments';
    this.viewSelection = newView;
    switch (newView) {
      case "Vendors":
        this.tableData = [this.vendorData]
        this.tableLinks = ["vendors-drilldown-overview"];
        break;
      case "Contracts":
        this.tableData = [this.contractData]
        this.tableLinks = ["vendor-contract-details"];
        break;
      case "Assets":
        this.tableData = [this.assetData]
        this.tableLinks = ["name-of-asset"];
        break;
      case "Subdepartments":
        this.tableData = [this.deptData]
        this.tableLinks = ["sub-dept-analysis"];
        break;
      default:
        this.tableData = this.overviewData
        this.tableLinks = this.overviewTableLinks;
    }
    this.viewSelection === 'Contracts' || this.viewSelection === 'Assets' ? this.dFilters.showAbreviations = false : this.dFilters.showAbreviations = true;
    this.viewSelection === 'Contracts' ? this.dFilters.contractsBtn = true : this.dFilters.contractsBtn = false;
    this.dFilters.dataIndex = 0;
    this.dFilters.resetToggles();
    this.setTableColumns()
  }

  vendorTabChange() {
    this.tableData[this.tableIndex] = this._fn.changeDD(this.tableData[this.tableIndex])
  }

  tabChange(i: number) {
    this.tableIndex = i;
    this.setTableColumns()
    this._fn.altReset()
  }

  // Create new grid-template-area
  receiveNewColNames(e: any) {
    this.gridAreaNames = "";
    for (var i = 0; i < e.length; i++) {
      this.gridAreaNames += e[i] + " "
    }
    this.gridAreaNames = "'" + this.gridAreaNames + "'";
  }

  // Receive from filters, send full/abreviation to table (column 1)
  getNewData(e: any) {
    if (e) {
      for (var i = 0; i < this.tableData[this.tableIndex].length; i++) {
        this.abreviationArray.push(this.tableData[this.tableIndex][i]._abreviation)
      }
    } else {
      this.abreviationArray = []
    }
  }

  openContact() {
    this._modalBG.panelStatus[5].contact.value = true;
    this._modalBG.changeValue(5, 'contact', this._modalBG.panelStatus[5].contact.value);
  }

}
