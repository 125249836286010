<div id="sidebar">
    <a (click)="closePanels()" routerLink="" id="conseqta-logo">
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 108.95 113.12"><defs></defs><title>Conseqta</title><path d="M150.27,6.24a14.34,14.34,0,0,0,9.78,8.36,7.33,7.33,0,0,0,2.84.08l.87-.23c2-.73,2.92-2.65,2.12-5.3-1.24-3.7-5.51-7.58-9.65-8.83-4.34-1.16-7.18,1-6.27,4.85a8.06,8.06,0,0,1,.29,1.07ZM116,15.9c-4,6.39-4,6.39-8,13.22-2.52,4.13-2.52,4.13-5,8.35-6.16,6.55-15.06,2.18-18.3.22-.77-.5-1.24-.84-1.24-.84a11,11,0,0,0-10.82.37,11.26,11.26,0,0,0-4.45,14.89,10.66,10.66,0,0,0,14.5,4.11l.48-.29s.47-.37,1.38-1c3.35-2.15,12.41-6.74,18.67.22l5.27,9.36c4.59,8.19,4.59,8.19,9.18,16.54a4.65,4.65,0,0,0,4.13,2.58c8.89.11,8.89.11,17.61.26,4.92,0,4.92,0,9.77.11,8.12,2.22,8.67,12.41,8.54,16.55a9.31,9.31,0,0,1-.11,1.57,11.34,11.34,0,0,0,4.64,9.62c4.39,2.91,9.89.88,12.41-4.41s1.06-11.87-3.06-14.93l-1.08-.77c-2.84-1.88-10-7.63-7.8-16.37,2-4.39,2-4.39,3.9-8.65,3.1-7.2,3.1-7.2,5.94-14a5.24,5.24,0,0,0-.47-4.4c-4.13-7.32-4.13-7.32-8.49-14.56-2.59-4.14-2.59-4.14-5.18-8.2-1.89-2.87-1.89-2.87-3.79-5.67A5.53,5.53,0,0,0,151,17.47l-6.32-.93c-4.37-.58-4.37-.58-8.86-1-7.94-.73-7.94-.73-16.1-1.31A3.75,3.75,0,0,0,116,15.92Zm.71,27.36,7.58-13.41a5.12,5.12,0,0,1,5.06-2.57c7.69.58,7.69.58,15.16,1.3a6.89,6.89,0,0,1,5.06,3.29c4.3,6.9,4.3,6.9,8.43,14a6.58,6.58,0,0,1,.51,6.09c-3.13,6.78-3.13,6.78-6.52,13.78a5.14,5.14,0,0,1-5,3c-7.91-.28-7.91-.28-16-.51a6.43,6.43,0,0,1-5.52-3.49c-4.3-7.61-4.3-7.61-8.68-15.08a6.23,6.23,0,0,1-.1-6.4Z" transform="translate(-66.88 0)"/></svg>
    </a>

    <a (click)="closePanels()" routerLink="/landing" [routerLinkActive]="['active-route']">
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="49" viewBox="0 0 48 49"><defs><style>.a{fill:rgba(255,255,255,0);stroke:rgba(0,0,0,0);}.b,.c{fill:#fff;}.b{font-size:12px;font-family:OpenSans, Open Sans;letter-spacing:-0.024em;}.d{stroke:none;}.e{fill:none;}</style></defs><g transform="translate(-0.249)"><g class="a" transform="translate(0.249)"><rect class="d" width="48" height="32"/><rect class="e" x="0" y="0" width="47" height="31"/></g><text class="b" transform="translate(0.249 32)"><tspan x="7.434" y="13">Home</tspan></text></g><path class="c" d="M215.443,136.333v10.509a1.013,1.013,0,0,1-1.013,1.013h-4.283a1.013,1.013,0,0,1-1.013-1.013v-3.859a3.064,3.064,0,0,0-3.064-3.064h0A3.064,3.064,0,0,0,203,142.982v3.859a1.013,1.013,0,0,1-1.013,1.013h-5.037a1.013,1.013,0,0,1-1.014-1.013v-10.5a2.291,2.291,0,0,1,.923-1.838l7.905-5.887a1.549,1.549,0,0,1,1.85,0l7.905,5.889A2.283,2.283,0,0,1,215.443,136.333Z" transform="translate(-182.057 -121.306)"/></svg>
    </a>

    <a (click)="closePanels()" routerLink="/departments" [routerLinkActive]="['active-route']">
        <svg xmlns="http://www.w3.org/2000/svg" width="77" height="43" viewBox="0 0 77 43"><defs><style>.a{opacity:0.8;}.b{fill:#cacaca;font-size:12px;font-family:OpenSans, Open Sans;letter-spacing:-0.024em;}.c{opacity:0.9;}.d{fill:none;stroke:#969696;stroke-width:2px;}.e{fill:#969696;}</style></defs><g class="a" transform="translate(-0.105 26)"><text class="b" transform="translate(0.105)"><tspan x="23.944" y="13">Dept.</tspan></text></g><g class="c" transform="translate(24.895)"><g transform="translate(6.351 7.106)"><line class="d" y1="7.709" x2="6.167"/><line class="d" x1="6.167" y1="7.709" transform="translate(7.38)"/></g><rect class="e" width="11" height="8" rx="1" transform="translate(0.105 13)"/><rect class="e" width="11" height="8" rx="1" transform="translate(16.105 13)"/><rect class="e" width="13" height="8" rx="1" transform="translate(7.105)"/></g></svg>
    </a>

    <a (click)="closePanels()" routerLink="/vendor-main-landing" [routerLinkActive]="['active-route']">
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="40.762" viewBox="0 0 48 40.762"><defs><style>.a,.b{fill:#8a8a8a;}.a{font-size:12px;font-family:OpenSans, Open Sans;letter-spacing:-0.024em;}</style></defs><text class="a" transform="translate(0 23.762)"><tspan x="4.47" y="13">Vendor</tspan></text><path class="b" d="M20.935,2.25H1.9A1.9,1.9,0,0,0,0,4.153V18.11a1.9,1.9,0,0,0,1.9,1.9H20.935a1.9,1.9,0,0,0,1.9-1.9V4.153A1.9,1.9,0,0,0,20.935,2.25ZM6.978,6.056A2.538,2.538,0,1,1,4.441,8.594,2.54,2.54,0,0,1,6.978,6.056Zm4.441,9.389a.831.831,0,0,1-.888.761H3.426a.831.831,0,0,1-.888-.761v-.761A2.5,2.5,0,0,1,5.2,12.4h.2a4.084,4.084,0,0,0,3.156,0h.2a2.5,2.5,0,0,1,2.664,2.284ZM20.3,13.352a.318.318,0,0,1-.317.317h-5.71a.318.318,0,0,1-.317-.317v-.634a.318.318,0,0,1,.317-.317h5.71a.318.318,0,0,1,.317.317Zm0-2.538a.318.318,0,0,1-.317.317h-5.71a.318.318,0,0,1-.317-.317V10.18a.318.318,0,0,1,.317-.317h5.71a.318.318,0,0,1,.317.317Zm0-2.538a.318.318,0,0,1-.317.317h-5.71a.318.318,0,0,1-.317-.317V7.642a.318.318,0,0,1,.317-.317h5.71a.318.318,0,0,1,.317.317Z" transform="translate(13.496 -2.25)"/></svg>
    </a>

    <a (click)="closePanels()" routerLink="/search" [routerLinkActive]="['active-route']">
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="42.747" viewBox="0 0 48 42.747"><defs><style>.a{opacity:0.8;}.b{fill:#cacaca;font-size:12px;font-family:OpenSans, Open Sans;letter-spacing:-0.024em;}.c{fill:#969696;opacity:0.9;}</style></defs><g class="a" transform="translate(0.249 25.747)"><text class="b" transform="translate(-0.249)"><tspan x="5.856" y="13">Search</tspan></text></g><path class="c" d="M22.83,19.72l-4.952-4.212a2.21,2.21,0,0,0-1.5-.652,7.841,7.841,0,1,0-.878.878,2.21,2.21,0,0,0,.652,1.5l4.212,4.952A1.756,1.756,0,1,0,22.83,19.72ZM10.411,15a5.227,5.227,0,1,1,5.227-5.227A5.227,5.227,0,0,1,10.411,15Z" transform="translate(10.932 -1.928)"/></svg>
    </a>

    <a (click)="closePanels()" routerLink="/more" [routerLinkActive]="['active-route']">
        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="48" height="42.747" viewBox="0 0 48 42.747"><defs><style>.a{opacity:0.8;}.b{fill:#cacaca;font-size:12px;font-family:OpenSans, Open Sans;letter-spacing:-0.024em;}.c{fill:#969696;opacity:0.9;}</style></defs><g class="a" transform="translate(0.249 25.747)"><text class="b" transform="translate(-0.249)"><tspan x="5.856" y="13">More</tspan></text></g><path class="c" d="M22.83,19.72l-4.952-4.212a2.21,2.21,0,0,0-1.5-.652,7.841,7.841,0,1,0-.878.878,2.21,2.21,0,0,0,.652,1.5l4.212,4.952A1.756,1.756,0,1,0,22.83,19.72ZM10.411,15a5.227,5.227,0,1,1,5.227-5.227A5.227,5.227,0,0,1,10.411,15Z" transform="translate(10.932 -1.928)"/></svg> -->
        <svg xmlns="http://www.w3.org/2000/svg" width="28.979" height="6.5" viewBox="0 0 28.979 6.5">
            <g id="Group_13965" data-name="Group 13965" transform="translate(-313.574 -1051)">
              <circle id="Ellipse_1589" data-name="Ellipse 1589" cx="3.25" cy="3.25" r="3.25" transform="translate(313.574 1051)" fill="#8a8a8a"/>
              <circle id="Ellipse_1590" data-name="Ellipse 1590" cx="3.25" cy="3.25" r="3.25" transform="translate(324.814 1051)" fill="#8a8a8a"/>
              <circle id="Ellipse_1591" data-name="Ellipse 1591" cx="3.25" cy="3.25" r="3.25" transform="translate(336.054 1051)" fill="#8a8a8a"/>
            </g>
        </svg>
        <p class='text'>More</p>
          
    </a>

</div>