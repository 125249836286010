<div id="user-profile">
    <div class="notification-tip"></div>
    <div class="notification-body">

        <div class="grid" (click)="toggleSettings()">
            <div class="relative">
                <i style="color: rgb(12,97,142)" class="fas fa-cog"></i>
            </div>
            <p>Privacy Settings..</p>
        </div>
        <div class="grid" (click)="toggleSettings()">
            <div class="relative">
                <i style="color: rgb(113,206,88)" class="fas fa-user"></i>
            </div>
            <p>Update Account Information</p>
        </div>
        <div class="grid" (click)="toggleSettings()">
            <div class="relative">
                <i style="color: rgb(24,185,216)" class="fas fa-bell"></i>
            </div>
            <p>Notification Settings</p>
        </div>
        <div class="grid">
            <div class="relative">
                <i style="color: rgb(28,207,108)" class="fas fa-long-arrow-alt-right"></i>
            </div>
            <p>Switch to a General User</p>
        </div>
    </div>
</div>