<div id="main-content" data-page="comparison-report-select">
    <div class="header-content">
        <div class="page-name">
            <page-name [pageName]='"Comparison Report"'></page-name>
        </div>
        <div class='tab-container'>
            <tab-group [tabs]='tabNames' [maxWidth]="600" (tabClickEvt)='changeTableData($event)'></tab-group>
            <div class="button-enclosure-2" *ngIf="screenSize > 1100">
                <button type="button" class="conseqta-btn" (click)='clickCompare()' [ngClass]="{'full-compare' : boxesChecked.length === 2}">Compare ({{ boxesChecked.length }})</button>
            </div>
        </div>
        <div class='search-container'>
            <search-bar [placeholder]="'Search Expense Categories'" (filterStatus)="_fn.transparentBG($event)"></search-bar>
        </div>
    </div>

    <div class="page-content">
        <section class='comparison-table p-shadow p-radius white-BG'>
            <dept-table [abreviations]="abreviationArray" [data]="tableData[currentTab]" [itemsPerPageInput]="10" [pageValues]="_fn.gridAreaNames" [comparison]="true" [deleteThis]="removeCheckNum" (numChecked)='getNumChecked($event)'></dept-table>
        </section>
    </div>
    <div id="filter-container" [ngClass]="{'show-filters': _modalBG.panelStatus[3].filters.value}">
        <dept-filters [data]="tableData[currentTab]" [filterList]="tableComponent.columnNames" [pageValues]="_fn.gridAreaNames" (columnNamesEvt)="receiveNewColNames($event)" (abreviationEvt)="getNewData($event)"></dept-filters>
    </div>

    <div class='mobile-compare' *ngIf='screenSize <= 1100'>

        <div class="selected-items">
            <div *ngFor="let selection of boxesChecked; let i = index">
                <span>{{ selection.name }}</span>
                <div (click)="removeCheck(i)">
                    <i class="fas fa-times"></i>
                </div>
            </div>
        </div>

        <button type="button" class='conseqta-btn' (click)='clickCompare()' [ngClass]="{'full-compare' : boxesChecked.length === 2}">Compare ({{ boxesChecked.length }})</button>
    </div>
</div>