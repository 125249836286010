import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubDeptAnalysisRoutingModule, subDeptAnalysisRoutingComponents } from './sub-dept-analysis-routing.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { SharedModule } from '../../modules/shared/shared.module';

@NgModule({
  declarations: [
    subDeptAnalysisRoutingComponents,
  ],
  imports: [
    CommonModule,
    SubDeptAnalysisRoutingModule,
    NgxPaginationModule,
    SharedModule
  ]
})
export class SubDeptAnalysisModule { }

