import { Component, OnDestroy, OnInit, Output, ViewChild, ChangeDetectorRef } from '@angular/core';
import { WindowWidthService } from 'src/app/services/windowWidth.service';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from 'rxjs';
import { ModalBgService } from 'src/app/services/modal-bg.service';
import { DeptTableComponent } from '../../components/_reusable/dept-table/dept-table.component';
import { GlobalFnService } from '../../services/global-functions.service';
import * as tableData from './table-data.json';

@AutoUnsubscribe()

@Component({
  selector: 'app-spending-milestone',
  templateUrl: './spending-milestone.component.html',
  styleUrls: ['./spending-milestone.component.scss']
})
export class SpendingMilestoneComponent implements OnInit, OnDestroy {

  protected subscription1$: Subscription;
  protected subscription2$: Subscription;

  screenSize: number;
  viewSelection: string = "1D";
  filterActive: any;
  tableData = tableData.tableData;
  dropdownOptions = ["One Week", "One Month", "One Year"];
  sliderValue: number = 10.5;

  // Get Column Names from Table
  @ViewChild(DeptTableComponent) tableComponent: any = [];
  columnNames: string;
  @Output() visibleFilters: any = this.tableComponent.columnNames;
  @Output() gridAreaNames: any;

  constructor(
    private _windowWidth: WindowWidthService,
    private _cd: ChangeDetectorRef,
    public _modalBG: ModalBgService,
    public _fn: GlobalFnService
  ) { }

  ngOnInit(): void {
    this.subscription1$ = this._windowWidth.currentVal.subscribe(currentVal => this.screenSize = currentVal);
    this.subscription2$ = this._modalBG.currentVal.subscribe(currentVal => this.filterActive = currentVal);
    this.sliderUpdate({value: this.sliderValue});
  }

  ngAfterViewInit() {
    this._fn.createTableGridArea(this.tableData[0] );
    this._cd.detectChanges()
  }

  ngOnDestroy() { }

  viewChange(e: any) {
    this.viewSelection = e.target.buttonId;
  }

  transparentBG(e: any) {
    this._modalBG.panelStatus[3].filters.value = true;
    this._modalBG.changeValue(3, 'filters', this._modalBG.panelStatus[3].filters.value);
  }

  changeDD() {
    this.tableData = this._fn.changeDD(this.tableData)
  }

  sliderUpdate(e) {
    let newData = tableData.tableData.filter(item => {
      const num = parseFloat(item.Dept.percent);
      return num <= e.value;
    })
    if (newData.length === 0) newData = [{
      "Dept": {
        "name": "No Matches",
        "percent": "",
        "status": ""
      },
      "Total Budget": "-",
      "Remaining": "-",
      "# of Sub-dept": "-",
      "Burn Rate": "-"
    }]
    this.tableData = newData;
  }

  sliderMove(e) {
    this.sliderValue = e.value;
  }

}
