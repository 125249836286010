<div id="main-content" data-page="credit-card-transactions">
    <div class="mobile-bg" [ngClass]="{'dd-bg': screenSize <= 1100 && currentView === 'department'}">
        <div class="btn-group" *ngIf="screenSize <= 1100 && currentView === 'agency'">
            <btn-group (clickEvent)="btnClick($event)" [btns]="btnNames"></btn-group>
        </div>
        <div class="dropdowns" *ngIf="screenSize <= 1100 && currentView === 'department'">
            <dropdown-menus (ddInfo)='changeDD($event)'></dropdown-menus>
        </div>
    </div>
    <div class="top-level-header">
        <div class="header-content">
            <div class="page-name">
                <h2 [ngClass]="{'active-link':currentView === 'agency'}" (click)="changeView($event)" id="agency">
                    Agency</h2>
                <h2 [ngClass]="{'active-link':currentView === 'department'}" (click)="changeView($event)" id="department">Department</h2>
            </div>
            <div *ngIf="screenSize > 1100">
                <dropdown-menus (ddInfo)='changeDD($event)'></dropdown-menus>
            </div>
        </div>
        <div class="sidebar-icon">
            <sidebar-toggle-icon (emitValue)="_fn.sidebarToggle($event)" (click)="_fn.sidebarBG(null)">
            </sidebar-toggle-icon>
        </div>
    </div>
    <div class="page-content" [ngClass]="{'dd-margin': screenSize <= 1100 && currentView === 'department'}">
        <div class="slider" *ngIf="currentView === 'agency'">
            <section #scrollingContent class='info-cards' [ngClass]="{'hidden': currentView === 'department' || currentBtn === 1}">
                <!-- Yearly Trends -->
                <div class='inner-info-card p-shadow p-radius white-BG p-padding'>
                    <p>Yearly Trends</p>
                    <div class="yearly-transaction-trends">
                        <div>
                            <p class="green-text"><span class="dot green"></span>2022 Trans.</p>
                            <p>$91,839<span class="decimal-text">.56</span></p>

                        </div>
                        <div>
                            <p class="gray-text"><span class="dot gray"></span>2021 Trans.</p>
                            <p>$91,839<span class="decimal-text">.56</span></p>
                        </div>
                    </div>
                    <div class="bar-chart-area">
                        <h-bar-chart></h-bar-chart>
                    </div>
                </div>
                <!-- Archived Transactions -->
                <div class='archived p-padding p-shadow p-radius white-BG'>
                    <p>Archived Transactions</p>
                    <div class='doughnut-grid'>
                        <div class="doughnut-chart">
                            <div>
                                <doughnut-chart [cutOutPercent]="70"></doughnut-chart>
                            </div>
                        </div>
                        <div class="types-of-accounts">
                            <p><span class="dot blue"></span>Travel Account</p>
                            <p><span class="dot dark-blue"></span>Purchase Account</p>
                        </div>
                    </div>
                </div>
                <!-- Recent Payments -->
                <div class='p-padding p-shadow p-radius white-BG'>
                    <p>Recent Payments</p>
                    <div class="payment-history">
                        <div class="payments">
                            <p>Delta Airlines</p>
                            <p>$20,000.00</p>
                        </div>
                        <div class="payments">
                            <p>Rent-a-Car</p>
                            <p>$19,000.00</p>
                        </div>
                        <div class="payments">
                            <p>Rent-a-Car</p>
                            <p>$11,000.00</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <!-- Top 3 Spendinig Departments -->
        <div *ngIf="currentView === 'department'">
            <div class="department-top-panel white-BG p-padding p-radius">
                <p class="top-three-text">Top 3 Spending Departments</p>
                <div class="top-three-data-container">
                    <div class="doughnut-chart">
                        <div>
                            <doughnut-chart [cutOutPercent]="70"></doughnut-chart>
                        </div>
                    </div>
                    <div class="department-top-panel-data">
                        <div *ngFor="let panelData of departmentDataArray" class="pData">
                            <div [ngStyle]="{'backgroundColor':panelData.color}" class="dot panel-dot"></div>
                            <p>{{panelData.department}}</p>
                            <p>{{panelData.spendingAmount}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div *ngIf="screenSize <= 1100 &&  currentView === 'agency' && currentBtn === 0" class="credit mobile-card p-padding p-shadow p-radius white-BG">
            <p class="your-card-subtitle">Your Card</p>
            <div class="your-card">
                <p>Request Card</p>
                <p>Edit Card</p>
            </div>
            <div class="card-container">
                <img src="../../../assets/more/credit_card.svg" class="vCenter refresh image" />
            </div>
            <div class="expiring">
                <p>EXPIRING</p>
            </div>
        </div>

        <div *ngIf="screenSize <= 1100 &&  currentView === 'agency' && currentBtn === 0" class='calendar-wrapper p-shadow p-radius white-BG'>
            <div class="calendar">
                <calendar-credit (emitStartDate)="daySelect($event)"></calendar-credit>
            </div>
            <div class='calendar-expenses'>
                <div class='current-day'>{{ calendarDay }}</div>
                <div class='expense-list'>
                    <div *ngFor="let transaction of dailyExpenses">
                        <p>{{ transaction.Description }}</p>
                        <p>{{ transaction.Amount }}</p>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- Desktop tabs/search/table -->
        <div class='tab-group-wrapper' *ngIf="screenSize > 1100 && currentView === 'agency'">
            <tab-group [tabs]="tabNames" [maxWidth]="700" (tabClickEvt)='changeTableData($event)'></tab-group>
        </div>
        <div class='mobile-tab-group' *ngIf="screenSize <= 1100 && currentBtn === 1">
            <tab-group [tabs]="transactionTabs" [maxWidth]="700" (tabClickEvt)='changeTableData($event)'></tab-group>
        </div>
        <div class="dept-table" [ngClass]="{'hidden': screenSize <= 1100 && currentView === 'agency' && currentBtn === 0}">
            <div class="search-bar" *ngIf="screenSize <= 1100 || currentView === 'department'">
                <search-bar [placeholder]="'Search Departments'" [backgroundColor]="'#EDEDED'" (filterStatus)="_fn.transparentBG($event)"></search-bar>
            </div>
            <div class='table-btns' *ngIf="currentView === 'agency'">
                <btn-group (clickEvent)='changeDD($event)' [btns]="deptBtnNames"></btn-group>
            </div>
            <div class="dept-table">
                <dept-table [abreviations]="abreviationArray" [data]="tableData" [itemsPerPageInput]="10" [firstColumnWidth]="200" [pageValues]="_fn.gridAreaNames" [pageLink]="'department-drilldown'"></dept-table>
            </div>
        </div>

        <p class="subtitle" *ngIf="screenSize <= 1100 && currentView === 'agency' && currentBtn === 0">Category Breakdown</p>
        <section class='category-breakdown' *ngIf="screenSize <= 1100 && currentView === 'agency' && currentBtn === 0">
            <div *ngFor="let category of categoryDataArray" class="category-card white-BG p-padding p-shadow">
                <div>
                    <p>{{category.category}}</p>
                    <p>Trans: {{category.transactions}}</p>
                </div>
                <div>
                    <p class="red-text">{{category.debitAmount}}</p>
                    <p>Debit</p>
                </div>
                <div>
                    <p class="green-text">{{category.creditAmount}}</p>
                    <p>Credit</p>
                </div>
            </div>
        </section>

        <div id='sidebar' *ngIf="screenSize > 1100" [ngClass]="{'show-sidebar':_fn.sidebarVisible}">
            <div *ngIf="_fn.sidebarVisible && screenSize > 1100 && screenSize < 1420" class="sidebar-close" (click)="_fn.sidebarToggle()">
                <img src="../../../assets/dept-name/closeFilterPanel.svg" />
            </div>
            <div class="credit desktop-card p-shadow p-radius white-BG">
                <p class="your-card-subtitle">Your Card</p>
                <div class="your-card">
                    <p>Request Card</p>
                    <p>Edit Card</p>
                </div>
                <div class="card-container">
                    <img src="../../../assets/more/credit_card.svg" class="vCenter refresh image" />
                </div>
                <div class="expiring">
                    <p>EXPIRING</p>
                </div>
            </div>

            <div class='sidebar-calendar p-shadow p-radius white-BG'>
                <calendar-credit></calendar-credit>
            </div>
            
            <div class='sidebar-categories-title'>
                <p class="category-subtitle">Category Breakdown</p>
                <div class='divider-highlight'>
                </div>
                <div class="category-breakdown-container-sidebar">
                    <section class='category-breakdown'>
                        <div *ngFor="let category of categoryDataArray" class="category-card white-BG p-padding p-shadow">
                            <div>
                                <p>{{category.category}}</p>
                                <p>Trans: {{category.transactions}}</p>
                            </div>
                            <div class="inner-category-sidebar-data">
                                <div>
                                    <p class="red-text">{{category.debitAmount}}</p>
                                    <p>Debit</p>
                                </div>
                                <div>
                                    <p class="green-text">{{category.creditAmount}}</p>
                                    <p>Credit</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <div id="filter-container" [ngClass]="{'show-filters': _modalBG.panelStatus[3].filters.value}">
            <dept-filters [data]="tableData" [filterList]="tableComponent.columnNames" *ngIf="tableComponent" [pageValues]="_fn.gridAreaNames" (columnNamesEvt)="receiveNewColNames($event)" (abreviationEvt)="getNewData($event)"></dept-filters>
        </div>
    </div>