<div id="main-content" data-page="sub-dept-analysis">
    <div class="header-content">
        <div class="page-name">
            <page-name [pageName]='"Sub Department Analysis"'></page-name>
        </div>
        <div [ngClass]="{'mobile-background':screenSize < 1100}">
            <dropdown-menus (ddInfo)='changeDD($event)'></dropdown-menus>
        </div>
    </div>
    <div class="page-content">
        <div class="grid">
            <div>
                <div>
                    <div id="remaining-budget" class="p-padding p-radius p-shadow white-BG">
                        <div class="chart-data">
                            <div *ngIf="screenSize > 1100">
                                <div>
                                    <p>Total Expenditures: April</p>
                                </div>
                                <div class="increase">
                                    <green-box [arrow]="true" [text]="'14% INCREASE FROM LAST YEAR'" [fontSize]="10"></green-box>
                                </div>
                            </div>
                            <div class="sum-amount" *ngIf="screenSize > 1100">
                                <p>${{ formatNumber(progression) }}<span>.12</span></p>
                            </div>
                            <div class="data-collection">
                                <div class="data-grid" *ngFor="let department of departmentData">
                                    <div>
                                        <div class="indicator"></div>
                                        <div class="text">{{ department.name }}</div>
                                    </div>
                                    <div class="dollar-amount">{{ department.expenditure }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="chart">
                            <multi-line-chart [colors]="colors" [data]="dataArray"></multi-line-chart>
                        </div>
                        <div class='mobile-graph-title' *ngIf="screenSize <= 1100">
                            <div>
                                <p>Total Expenditures: April</p>
                                <div>
                                    <span class="dollar-sign">$</span>
                                    <span class="dollar-amount">12,230,593</span>
                                    <span class="cents">.12</span>
                                </div>
                            </div>
                            <div class="increase">
                                <green-box [arrow]="true" [text]="'14% INCREASE FROM LAST YEAR'"></green-box>
                            </div>
                        </div>
                    </div>
                    <!-- Favorited Dept -->
                    <div id="budget-allocation" class="p-padding p-radius p-shadow white-BG">

                        <div class="title">
                            <p>
                                Budget Allocation Percentages
                            </p>
                        </div>

                        <div class="progress-bar">
                            <div *ngFor="let department of departmentData; let i = index" [style.backgroundColor]="colors[i]" [style.width.%]='department.allocationPercent'></div>
                        </div>

                        <div class="repeating-blocks" *ngFor="let department of departmentData; let i = index">
                            <div class="dept-block">
                                <div>
                                    <div class="color" [style.background-color]="colors[i]"></div>
                                    <div class="sub-department">{{ department.name }}</div>
                                </div>
                                <div>
                                    <div class="amount">{{ department.allocation }}</div>
                                    <div class="percent">{{ department.allocationPercent }}%</div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div id="budgeted-forecast" class="p-padding p-radius p-shadow white-BG">
                        <div class="headline" *ngIf="screenSize > 675">
                            <div class="info-icon">&#9432;</div>
                            <p (click)="generalInfoClick()">General Information</p>
                        </div>

                        <div class="repeating-blocks" *ngFor="let item of superlatives">
                            <div class="dept-status">
                                {{ item.name }}
                            </div>
                            <div class="two-column">
                                <div>
                                    <p>{{ item.department }}</p>
                                </div>
                                <div>
                                    <p class="dollars-p">{{ item.amount }}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>