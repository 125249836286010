<div id="main-content" data-page="expenditures">
    <div class="header-content">
        <div class="page-name">
            <page-name [pageName]='"Expenditures"'></page-name>
        </div>
        <div [ngClass]="{'mobile-background':screenSize < 1101}">
            <dropdown-menus (ddInfo)='changeDD($event)'></dropdown-menus>
        </div>
    </div>
    <div class="line-chart p-shadow p-radius white-BG">
        <area-chart-header></area-chart-header>
        <div class="area-chart">
            <app-area-line-chart [monthData]="currentYear" [duration]="duration" [screensize]="screenSize"></app-area-line-chart>
        </div>
    </div>
    <div class="page-content">
        <div class="data-table">
            <div class="wrapper">
                <div class="btn-group">
                    <btn-group [btns]='["Posted", "Pending"]' (clickEvent)="btnClick($event)"></btn-group>
                </div>
                <div class='search'>
                    <search-bar [placeholder]="'Search Dept, Contact, Etc..'" (filterStatus)="_fn.transparentBG($event)">
                    </search-bar>
                </div>
            </div>
            <div>
                <dept-table #deptTable [data]="tableValue" [itemsPerPageInput]="10" [firstColumnWidth]="200" [pageValues]="_fn.gridAreaNames" [pageLink]="'vendor-contract-details'"></dept-table>
            </div>
        </div>
    </div>
    <div id="filter-container" [ngClass]="{'show-filters': _modalBG.panelStatus[3].filters.value}">
        <dept-filters [data]="tableValue" [filterList]="tableComponent.columnNames" [pageValues]="_fn.gridAreaNames" (columnNamesEvt)="receiveNewColNames($event)"></dept-filters>
    </div>
</div>