import { Component, OnInit, Output, ViewChild, ElementRef, OnDestroy, ChangeDetectorRef, HostListener } from '@angular/core';
import { WindowWidthService } from 'src/app/services/windowWidth.service';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from 'rxjs';
import { ModalBgService } from 'src/app/services/modal-bg.service';
import { DeptTableComponent } from '../../components/_reusable/dept-table/dept-table.component';
import { GlobalFnService } from 'src/app/services/global-functions.service';
import * as vendorJson from './vendor-data.json';
import * as contractJson from './contract-data.json';
import * as breakdownJson from './breakdownData.json'
import { DeptFiltersComponent } from 'src/app/components/dept-filters/dept-filters.component';

@AutoUnsubscribe()

@Component({
  selector: 'app-vendor-main-landing',
  templateUrl: './vendor-main-landing.component.html',
  styleUrls: ['./vendor-main-landing.component.scss']
})
export class VendorMainLandingComponent implements OnInit, OnDestroy {

  protected subscription1$: Subscription;
  protected subscription2$: Subscription;

  p: number = 1;
  itemsPerPage: number[] = [18, 8];
  result: any = [];
  tableVal: string = 'all';
  currentIndex: number;
  currentSubIndex: number;
  currentPage: HTMLElement;
  pageTitle: string;
  screenSize: number;
  currentLayout: string = 'vendors';
  filterActive: any;
  btnNames: any = ["All", "Active", "Expired"];
  updateTable: boolean = true;
  tableIndex: number = 0;
  tableValue: any = [];
  viewSelection: any = this.btnNames[this.tableIndex];
  tableLink: string = 'vendors-drilldown-overview'
  spendingLink: string = '/vendor-spending';
  vendorData: any = vendorJson.tableData;
  contractData: any = contractJson.tableData;
  tableData: any = this.vendorData;
  breakdownData: any = breakdownJson.data;

  currentYear: any = new Date().getFullYear();
  currentMonth: any = new Date().getMonth();
  months: any = ['Jan', 'Feb', 'Mar', 'Apr', 'May', "June", "July", 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

  @ViewChild('header', { static: false }) header: ElementRef;
  @ViewChild('menuGroup', { static: false }) menuGroup: ElementRef;
  @ViewChild(DeptTableComponent) deptTable: any;
  @ViewChild(DeptFiltersComponent) dFilters: any;

  // Get Column Names from Table
  @ViewChild(DeptTableComponent) tableComponent: any = [];
  columnNames: string;
  @Output() visibleFilters: any = this.tableComponent.columnNames;
  @Output() gridAreaNames: any;
  filterStatus: any;
  abreviationArray: any = [];

  constructor(
    private _windowWidth: WindowWidthService,
    public _modalBG: ModalBgService,
    private _cd: ChangeDetectorRef,
    public _fn: GlobalFnService
  ) { }

  ngOnInit(): void {
    this.subscription1$ = this._modalBG.currentVal.subscribe(currentVal => this.filterActive = currentVal);
    this.subscription2$ = this._windowWidth.currentVal.subscribe(currentVal => this.screenSize = currentVal);
    this.retrieveData(0)
  }

  ngAfterViewInit() {
    this.setTableColumns()
    this._cd.detectChanges()
  }

  ngOnDestroy() { }

  setTableColumns() {
    this._fn.createTableGridArea(this.tableData[0][0]);
    this._fn.createFilterGridArea(this.dFilters, this.tableData[0][0]);
  }

  // Receive from filters, send full/abreviation to table (column 1)
  getNewData(e: any) {
    this.abreviationArray = [];
    if (e) {
      for (var i = 0; i < this.tableValue.length; i++) {
        this.abreviationArray.push(this.tableValue[i]._abreviation);
      }
    }
  }

  changeLayout(selection) {
    this.currentLayout = selection;
    this.retrieveData(this.tableIndex);
    if (this.currentLayout === 'vendors') {
      this.tableLink = 'vendors-drilldown-overview';
      this.spendingLink = '/vendor-spending';
      this.tableData = this.vendorData;
    } else {
      this.tableLink = 'vendor-contract-details';
      this.spendingLink = '/contract-spending';
      this.tableData = this.contractData;
    }
    this.retrieveData(this.tableIndex);
    this.loadTable();
    console.log(this.abreviationArray)
  }

  // All Active Expired Selection
  btnClick(i: number) {
    this.tableIndex = i;
    this.retrieveData(this.tableIndex);
    this.setTableColumns()
    this._fn.restoreColumns();
    this.getNewData(true);
  }

  retrieveData(i: number) {
    i = this.tableIndex;
    this.tableValue = this.tableData[i];
    this.viewSelection = this.btnNames[this.tableIndex];
  }

  // Create new grid-template-area
  receiveNewColNames(e: any) {
    this.gridAreaNames = "";
    for (var i = 0; i < e.length; i++) {
      this.gridAreaNames += e[i] + " "
    }
    this.gridAreaNames = "'" + this.gridAreaNames + "'";
  }

  changeDD(e: any) {
    this.tableValue = this._fn.changeDD(this.tableValue)
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.loadTable()
  }

  loadTable() {
    this.setTableColumns();
    this._fn.restoreColumns();
    this.dFilters.resetToggles();
    this._fn.altReset();
    this.getNewData(true);
  }



}