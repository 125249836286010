import { Component, OnInit, HostListener, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { WindowWidthService } from 'src/app/services/windowWidth.service';
import { CurrentRouteService } from 'src/app/services/current-route.service';
import { OpenContactService } from 'src/app/services/open-contact.service';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from 'rxjs';

@AutoUnsubscribe()

@Component({
  selector: 'fav-compare-contact',
  templateUrl: './fav-compare-contact.component.html',
  styleUrls: ['./fav-compare-contact.component.scss']
})
export class FavCompareContactComponent implements OnInit, OnDestroy {

  // Used in /departments & /name-of-dept
  protected subscription1$: Subscription;
  protected subscription2$: Subscription;
  protected subscription3$: Subscription;

  favorited: boolean;
  windowWidth: number;
  userRoute: string;
  tabletBreakpoint: number;
  contactCardPanel: boolean;

  @ViewChild('btnGroup', { static: false }) btnGroup: ElementRef;

  constructor(
    private _wService: WindowWidthService,
    private _urlService: CurrentRouteService,
    private _contactService: OpenContactService
  ) { }

  ngOnInit(): void {
    // Contact Card
    this.subscription1$ = this._contactService.currentVal.subscribe(currentVal => this.contactCardPanel = currentVal);
    this._contactService.contactCardState = this.contactCardPanel;

    // Tracks current URL
    this.subscription2$ = this._urlService.currentVal.subscribe(currentVal => this.userRoute = currentVal);
    this._urlService.currentRoute = this.userRoute;

    // Tracks window width
    this.subscription1$ = this._wService.currentVal.subscribe(currentVal => this.windowWidth = currentVal);
    this._wService.someWidth = this.windowWidth;

    this.userRoute != "/name-of-dept" ? this.tabletBreakpoint = 1100 : this.tabletBreakpoint = 1100;
  }

  ngAfterViewInit() {
    this.setBreakpoint()
  }

  ngOnDestroy(){}

  setBreakpoint() {
    // Toggle breakpoint depending on route
    if (this.userRoute === "/name-of-dept" && this.windowWidth > 1099) {
      this.btnGroup.nativeElement.setAttribute("id", "dsktop-layout")
    }
    if (this.userRoute === "/name-of-dept" && this.windowWidth < 1100 || this.userRoute === "/sub-dept-analysis" && this.windowWidth < 1099) {
      this.btnGroup.nativeElement.setAttribute("id", "tablet-layout")
    }
  }

  contactCardToggle() {
    this.contactCardPanel = !this.contactCardPanel;
    this._contactService.contactCardState = this.contactCardPanel;
    this._contactService.changeValue(this.contactCardPanel)
  }

  // Toggle favorited btn
  toggleFavorite() {
    this.favorited = !this.favorited
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.windowWidth = window.innerWidth;
    this.setBreakpoint()
  }

}
