import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsPageRoutingModule, settingsPageRoutingComponents } from './settings-page-routing.module';
import { SharedModule } from '../../modules/shared/shared.module'

@NgModule({
  declarations: [
    settingsPageRoutingComponents
  ],
  imports: [
    CommonModule,
    SettingsPageRoutingModule,
    SharedModule
  ]
})
export class SettingsPageModule { }
