<div id="main-content" data-page="more">
  <div class="header-content">
      <div class="page-name">
          <page-name [pageName]='"More"'></page-name>
      </div>
  </div>

  <div class="page-content">
      
    <div class='p-shadow p-radius white-BG' [routerLink]="['/spending-milestone']">
      <div class='left-section db'>
        <div class="img-container">
          <img src="../../../assets/more/spending_milestones.svg" class="vCenter refresh image"/>
        </div>
        
      </div>
      <div class='right-section'>
        <div class="subheader-row">
          <h2>Spending Milestone</h2>
          <i class="fa fa-chevron-right"></i>
        </div>
        <p>Compare departments to see if they are meeting spending goals</p>
      </div>
    </div>
    <div class='p-shadow p-radius white-BG voucher-break' [routerLink]="['/voucher-breakdown']">
      <div class='left-section lb'>
        <div class="img-container img-container-voucher">
          <img src="../../../assets/more/voucher_breakdown.svg" class="vCenter refresh image"/>
        </div>
      </div>
      <div class='right-section'>
        <div class="subheader-row">
          <h2>Voucher Breakdown</h2>
          <i class="fa fa-chevron-right"></i>
        </div>
        <p>View insights on various voucher types</p>
      </div>
    </div>
    <div class='p-shadow p-radius white-BG comp-report' [routerLink]="['/comparison-report-select']">
      <div class='left-section dg'>
        <div class="img-container">
          <img src="../../../assets/more/comparison_report.svg" class="vCenter refresh image"/>
        </div>
      </div>
      <div class='right-section'>
        <div class="subheader-row">
          <h2>Comparison Report</h2>
          <i class="fa fa-chevron-right"></i>
        </div>
        <p>Compare depts, fiscal yrs, contracts or vouchers to identify key differences</p>
      </div>
    </div>
    <div class='p-shadow p-radius white-BG' [routerLink]="['/credit-card-transactions']">
      <div class='left-section lg'>
        <div class="img-container">
          <img src="../../../assets/more/credit-card-transaction.svg" class="vCenter refresh image"/>
        </div>
      </div>
      <div class='right-section'>
        <div class="subheader-row">
          <h2>Credit Card Transactions</h2>
          <i class="fa fa-chevron-right"></i>
        </div>
        <p>View Agency wide transactions or by dept. basis</p>
      </div>
    </div>
  </div>

</div>