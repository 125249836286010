import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComparisonReportSelectRoutingModule, comparisonReportSelectRoutingComponents } from './comparison-report-select-routing.module';
import { SharedModule } from '../../modules/shared/shared.module';

@NgModule({
  declarations: [
    comparisonReportSelectRoutingComponents
  ],
  imports: [
    CommonModule,
    ComparisonReportSelectRoutingModule,
    SharedModule
  ]
})
export class ComparisonReportSelectModule { }
