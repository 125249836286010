import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotificationsPageComponent } from './top_navigation/notifications-page/notifications-page.component';

const routes: Routes = [
  {
    path: 'search',
    loadChildren: () => import('./global-search/global-search-landing_m/global-search-landing.module').then(m => m.GlobalSearchLandingModule),
    data:{
      category: 'GlobalSearch',
      breadcrumbs: ['Search']}
  }, {
    path: 'search/results/:searchFor',
    loadChildren: () => import('./global-search/global-search-results_m/global-search-results.module').then(m => m.GlobalSearchResultsModule),
    data:{
      category: 'GlobalSearch',
      breadcrumbs: ['Search']}
  }, {
    path: 'search/results',
    loadChildren: () => import('./global-search/global-search-results_m/global-search-results.module').then(m => m.GlobalSearchResultsModule),
    data:{
      category: 'Search',
      breadcrumbs: ['Search']}
  }, {
    path: 'notifications', component: NotificationsPageComponent,
    data:{
      category: 'Other',
      breadcrumbs: ['Notifications']}
  }, {
    path: 'settings',
    loadChildren: () => import('./top_navigation/settings-page_m/settings-page.module').then(m => m.SettingsPageModule),
    data:{
      category: 'Other',
      breadcrumbs: ['Settings']}
  }, {
    path: 'landing',
    loadChildren: () => import('./home/landing_m/landing.module').then(m => m.LandingModule),
    data:{
      category: 'Home',
      breadcrumbs: []}
  }, {
    path: 'departments',
    loadChildren: () => import('./department/all-departments_m/all-departments.module').then(m => m.AllDepartmentsModule),
    data:{
      category: 'Departments',
      breadcrumbs: ['']}
  }, {
    path: 'vendor-contract-details',
    loadChildren: () => (import('./vendor_contract/vendor-contract-details_m/vendor-contract-details.module').then(m => m.VendorContractDetailsModule)),
    data:{
      category: 'Vendor Contract',
      breadcrumbs: ['Vendor', 'Purchase Order #', "Overview"]}
  }, {
    path: 'commitments',
    loadChildren: () => import('./department/commitments_m/commitments.module').then(m => m.CommitmentsModule),
    data:{
      category: 'Departments',
      breadcrumbs: ['Home', 'Commitments']}
  }, {
    path: 'obligations',
    loadChildren: () => import('./department/obligations_m/obligations.module').then(m => m.ObligationsModule),
    data:{
      category: 'Departments',
      breadcrumbs: ['Home', 'Obligations']}
  }, {
    path: 'expenditures',
    loadChildren: () => import('./department/expenditures_m/expenditures.module').then(m => m.ExpendituresModule),
    data:{
      category: 'Departments',
      breadcrumbs: ['Home', 'Expenditures']}
  }, {
    path: 'vendor-breakdown',
    loadChildren: () => import('./vendor_contract/vendor-breakdown_m/vendor-breakdown.module').then(m => m.VendorBreakdownModule),
    data:{
      category: 'Vendor Contract',
      breadcrumbs: ['Home', 'Obligations', 'Vendor-Breakdown']}
  }, {
    path: 'vendor-main-landing',
    loadChildren: () => import('./vendor_contract/vendor-main-landing_m/vendor-main-landing.module').then(m => m.VendorMainLandingModule),
    data:{
      category: 'Vendor Contract',
      breadcrumbs: []}
  }, {
    path: 'vendor-spending',
    loadChildren: () => import('./vendor_contract/vendor-spending_m/vendor-spending.module').then(m => m.VendorSpendingModule),
    data:{
      category: 'Vendor Contract',
      breadcrumbs: ['Vendor', 'Vendor-Spending']}
  }, {
    path: 'vendors-drilldown-overview',
    loadChildren: () => import('./vendors/vendors-drilldown-overview_m/vendors-drilldown-overview.module').then(m => m.VendorsDrilldownOverviewModule),
    data:{
      category: 'Vendor Contract',
      breadcrumbs: ['Vendor', 'Vendor-Drilldown']}
  }, {
    path: 'vendors-po-drilldown-invoices',
    loadChildren: () => import('./vendors/vendors-po-drilldown-invoices_m/vendors-po-drilldown-invoices.module').then(m => m.VendorsPoDrilldownInvoicesModule),
    data:{
      category: 'Vendor Contract',
      breadcrumbs: ['Vendor', '...', 'Purchase Order #', 'Invoices']}
  }, {
    path: 'name-of-asset',
    loadChildren: () => import('./home/name-of-asset_m/name-of-asset.module').then(m => m.NameOfAssetModule),
    data:{
      category: 'Home',
      breadcrumbs: ['Vendor', '...', 'Purchase Order #', 'Invoice #', 'Name of Asset']}
  }, {
    path: 'vendors-po-drilldown-vouchers-detail',
    loadChildren: () => import('./vendors/vendors-po-drilldown-vouchers-voucher-detail_m/vendors-po-drilldown-vouchers-voucher-detail.module').then(m => m.VendorsPoDrilldownVouchersVoucherDetailModule),
    data:{
      category: 'Vendor Contract',
      breadcrumbs: ['Vendor', '...', 'Purchase Order #', 'Voucher #']}
  }, {
    path: 'vendors-po-drilldown-invoices-detail',
    loadChildren: () => import('./vendors/vendors-po-drilldown-invoices-invoice-detail_m/ vendors-po-drilldown-invoices-invoice-detail.module').then(m => m.VendorsPoDrilldownInvoicesInvoiceDetailModule),
    data:{
      category: 'Vendor Contract',
      breadcrumbs: ['Vendor', '...', 'Purchase Order #', 'Invoice #']}
  }, {
    path: 'sub-dept-analysis',
    loadChildren: () => import('./department/sub-dept-analysis_m/sub-dept-analysis.module').then(m => m.SubDeptAnalysisModule),
    data:{
      category: 'Department',
      breadcrumbs: ['Department', 'Department Name', 'Sub-department Analysis']}
  }, {
    path: 'global-search-contact',
    loadChildren: () => import('./global-search/global-search-contact_m/global-search-contact.module').then(m => m.GlobalSearchContactModule),
    data:{
      category: 'GlobalSearch',
      breadcrumbs: ['Global Search', 'Contact']}
  }, {
    path: 'more',
    loadChildren: () => import('./more/more_m/more.module').then(m => m.MoreModule),
    data:{
      category: 'More',
      breadcrumbs: ['']}
  }, {
    path: 'spending-milestone',
    loadChildren: () => import('./more/spending-milestone_m/spending-milestone.module').then(m => m.SpendingMilestoneModule),
    data:{
      category: 'More',
      breadcrumbs: ['Menu', 'Spending Milestone']}
  }, {
    path: 'comparison-report-select',
    loadChildren: () => import('./more/comparison-report-select_m/comparison-report-select.module').then(m => m.ComparisonReportSelectModule),
    data:{
      category: 'More',
      breadcrumbs: ['Menu', 'Comparison']}
  }, {
    path: 'comparison',
    loadChildren: () => import('./more/comparison_m/comparison.module').then(m => m.ComparisonModule),
    data:{
      category: 'More',
      breadcrumbs: ['Menu', 'Comparison Report']}
  }, {
    path: 'voucher-breakdown',
    loadChildren: () => import('./more/voucher-breakdown_m/voucher-breakdown.module').then(m => m.VoucherBreakdownModule),
    data:{
      category: 'More',
      breadcrumbs: [ 'Contract', 'Voucher Breakdown']}
  }, {  
    path: 'credit-card-transactions',
    loadChildren: () => import('./more/credit-card-transactions_m/credit-card-transactions.module').then(m => m.CreditCardTransactionsModule),
    data:{
      category: 'More',
      breadcrumbs: ['Department of AntiTrust and Fraud', 'Credit Card Transactions']}
  }, {
    path: 'contract-spending',
    loadChildren: () => import('./vendor_contract/contract-spending_m/contract-spending.module').then(m => m.ContractSpendingModule),
    data:{
      category: 'Vendor Contract',
      breadcrumbs: ['Contract', 'Contract Spending']}
  }, {
    path: 'burn-rate-month',
    loadChildren: () => import('./vendor_contract/burn-rate-month_m/burn-rate-month.module').then(m => m.BurnRateMonthModule),
    data:{
      category: 'More',
      breadcrumbs: ['Vendor', 'Vendor Name', 'Burn Rate Over Time']}
  }, {
    path: 'contract-obligations-breakdown',
    loadChildren: () => import('./vendor_contract/contract-obligations-breakdown_m/contract-obligations-breakdown.module').then(m => m.ContractObligationsBreakdownModule),
    data:{
      category: 'Vendor Contract',
      breadcrumbs: ['Vendor', 'Contract Drilldown']}
  }, {
    path: 'burn-rate-rank',
    loadChildren: () => import('./vendor_contract/burn-rate-rank_m/burn-rate-rank.module').then(m => m.BurnRateRankModule),
    data:{
      category: 'More',
      breadcrumbs: ['Vendor', 'Vendor Name', 'Burn Rate Over Time']}
  }, {
    path: 'department-drilldown',
    loadChildren: () => import('./department/dept-drilldown_m/dept-drilldown.module').then(m => m.DeptDrilldownModule),
    data:{
      category: 'Departments',
      breadcrumbs: ['Department', 'Department of Anti Trust']}
  }, {
    path: 'burn-rate-category',
    loadChildren: () => import('./vendor_contract/burn-rate-category_m/burn-rate-category.module').then(m => m.BurnRateCategoryModule),
    data:{
      category: 'More',
      breadcrumbs: ['Vendor', 'Burn Rate by Expense Category']}
  }, {
    path: 'server-connect',
    loadChildren: () => import('./home/login_m/login.module').then(m => m.LoginModule),
    data: {
      showSidebar: false,
      showHeader: false
    }
  }, {
    path: '**',
    redirectTo: '/landing'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const appRoutingComponents = [
  NotificationsPageComponent
]