import { Component, OnInit } from '@angular/core';
import * as data from './sidebar.json';

@Component({
  selector: 'burn-rate-sidebar',
  templateUrl: './burn-rate-sidebar.component.html',
  styleUrls: ['./burn-rate-sidebar.component.scss']
})
export class BurnRateSidebarComponent implements OnInit {

  sidebarData:any = data.sidebar;

  constructor() { }

  ngOnInit(): void {}

}
