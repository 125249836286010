import { Component, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { GlobalFnService } from 'src/app/services/global-functions.service';
import { WindowWidthService } from 'src/app/services/windowWidth.service';

@Component({
  selector: 'sidebar-toggle-icon',
  templateUrl: './sidebar-toggle-icon.component.html',
  styleUrls: ['./sidebar-toggle-icon.component.scss']
})

@AutoUnsubscribe()

export class SidebarToggleIconComponent implements OnInit, OnDestroy {

  protected subscription1$: Subscription;

  screenSize:number;
  visible:boolean = false;
  @Output() emitValue:any = new EventEmitter<boolean>();

  constructor(
    private _winWidth: WindowWidthService,
    public _fn:GlobalFnService
  ) { }

  ngOnInit(): void {
    this.subscription1$ = this._winWidth.currentVal.subscribe(currentVal => this.screenSize = currentVal);
  }

  ngOnDestroy(){}

  toggleValue() {
    this.visible = true;
    this._fn.sidebarVisible = this.visible;
    this.emitValue.emit(this.visible);
  }

}
