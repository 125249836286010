import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MoreComponent } from '../more/more.component';

const routes: Routes = [
  { path: '', component: MoreComponent, children: [
      { path:'more', component: MoreComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MoreRoutingModule { }

export const moreRoutingComponents = [
  MoreComponent
]
