import { Component, OnDestroy, OnInit } from '@angular/core';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from 'rxjs';
import { ModalBgService } from 'src/app/services/modal-bg.service';

@AutoUnsubscribe()

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, OnDestroy {

  protected subscription1$: Subscription;

  currentSize:number;
  userPanelStatus:boolean;
  modal:any;

  constructor(
    private _modalBG:ModalBgService
  ) { }

  ngOnInit(): void {
    this.subscription1$ = this._modalBG.currentVal.subscribe(currrentVal => this.modal = currrentVal);
  }

  ngOnDestroy(){}

  toggleSettings(){
    this._modalBG.panelStatus[2].settings.value = !this._modalBG.panelStatus[2].settings.value;
    this._modalBG.changeValue(2, 'settings', this._modalBG.panelStatus[2].settings.value);
  }

}
