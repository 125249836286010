<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.381 23">
    <defs>
        <style>
            .a,
            .d {
                fill: none
            }

            .a {
                stroke-width: 1.5px
            }

            .b {
                stroke: #fff
            }

            .c {
                stroke: none
            }
        </style>
    </defs>
    <g transform="translate(-403.5 -616.3)">
        <path class="a" [ngClass]="{'oceanBlue':filterTab}" d="M0,0V23.38" transform="translate(403.5 627.859) rotate(-90)" />
        <path class="a" [ngClass]="{'oceanBlue':filterTab}" d="M0,0V23.38" transform="translate(426.881 619.275) rotate(90)" />
        <path class="a" [ngClass]="{'oceanBlue':filterTab}" d="M0,0V23.38" transform="translate(426.881 636.18) rotate(90)" />
        <g class="b" transform="translate(407.5 624.75)">
            <rect class="c" width="6" height="6" rx="3" />
            <rect class="d" x="0.5" y="0.5" width="5" height="5" rx="2.5" />
        </g>
        <g class="b" transform="translate(416.5 616.3)">
            <rect class="c" width="6" height="6" rx="3" />
            <rect class="d" x="0.5" y="0.5" width="5" height="5" rx="2.5" />
        </g>
        <g class="b" transform="translate(416.5 633.3)">
            <rect class="c" width="6" height="6" rx="3" />
            <rect class="d" x="0.5" y="0.5" width="5" height="5" rx="2.5" />
        </g>
    </g>
</svg>