import { Component, OnInit, OnDestroy, Output, ViewChild, ChangeDetectorRef, AfterViewInit, ElementRef } from '@angular/core';
import * as filteredCharts from './filteredCharts.json';
import * as tableData from './table-data.json';
import { WindowWidthService } from 'src/app/services/windowWidth.service';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from 'rxjs';
import { ModalBgService } from 'src/app/services/modal-bg.service';
import { DeptTableComponent } from '../../components/_reusable/dept-table/dept-table.component';
import { GlobalFnService } from 'src/app/services/global-functions.service';
import { DeptFiltersComponent } from 'src/app/components/dept-filters/dept-filters.component';
import { AreaLineChartComponent } from 'src/app/components/_reusable/area-line-chart/area-line-chart.component';

@AutoUnsubscribe()

@Component({
  selector: 'app-expenditures',
  templateUrl: './expenditures.component.html',
  styleUrls: ['./expenditures.component.scss']
})
export class ExpendituresComponent implements OnInit, OnDestroy, AfterViewInit {

  protected subscription1$: Subscription;
  protected subscription2$: Subscription;

  // Get Column Names from Table
  @ViewChild(DeptTableComponent) tableComponent: any = [];
  columnNames: string;
  @Output() visibleFilters: any = this.tableComponent.columnNames;
  @Output() gridAreaNames: any;
  filterStatus: any;

  filterActive: any;

  @ViewChild(DeptTableComponent) deptTable: ElementRef;
  @ViewChild(DeptFiltersComponent) dFilters: any;
  @ViewChild(AreaLineChartComponent) areaLineChart:any;

  screenSize: number;
  result: any = [];
  filteredCharts: any = filteredCharts;
  currentYear: any = new Date().getFullYear();
  duration: string = 'Monthly';
  filteredChartsArray: any = [];
  dataIndex: number;
  tableData = tableData.tableData;
  tableIndex: number = 0;
  tableValue: any = [];
  tableArray: any = [];

  constructor(
    private _windowWidth: WindowWidthService,
    public _modalBG: ModalBgService,
    private _cd: ChangeDetectorRef,
    public _fn: GlobalFnService
  ) { }

  ngOnInit(): void {
    this.subscription1$ = this._windowWidth.currentVal.subscribe(currentVal => this.screenSize = currentVal);
    this.subscription2$ = this._modalBG.currentVal.subscribe(currentVal => this.filterActive = currentVal)

    for (let key in filteredCharts.dataArray) {
      if (filteredCharts.dataArray.hasOwnProperty(key)) {
        this.filteredChartsArray.push(filteredCharts.dataArray[key])
      }
    }
    this.retrieveData(0)
  }

  ngAfterViewInit() {
    this.dFilters.showAbreviations = false;
    this._fn.createTableGridArea(this.tableArray[0][0] );
    this._fn.createFilterGridArea(this.dFilters, this.tableArray[0][0])
    this._cd.detectChanges();
  }

  ngOnDestroy() { }

  // Create new grid-template-area
  receiveNewColNames(e: any) {
    this.gridAreaNames = "";
    for (var i = 0; i < e.length; i++) {
      this.gridAreaNames += e[i] + " "
    }
    this.gridAreaNames = "'" + this.gridAreaNames + "'";
  }

  retrieveData(i: number) {
    for (let key in tableData.tableData) {
      if (tableData.tableData.hasOwnProperty(key)) {
        this.tableArray.push(tableData.tableData[key])
      }
    }
    i = this.tableIndex;
    this.tableValue = this.tableArray[i];
  }

  btnClick(i: number) {
    this.tableIndex = i;
    this.retrieveData(this.tableIndex)
  }

  changeDD(e:any) {
    this.tableValue = this._fn.changeDD(this.tableValue);
    this._fn.randomizeChartData(this.areaLineChart)
  }

}