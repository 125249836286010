import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule, appRoutingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';

// Modules
import { LandingModule } from './home/landing_m/landing.module';
import { ExpendituresModule } from './department/expenditures_m/expenditures.module';
import { CommitmentsModule } from './department/commitments_m/commitments.module';
import { ObligationsModule } from './department/obligations_m/obligations.module';
import { AllDepartmentsModule } from './department/all-departments_m/all-departments.module';
import { VendorContractDetailsModule } from './vendor_contract/vendor-contract-details_m/vendor-contract-details.module';
import { VendorBreakdownModule } from './vendor_contract/vendor-breakdown_m/vendor-breakdown.module';
import { VendorMainLandingModule } from './vendor_contract/vendor-main-landing_m/vendor-main-landing.module';
import { SubDeptAnalysisModule } from './department/sub-dept-analysis_m/sub-dept-analysis.module';
import { VendorSpendingModule } from './vendor_contract/vendor-spending_m/vendor-spending.module';
import { VendorPOInvoiceListModule } from './vendor_contract/vendor-po-invoice-list_m/vendor-po-invoice-list.module';
import { GlobalSearchContactModule } from './global-search/global-search-contact_m/global-search-contact.module';
import { SharedModule } from './modules/shared/shared.module'
import { DemoMaterialModule } from './modules/material-module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

//Modules (Phase 2)
import { MoreModule } from './more/more_m/more.module';
import { SpendingMilestoneModule } from './more/spending-milestone_m/spending-milestone.module';
import { ComparisonReportSelectModule } from './more/comparison-report-select_m/comparison-report-select.module';
import { ComparisonModule } from './more/comparison_m/comparison.module';
import { VoucherBreakdownModule } from './more/voucher-breakdown_m/voucher-breakdown.module';
import { CreditCardTransactionsModule } from './more/credit-card-transactions_m/credit-card-transactions.module';
import { ContractSpendingModule } from './vendor_contract/contract-spending_m/contract-spending.module';
import { BurnRateMonthModule } from './vendor_contract/burn-rate-month_m/burn-rate-month.module';
import { ContractObligationsBreakdownModule } from './vendor_contract/contract-obligations-breakdown_m/contract-obligations-breakdown.module';
import { BurnRateRankModule } from './vendor_contract/burn-rate-rank_m/burn-rate-rank.module';
import { BurnRateCategoryModule } from './vendor_contract/burn-rate-category_m/burn-rate-category.module';
import { DeptDrilldownModule } from './department/dept-drilldown_m/dept-drilldown.module';

// Components
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { HeaderComponent } from './components/header/header.component';
import { NotificationsComponent } from './top_navigation/notifications/notifications.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { UserProfileComponent } from './top_navigation/user-profile/user-profile.component';
import { UserProfileMobileComponent } from './top_navigation/user-profile-mobile/user-profile-mobile.component';
import { SettingsComponent } from './top_navigation/settings/settings.component';
import { SettingsPageModule } from './top_navigation/settings-page_m/settings-page.module';
import { DivComponent } from './components/div/div.component';

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    HeaderComponent,
    NotificationsComponent,
    UserProfileComponent,
    UserProfileMobileComponent,
    SettingsComponent,
    appRoutingComponents,
    DivComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    LandingModule,
    ExpendituresModule,
    CommitmentsModule,
    ObligationsModule,
    NoopAnimationsModule,
    AllDepartmentsModule,
    VendorContractDetailsModule,
    VendorBreakdownModule,
    VendorMainLandingModule,
    SubDeptAnalysisModule,
    VendorSpendingModule,
    VendorPOInvoiceListModule,
    BurnRateMonthModule,
    GlobalSearchContactModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    SettingsPageModule,
    MoreModule,
    SpendingMilestoneModule,
    ComparisonReportSelectModule,
    ComparisonModule,
    VoucherBreakdownModule,
    CreditCardTransactionsModule,
    ContractSpendingModule,
    ContractObligationsBreakdownModule,
    BurnRateRankModule,
    BurnRateCategoryModule,
    DeptDrilldownModule,
    SharedModule,
    DemoMaterialModule,
    InfiniteScrollModule
  ],
  exports: [
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    ReactiveFormsModule,
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}
