<div id="main-content" data-page="vendor-main-landing">
    <div class="header-content">
        <div class="header-toggle">
            <h2 [ngClass]="{'toggled-off': currentLayout === 'contracts'}" class="page-title" (click)="changeLayout('vendors')">Vendors</h2>
            <h2 [ngClass]="{'toggled-off': currentLayout === 'vendors'}" class="page-title" (click)="changeLayout('contracts')">Contracts</h2>
        </div>
        <div [ngClass]="{'mobile-background':screenSize <= 1100}">
            <dropdown-menus (ddInfo)='changeDD($event)'></dropdown-menus>
        </div>

        <div class="amount-spent p-shadow p-radius white-BG" *ngIf="screenSize > 1100" [routerLink]="spendingLink">
            <div class="text">
                <p>Amount Spent This Month: <span>${{ _fn.numberWithCommas(breakdownData[currentLayout][0]) }}</span><span *ngIf="screenSize > 1200">|</span></p>
                <p>Amount Spent Last Month: ${{ _fn.numberWithCommas(breakdownData[currentLayout][1]) }}</p>
            </div>
            <i class="fa fa-chevron-right"></i>
        </div>
    </div>

    <div class='spending-breakdown p-radius p-shadow white-BG' *ngIf="screenSize <= 1100" [routerLink]="spendingLink">
        <p>Spending Breakdown</p>
        <i class="fa fa-chevron-right"></i>
        <div>
            <div>
                <p>${{ _fn.numberWithCommas(breakdownData[currentLayout][0]) }}</p>
                <p>Spent This Month</p>
            </div>
            <div>
                <p>${{ _fn.numberWithCommas(breakdownData[currentLayout][1]) }}</p>
                <p>Spent Last Month</p>
            </div>
        </div>
    </div>


    <div class="data-table p-shadow p-radius white-BG">
        <div class="wrapper">
            <div class="btn-group">
                <btn-group [btns]="btnNames" (clickEvent)="btnClick($event)"></btn-group>
            </div>
            <div class='search'>
                <search-bar [placeholder]="'Search Dept, Contact, Etc..'" (filterStatus)="_fn.transparentBG($event)">
                </search-bar>
            </div>
        </div>
        <div>
            <dept-table [abreviations]="abreviationArray" [data]="tableValue" [itemsPerPageInput]="15" [firstColumnWidth]="200" [pageValues]="_fn.gridAreaNames" [pageLink]="tableLink"></dept-table>
        </div>
    </div>
    <div id="filter-container" [ngClass]="{'show-filters': _modalBG.panelStatus[3].filters.value}">
        <dept-filters [data]="tableValue" [filterList]="tableComponent.columnNames" [pageValues]="_fn.gridAreaNames" (columnNamesEvt)="receiveNewColNames($event)" (abreviationEvt)="getNewData($event)"></dept-filters>
    </div>
</div>