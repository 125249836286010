
import { Component, OnDestroy, OnInit, Output, ViewChild, ChangeDetectorRef } from '@angular/core';
import * as rates from './rates.json'
import { WindowWidthService } from 'src/app/services/windowWidth.service';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from 'rxjs';
import { ModalBgService } from 'src/app/services/modal-bg.service';
import { DeptTableComponent } from '../../components/_reusable/dept-table/dept-table.component'
import { GlobalFnService } from 'src/app/services/global-functions.service';
import { DeptFiltersComponent } from 'src/app/components/dept-filters/dept-filters.component';

@AutoUnsubscribe()

@Component({
  selector: 'app-burn-rate-month',
  templateUrl: './burn-rate-month.component.html',
  styleUrls: ['./burn-rate-month.component.scss']
})
export class BurnRateMonthComponent implements OnInit, OnDestroy {

  protected subscription1$: Subscription;
  protected subscription2$: Subscription;

  // Get Column Names from Table
  @ViewChild(DeptTableComponent) tableComponent: any = [];
  columnNames: string;
  @Output() visibleFilters: any = this.tableComponent.columnNames;
  @Output() gridAreaNames: any;

  @ViewChild(DeptFiltersComponent) dFilters:any;

  screenSize: number;
  result: any = [];
  currentYear: any = new Date().getFullYear();
  currentBtn:number = 0;
  durations:string[] = ['Monthly', 'Quarterly', 'Yearly'];
  timeSpans:string[] = ["Month", "Quarter", "Year"];
  currentTime:string[] = ['540,000', '1,400,000', '5,400,000']
  lastTime:string[] = ['500,000', '1,200,000,', '5,000,000']
  chartsArray: any = [[60, 40, 40, 64, 22, 40, 60, 80, 55, 50, 60, 55], [90, 45, 75, 80, 40, 50, 75, 75, 40, 50, 45, 35]];
  dataIndex: number;
  tableData = rates.tableData;
  filterStatus: any;

  constructor(
    private _windowWidth: WindowWidthService,
    public _modalBG: ModalBgService,
    public _fn: GlobalFnService,
    private _cd: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.subscription1$ = this._windowWidth.currentVal.subscribe(currentVal => this.screenSize = currentVal);
    this.subscription2$ = this._windowWidth.currentVal.subscribe(currentVal => this.filterStatus = currentVal);
  }

  ngAfterViewInit() {
    this.dFilters.showAbreviations = false;
    this._fn.createTableGridArea(this.tableData[0] );
    this._fn.createFilterGridArea(this.dFilters, this.tableData[0]);
    this._cd.detectChanges()
  }

  ngOnDestroy() { }

  // Create new grid-template-area
  receiveNewColNames(e: any) {
    this.gridAreaNames = "";
    for (var i = 0; i < e.length; i++) {
      this.gridAreaNames += e[i] + " "
    }
    this.gridAreaNames = "'" + this.gridAreaNames + "'";
  }

  changeData(e) {
    this.currentBtn = e;
    this.chartsArray = this._fn.changeDD(this.chartsArray);
    this.tableData = this._fn.changeDD(this.tableData);
  }

}
