import { Component, OnDestroy, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { WindowWidthService } from 'src/app/services/windowWidth.service';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from 'rxjs';
import { ModalBgService } from 'src/app/services/modal-bg.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { CurrentRouteService } from 'src/app/services/current-route.service';
import { ToggleSwitchComponent } from '../_reusable/toggle-switch/toggle-switch.component';
import { GlobalFnService } from 'src/app/services/global-functions.service';
import * as vendorItems from './vendorItems.json';

@AutoUnsubscribe()

@Component({
  selector: 'dept-filters',
  templateUrl: './dept-filters.component.html',
  styleUrls: ['./dept-filters.component.scss']
})
export class DeptFiltersComponent implements OnInit, OnDestroy {

  vData: any = [];

  protected subscription1$: Subscription;
  protected subscription2$: Subscription;

  // contractsStatus: boolean = false;
  screenSize: number;
  panelStatus: boolean;
  abreviation: boolean = false;
  showAbreviations: boolean = true;
  filterPanel: boolean;
  filterActive: any;
  activeTab: string = 'filter';
  pageValuesArray: any = [];
  pageValuesMaster: any = [];
  contractsBtn: boolean = false;
  rowOrder: any;
  category: any;
  panelTitle: string;
  vFilter: any = [];
  dataIndex: number = 0;
  selectedFilter: number = 0;
  startVal: string;
  endVal: string;
  sliderValue: number = 500000;
  checkedBox;

  totalBudget = [
    { "element": "$0 - $500,000", "status": false },
    { "element": "$500,001 - $1,000,000", "status": false },
    { "element": "$1,000,001 - $5,000,000", "status": false },
    { "element": "More than $5,000,000", "status": false }
  ]

  subDept = [
    { "element": "0 - 10 Sub-departments", "status": false },
    { "element": "11 - 25 Sub-departments", "status": false },
    { "element": "26 - 50 Sub-departments", "status": false },
    { "element": "More than 50 Sub-departments", "status": false }
  ]

  minMax = [
    { "element": "$0 - $500,000" },
    { "element": "$500,0001 - $1,000,000" },
    { "element": "$1,000,001 - $5,000,000" },
    { "element": "$5,000,001 - $10,000,000" }
  ]

  contractsFilter: any = [
    {
      "name": "Date Range",
      "data": []
    }, {
      "name": "Contract Type",
      "data": [
        { "element": "Non-Recurring" },
        { "element": "Semi-Annual" },
        { "element": "Quarterly" }
      ]
    }, {
      "name": "Vendor",
      "data": vendorItems.vendorList
    }, {
      "name": "Exp. Min.",
      "data": this.minMax
    }, {
      "name": "Exp. Max.",
      "status": false,
      "data": this.minMax
    }
  ]

  deptFilter: any = [
    {
      "name": "Remaining",
      "data": this.totalBudget
    }, {
      "name": "Sub-Dept",
      "data": this.subDept
    }, {
      "name": "Employees",
      "data": [
        { "element": "0 - 50 Employees", "status": false },
        { "element": "51 - 100 Employees", "status": false },
        { "element": "101 - 150 Employees", "status": false }
      ]
    }
  ]

  homeFilter: any = [
    {
      "name": "Remaining",
      "data": this.totalBudget,
    }, {
      "name": "Total Budget",
      "data": this.totalBudget,
    }, {
      "name": "# of Sub-Dept",
      "data": this.subDept,
    }
  ]

  vendorsFilter: any = [
    {
      "name": "Remaining Amount",
      "data": this.totalBudget,
    }, {
      "name": "Total Budget",
      "data": this.totalBudget,
    }, {
      "name": "Date Range",
      "data": []
    }, {
      "name": "Related Contract",
      "data": vendorItems.vendorList,
    }
  ]

  // List of Filters
  @Input() filterList: any = [];
  @Input() data: any;
  @Input() orderFromTableNav: any = [];
  @Input() parent: any;
  @Input() pageValues: any;
  @Output() columnNamesEvt = new EventEmitter<any>();
  @Output() abreviationEvt = new EventEmitter<boolean>();

  @ViewChild('rowItems', { static: false }) rowItems: ElementRef;
  @ViewChildren(ToggleSwitchComponent) tSwitch: QueryList<ToggleSwitchComponent>

  tableBodyColumns: any = [];

  constructor(
    private _windowWidth: WindowWidthService,
    public _modalBG: ModalBgService,
    public _routes: CurrentRouteService,
    public _fn: GlobalFnService
  ) { }

  ngOnInit(): void {
    this.subscription1$ = this._windowWidth.currentVal.subscribe(currentVal => this.screenSize = currentVal)
    this.subscription2$ = this._modalBG.currentVal.subscribe(currentVal => this.filterActive = currentVal);
    this.category = this._routes.category.category;
    this.category == 'More' ? this.activeTab = 'config' : '';
  }

  ngOnDestroy() { }

  // Toggle Abreviation
  toggleAbreviation() {
    this.abreviation = !this.abreviation;
    this.abreviationEvt.emit(this.abreviation)
  }

  // Switch Filter/ Config Table Tab
  currentTab(tab: string) {
    this.activeTab = tab;
    if (this.filterList != undefined) {
      this.tableBodyColumns = this.filterList.slice();
    }
  }

  filterData() {
    this.filterPanel = !this.filterPanel;
    this._modalBG.closeAll()
  }

  closeOnSave(){
    this.filterPanel = this.panelStatus = false;
    this.activeTab = 'filter';
    this._modalBG.closeAll()
  }

  formatNumber(n: any) {
    var formatedNum = String(n).replace(/(.)(?=(\d{3})+$)/g, '$1,');
    n = formatedNum;
    return n
  }

  drop(event: CdkDragDrop<string[]>) {
    this.pageValuesArray = this.pageValuesMaster;

    // Reset table grid on drag/drop
    this._fn.restoreColumns();
    this.resetToggles()
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
    this.activateAllToggles();
  }

  resetToggles() {
    this.tSwitch.forEach((directive) => { directive.switchActive = true })
  }

  // Toggle Row Visibility
  toggleEvt(i: number) {
    var elem = Array.from(document.querySelectorAll(".body-columns > div")),
      targetElem = elem[i];
    targetElem.classList.toggle('displayNone');
    this.pageValuesArray = [];
    for (var j = 0; j < elem.length; j++) {
      if (!elem[j].classList.contains('displayNone')) {
        this.pageValuesArray.push(elem[j].getAttribute('data-col'));
      }
    }
    this.disableLastToggle()
    this._fn.changeColumnVisibility(this.pageValuesArray)
    this._fn.deptFilters = this.pageValuesArray;
    this.columnNamesEvt.emit(this.pageValuesArray);
  }

  // Disable toggle if one row is left in table
  disableLastToggle() {
    if (this.pageValuesArray.length <= 1) {
      this.tSwitch.forEach((directive) => {
        if (directive.switchActive) {
          directive.lastVisibleColumn = true;
        }
      })
    }
    // Enable...
    if (this.pageValuesArray.length > 1) {
      this.activateAllToggles()
    }
  }

  activateAllToggles() {
    this.tSwitch.forEach((directive) => {
      directive.lastVisibleColumn = false;
    })
  }

  closePanel() {
    this.panelStatus = false;
  }

  closeAll() {
    this.panelStatus = false;
    this.checkedBox = undefined;
  }

  startDate(e: any) {
    this.startVal = e;
  }

  endDate(e: any) {
    this.endVal = e;
  }

  // Replace 'No Filter' w/ Value
  tickBox(i: number, id: any) {
    var arr: any, elem: any = document.querySelector(id + ' [data-index="' + this.dataIndex + '"]');
    elem.innerHTML = "";

    if (id == '#vendor') arr = this.vendorsFilter;
    if (id == '#contracts') arr = this.contractsFilter;
    if (id == '#nonContracts') arr = this.deptFilter;
    if (id == '#home') arr = this.homeFilter;

    elem.innerText = arr[this.dataIndex].data[i].element;
    this.checkedBox = i;
  }

  // Panel Title, Set Active Contract Btn Panel
  contractsPanel(index: number) {
    this.dataIndex = index;
    this.panelStatus = true;
    if (this.contractsBtn) {
      this.panelTitle = this.contractsFilter[index].name;
    }

    if (!this.contractsBtn && this.category === 'Departments') {
      this.panelTitle = this.deptFilter[index].name;
    }

    if (!this.contractsBtn && this.category === 'Home') {
      this.panelTitle = this.homeFilter[index].name;
    }

    if (!this.contractsBtn && this.category === 'Vendor Contract') {
      this.panelTitle = this.vendorsFilter[index].name;
    }
  }

  selectFilter(num) {
    this.selectedFilter = num;
  }

  //Functions for interacting with slider
  sliderMove(e:any) {
    this.sliderValue = e.value;
  }
  sliderUpdate(e) {
    let index = 0;
    const num = e.value

    if (num <= 500000) index = 0;
    else if (num > 500000 && num <= 1000000) index = 1;
    else if (num > 1000000 && num <= 5000000) index = 2;
    else if (num > 5000000) index = 3;

    this.tickBox(index, '#contracts');
  }
}
