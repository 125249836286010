<div id="calendar-credit">
    <div class="navigation">
        <div (click)="monthBackward()"><i class="fa fa-chevron-left"></i></div>
        <div (click)="monthForward()"><i class="fa fa-chevron-right"></i></div> 
    </div>
    <div class="month-wrapper">
        <div class="month">
            <div class="month-yr">
                <span class="current-mo">{{ months[cVarMo] }}</span>&nbsp;
                <span class="current-yr">{{ currentYear }}</span>
            </div>
            <div class="days">
                <p *ngFor="let day of days; let i = index">{{day}}</p>
            </div>
            <div id="numbers">
                <div *ngFor="let n of divs; let i = index" #currentElems (click)="getCD($event,cVarMo)" [ngClass]="{'dotted': datesWithTransactions.includes(i)}">
                    <span class='green-dot'></span>
                </div>
            </div>
        </div>
    </div>
</div>