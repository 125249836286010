import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { WindowWidthService } from 'src/app/services/windowWidth.service';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from 'rxjs';
import { GlobalFnService } from 'src/app/services/global-functions.service';
import { DropdownMenusComponent } from 'src/app/components/_reusable/dropdown-menus/dropdown-menus.component';
import * as sidebarJson from './sidebar.json';
import * as breakdownJson from './breakdown-data.json'
import * as chartJson from './chart-data.json'

@AutoUnsubscribe()

@Component({
  selector: 'app-voucher-breakdown',
  templateUrl: './voucher-breakdown.component.html',
  styleUrls: ['./voucher-breakdown.component.scss']
})
export class VoucherBreakdownComponent implements OnInit, OnDestroy {

  protected subscription1$: Subscription

  screenSize: number;
  currentPanel: number = 0;
  chart: any;
  labelString: any[];
  chartDataIndex:number = 0
  chartData:any = chartJson.chartData;

  sidebarData:any = sidebarJson.sidebar;
  spendingBreakdownData:any = breakdownJson.data;
  panelButtons:string[] = ["The Highest", "The Lowest"]
  panelSelection:any[] = ["highest", "highest", "highest"]
  
  @ViewChild(DropdownMenusComponent) ddMenuComp: DropdownMenusComponent;

  constructor(
    private _windowWidth: WindowWidthService,
    public _fn: GlobalFnService,
    private _changeDetect:ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.subscription1$ = this._windowWidth.currentVal.subscribe(currentVal => this.screenSize = currentVal);
  }

  ngAfterViewInit() {
    this._changeDetect.detectChanges()
  }

  ngOnDestroy() { }

  panelToggle(e:number, i:number) {
    if (e === 0) this.panelSelection[i] = "highest"
    else if (e === 1) this.panelSelection[i] = "lowest"
  }

  changeDD(e) {
    this.spendingBreakdownData = this._fn.changeDD(this.spendingBreakdownData);
    this.chartDataIndex = this.chartDataIndex === 0 ? 1 : 0;
  }

}
