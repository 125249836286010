import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VendorContractDetailsRoutingModule, vendorContractDetailsRoutingComponents } from './vendor-contract-details-routing.module';
import { VendorContractDetailsComponent } from '../vendor-contract-details/vendor-contract-details.component';
import { SharedModule } from '../../modules/shared/shared.module';

@NgModule({
  declarations: [
    vendorContractDetailsRoutingComponents,
    VendorContractDetailsComponent
  ],
  imports: [
    CommonModule,
    VendorContractDetailsRoutingModule,
    SharedModule
  ]
})
export class VendorContractDetailsModule { }