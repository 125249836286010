
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MoreRoutingModule, moreRoutingComponents } from './more-routing.module';
import { SharedModule } from '../../modules/shared/shared.module';



@NgModule({
  declarations: [
    moreRoutingComponents
  ],
  imports: [
    CommonModule,
    MoreRoutingModule,
    SharedModule
  ]
})
export class MoreModule { }
