import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss']
})
export class DoughnutChartComponent implements OnInit {

  chart: any;
  chartFocus: any;

  @ViewChild('chartRef', { static: false }) chartRef: ElementRef;
  @Input() dataArray:any = [10, 20, 30, 10, 15];

  @Input() backgroundColors: any = ["rgb(113,206,88)", "rgb(3,42,85)", "rgb(177,210,71)", "rgb(78,184,230)", "rgb(14,109,207)"];
  @Input() cutOutPercent:number = 80;
  @Input() currentFocus:any;

  constructor() { }

  ngOnInit(): void {}

  ngAfterViewInit() {
    let ctx:any;
        ctx = this.chartRef.nativeElement;
    this.chart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: ["JAN", "FEB", "MAR", "APR", "MAY"],
        datasets: [{
          data: this.dataArray,
          backgroundColor: this.backgroundColors,
          fill: false,
          borderWidth: 0
        },]
      },
      options: {
        responsive: false,
        legend: {
          display: false
        },
        cutoutPercentage: this.cutOutPercent,
        tooltips: {
          enabled: false
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 5,
            bottom: 5
          }
        }
      }
    });
    if(this.chart != undefined){
      this.changeFocus(this.currentFocus)
    }
  }

  ngOnChanges(){
    if(this.chart != undefined){
      this.chart.chart.config.data.datasets[0].data = this.dataArray;
      this.chart.update()
      this.changeFocus(this.currentFocus)
    }
  }

  changeFocus(n) {
    if (n !== undefined) {
      this.chart.update();
      this.chart.getDatasetMeta(0).data[n]._model.outerRadius += 4;
      this.chart.getDatasetMeta(0).data[n]._model.innerRadius -= 4;
    }
  }



}
