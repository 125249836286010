import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import * as data from './linechart.json';
import { WindowWidthService } from 'src/app/services/windowWidth.service';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from 'rxjs';
import { LineChartComponent } from '../line-chart/line-chart.component';
import { BtnGroupComponent } from '../btn-group/btn-group.component';
import { $ } from 'protractor';

@AutoUnsubscribe()

@Component({
  selector: 'budget-graphs',
  templateUrl: './budget-graphs.component.html',
  styleUrls: ['./budget-graphs.component.scss']
})
export class BudgetGraphsComponent implements OnInit, OnDestroy {

  protected subscription1$: Subscription

  screenSize: number;
  chartIndex: number = 0;
  miniHeight:number = 45;
  fullHeight:number = 100;
  jsonData:any = data.lineChart;
  dataArray:any = this.jsonData[this.chartIndex];
  redGradient:string = 'rgb(227,153,164)';
  redBorder:string = 'rgb(207,69,89)';
  greenGradient:string = 'rgb(217,239,211)';
  greenBorder:string = 'rgb(126,210,103)';
  btnNames: any = ["1W", "1M", "3M", "6M", "9M", "1Y"];

  @ViewChild(LineChartComponent) lineChartComponent:any;
  @ViewChild(BtnGroupComponent) btnGroupComponent:any;

  currentTab = 0;
  graphScale = [
    ['14.0M', '9.1M', '4.6M'],
    ['14.0M', '9.1M', '4.6M'],
    ['14.0M', '9.1M', '4.6M'],
    ['14.0M', '9.1M', '4.6M'],
    ['14.0M', '9.1M', '4.6M'],
    ['14.0M', '9.1M', '4.6M'],
  ]

  tabInfo = [
    {
      name: 'FY22',
      info: '$14,000,000',
    },
    {
      name: 'FY21',
      info: '$15,500,000',
    },
    {
      name: 'FY20',
      info: '$13,000,000',
    },
    {
      name: 'FY19',
      info: '$13,000,000',
    },
  ]

  constructor(
    private windowWidth: WindowWidthService,
    private _changeDetect: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.subscription1$ = this.windowWidth.currentVal.subscribe(currentVal => this.screenSize = currentVal);
  }

  @HostListener('window:resize', ['$event'])

  ngAfterViewInit() {
    this._changeDetect.detectChanges()
  }

  ngOnDestroy() { }

  getTabClick($event:any){
    this.btnGroupComponent.currentActiveButton = 0;
    this.dataArray = this.jsonData[this.chartIndex];
    this.chartIndex = $event + 6;
    this.lineChartComponent.createLineGraphAxis(0)
  }

  btnClick(i: number) {
    this.chartIndex = i;
    this.dataArray = this.jsonData[this.chartIndex];
  }

}
