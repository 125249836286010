import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { WindowWidthService } from 'src/app/services/windowWidth.service';
import * as departmentJson from './department-info.json';
import * as budgetJson from './budget-info.json';
import * as contractJson from './contract-info.json';
import { GlobalFnService } from '../../services/global-functions.service';


@AutoUnsubscribe()

@Component({
  selector: 'app-comparison',
  templateUrl: './comparison.component.html',
  styleUrls: ['./comparison.component.scss']
})
export class ComparisonComponent implements OnInit, OnDestroy {
  
  subscription1$: Subscription;
  screenSize:number;
  expenditureKeys:any;
  expenditureValues:any;
  burnRateKeys:any;
  burnRateValues:any;
  barData:any;
  chartData:any;
  burnRateTotals:any;
  comparisonType:string = 'budget';
  categories:any;
  departmentInfo:any = departmentJson.departments;
  budgetInfo:any = budgetJson.data;
  contractInfo:any = contractJson.data;
  progressBarWidth:number[] = [0, 0];
  
  specialCategories = ['name', 'fullName', 'budget', 'expenditures', 'burnRate', 'size'];
  doughnutColors = ["#81D36B", "#0E83D8", "#033A68"]
  

  //Define which comparison is being shown here. Department, budget, or contract. At the moment
  //there is no user interaction for changing this in the app until comparison-report-select
  //is complete
  info:any = this.departmentInfo;

  constructor(
    private windowWidth: WindowWidthService,
    private _changeDetect:ChangeDetectorRef,
    public _fn: GlobalFnService
  ) { }

  ngOnInit(): void {
    if (history.state.comparisonType) this.changeView(history.state.comparisonType)

    this.subscription1$ = this.windowWidth.currentVal.subscribe(currentVal => this.screenSize = currentVal);

    this.categories = this.getKeys(this.info[0]).filter(key => {
      if (!this.specialCategories.includes(key)) return key
    })

    if (this.info[0]["expenditures"]) {
      this.expenditureKeys = [
        this.getKeys(this.info[0]["expenditures"].list),
        this.getKeys(this.info[1]["expenditures"].list)
      ]
      this.expenditureValues = [
        this.getValues(this.info[0]["expenditures"].list),
        this.getValues(this.info[1]["expenditures"].list)
      ]
    }

    if (this.info[0]["burnRate"]) {
      this.burnRateKeys = [
        this.getKeys(this.info[0]["burnRate"].list),
        this.getKeys(this.info[1]["burnRate"].list)
      ]
      this.burnRateValues = [
        this.getValues(this.info[0]["burnRate"].list),
        this.getValues(this.info[1]["burnRate"].list)
      ]
      this.chartData = [
        this.formatBarChartData(this.info[0]["burnRate"].list, this.burnRateKeys[0]),
        this.formatBarChartData(this.info[1]["burnRate"].list, this.burnRateKeys[1])
      ]
      this.burnRateTotals = [
        this.burnRateValues[0].reduce((a, b) => parseInt(a) + parseInt(b), 0),
        this.burnRateValues[1].reduce((a, b) => parseInt(a) + parseInt(b), 0)
      ]
    }

    if (this.info[0]["budget"]) {
      this.progressBarWidth[0] = this.calcPercent(this.info[0].budget.remaining, this.info[0].budget.total)
      this.progressBarWidth[1] = this.calcPercent(this.info[1].budget.remaining, this.info[1].budget.total)
    }
  }

  ngAfterViewInit() {
    this._changeDetect.detectChanges()
  }
  
  ngOnDestroy(){}

  formatBarChartData(data, keys) {
    let chartData = [];
    keys.forEach((key) => {
      chartData.push({
        name: key,
        data: [data[key]]
      })
    })
    return chartData
  }

  convertToCash(x) {
    return "$" + this._fn.numberWithCommas(x);
  }

  calcPercent(a, b) {
    return Math.round((a/b) * 100)
  }

  getKeys(obj) {
    return Object.keys(obj);
  }

  getValues(obj) {
    return Object.values(obj);
  }

  changeView(x) { //for placeholder information
    if (x === 'department') this.info = this.departmentInfo
    else if (x === 'budget') this.info = this.budgetInfo
    else if (x === 'contract') this.info = this.contractInfo
    this.categories = this.getKeys(this.info[0]).filter(key => {
      if (!this.specialCategories.includes(key)) return key
    })
  }

}
