import { Component, OnInit, ViewChild, ElementRef, Input, HostListener, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Chart } from 'chart.js';
import { CurrentRouteService } from 'src/app/services/current-route.service';
import { WindowWidthService } from 'src/app/services/windowWidth.service';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from 'rxjs';

@AutoUnsubscribe()

@Component({
  selector: 'multi-line-chart',
  templateUrl: './multi-line-chart.component.html',
  styleUrls: ['./multi-line-chart.component.scss']
})
export class MultiLineChartComponent implements OnInit, OnDestroy {

  protected subscription1$: Subscription;
  protected subscription2$: Subscription;

  lineData: any;
  lineChart: any;
  lineOptions: any;
  currentRoute: string;
  screenSize: number;
  isIPad: any;
  lineColor: any;
  chartWidth: number;
  jsonValues: any = [];

  // Populate X Axis w/ Current Month
  currentYear: any = new Date().getFullYear();
  currentMonth:any = new Date().getMonth()
  months: any = ['Jan', 'Feb', 'Mar', 'Apr', 'May', "June", "July", 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  selectedMonth: any = this.months[this.currentMonth];
  datasets:any = [];

  @Input() duration: any = 'Monthly';
  @Input() data: any = [
    [60, 40, 40, 64, 22, 40, 60, 80, 55, 25, 60, 30],
    [90, 45, 75, 80, 40, 50, 75, 75, 40, 45, 35, 55]
  ];
  @Input() colors: any = ["#01B3DB", "#81D36B", "#003A68"];
  @Input() dHeight:number = 250;
  @Input() mHeight:number = 200;

  @ViewChild('trendsCanvas', { static: false }) trendsCanvas: ElementRef;
  @ViewChild('chartValues', { static: false }) chartValues: ElementRef;
  @ViewChild('slider', { static: false }) slider: ElementRef;
  @ViewChild('wrapper', { static: false }) wrapper: ElementRef;

  constructor(
    private _userRoute: CurrentRouteService,
    private _windowWidth: WindowWidthService,
    private _changeDetect:ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.subscription1$ = this._userRoute.currentVal.subscribe(currentVal => this.currentRoute = currentVal)
    this.subscription2$ = this._windowWidth.currentVal.subscribe(currentVal => this.screenSize = currentVal);

    // Detect iPad to configure canvas height
    this.isIPad = /Macintosh/.test(navigator.userAgent) && 'ontouchend' in document;
    this.buildDatasets();
  }

  ngAfterViewInit() {
    this._changeDetect.detectChanges()

    this.lineData = {
      labels: this.months,
      datasets: this.datasets
    }
    this.lineOptions = {
      responsive: true,
      maintainAspectRatio: true,
      tooltips: {
        enabled: false
      },
      layout: {
        padding: { left: 5, right: 5, top: 5, bottom: 5 }
      },
      scales: {
        yAxes: [{
          display: false
        }],
        xAxes: [{
          display: false
        }]
      },
      legend: {
        display: false
      }
    }

    if (this.screenSize > 1100) this.wrapper.nativeElement.style.height = this.dHeight+'px';
    else this.wrapper.nativeElement.style.height = this.mHeight+'px';

    let ctx:any;
      ctx = this.trendsCanvas.nativeElement;
    this.lineChart = new Chart(ctx, {
      type: "line",
      data: this.lineData,
      options: this.lineOptions
    });
    this.lineChart.aspectRatio = 0;
    this.createLineGraphAxis(0);

  }

  ngOnChanges(){
    if(this.lineChart != undefined){
      this.lineChart.chart.config.data.datasets.forEach((item, i) => item.data = this.data[i])
      this.lineChart.update()
    }
  }

  ngOnDestroy(){}

  setChartSize() {
    if (this.screenSize > 1100) this.wrapper.nativeElement.style.height = this.dHeight+'px';
    else this.wrapper.nativeElement.style.height = this.mHeight+'px';
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.screenSize > 1100) this.wrapper.nativeElement.style.height = this.dHeight+'px';
    else this.wrapper.nativeElement.style.height = this.mHeight+'px';
  }

  buildDatasets() {
    this.data.forEach((dataItem, i) => {
      this.datasets.push(
        {
          label: "",
          fill: false,
          lineTension: 0,
          backgroundColor: "",
          borderColor: this.colors[i],
          borderCapStyle: 'square',
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: "",
          pointBackgroundColor: "",
          pointBorderWidth: 2,
          pointHoverRadius: 8,
          pointHoverBackgroundColor: this.colors[i],
          pointHoverBorderColor: "",
          pointHoverBorderWidth: 2,
          pointRadius: 0,
          pointHitRadius: 5,
          data: dataItem,
          spanGaps: false
        }
      )
    })
  }

  createLineGraphAxis(item: any) {
    // Line Graphs
    if (this.duration === 'Monthly') {
      if (this.screenSize < 675) {
        for (var i = 0; i < 12; i++) {
          this.chartValues.nativeElement.innerHTML += '<div class="relative" style="width:' + 8.3 + '%"><div class="dotted-line"></div>' + this.months[0 + i] + '</div>'
        }
      } else {
        for (var i = 0; i < 12; i++) {
          this.chartValues.nativeElement.innerHTML += '<div class="relative" style="width:' + 8.3 + '%"><div class="dotted-line"></div>' + (i % 2 == 0 ? this.months[0 + i] : '') + '</div>'
        }
      }
    }
  }
}
