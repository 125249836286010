<div id="main-content" data-page="contract-spending">
    <div class="pag-content">
        <div class="sidebar-icon">
            <sidebar-toggle-icon (emitValue)="_fn.sidebarToggle($event)" (click)="_fn.sidebarBG(ddMenuComp)"></sidebar-toggle-icon>
        </div>
        <div class="header-content" [ngClass]="{'info-active-active':screenSize > 1100 && screenSize < 1420}">
            <div class="page-name">
                <page-name [pageName]='"Contract Spending"'></page-name>
            </div>
            <div [ngClass]="{'mobile-background':screenSize < 1100}">
                <dropdown-menus (ddInfo)='changeDD($event)'></dropdown-menus>
            </div>
        </div>

        <div class="page-content p-padding p-radius p-shadow white-BG">
            <p class='breakdown-title' *ngIf="screenSize <= 1100">Spending Breakdown</p>
            <div class="stacked-bar">
                <stacked-bar-chart [chartData]="firstFive" [graphLabels]="['This Month', 'Last Month']" [showLabels]="true"></stacked-bar-chart>
            </div>
            <div class='spending-breakdown'>
                <p *ngIf="screenSize > 1100">Spending Breakdown</p>
                <div class='breakdown-table'>
                    <div>
                        <p>Contract</p>
                        <p>This Month</p>
                        <p>Last Month</p>
                    </div>
                    <div *ngFor="let i = index; let contract of chartData | slice:0:5">
                        <div class='color-circle' [style.backgroundColor]="chartColors[i]"></div>
                        <p>{{ contract.name }}</p>
                        <p>{{ calcPercent(this.chartData, 0, i) }}</p>
                        <p>{{ calcPercent(this.chartData, 1, i) }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class='table-title' *ngIf="screenSize < 1101">
            <div><i class="fas fa-arrow-up"></i></div>
            <div>
                <p>Top 5 Spending Vendors</p>
            </div>
        </div>
        <div id="data-table-container" class="p-radius p-shadow white-BG" *ngIf="screenSize < 1101">
            <dept-table [abreviations]="abreviationArray" [data]="tableData" [itemsPerPageInput]="10" [pageValues]="_fn.gridAreaNames"></dept-table>
        </div>
    </div>
</div>

<div id="sidebar" *ngIf="screenSize > 1100" [ngClass]="{'show-sidebar':_fn.sidebarVisible}">
    <div class="header">
        <div>
            <div><i class="fas fa-arrow-up"></i></div>
            <div>
                <p>Top 10 Contracts</p>
            </div>
            <div *ngIf="_fn.sidebarVisible && screenSize > 1100 && screenSize < 1420" class="sidebar-close" (click)="_fn.sidebarToggle()">
                <img src="../../../assets/dept-name/closeFilterPanel.svg" />
            </div>
        </div>
        <div id="view-btns" class="btn-group">
            <btn-group [btns]='btnNames' (clickEvent)="btnClick($event)"></btn-group>
        </div>
    </div>
    <div class="loop-wrapper">
        <div *ngFor="let item of chartData | slice:0:10; let i = index">
            <app-sidebar-item [vendorName]="item.name" [amount]="" [expenseName]="inDollars(item.data[0])" [span]="returnRange(item.years)"></app-sidebar-item>
        </div>
    </div>
</div>