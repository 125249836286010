import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeptFilterChildService {

  childFilterPanel:boolean = undefined;
  childTitle:string = undefined;

  private valueSource = new BehaviorSubject(this.childFilterPanel);
  private valueTitle = new BehaviorSubject(this.childTitle);

  currentVal = this.valueSource.asObservable();
  titleVal = this.valueTitle.asObservable();

  changeValue(panelStatus:boolean, titleStatus:string){
    this.valueSource.next(panelStatus);
    this.valueTitle.next(titleStatus)
  }

  constructor() { }
}
