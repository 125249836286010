import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SettingsPageComponent } from '../settings-page/settings-page.component';

const routes: Routes = [
  { path: '', component: SettingsPageComponent, children: [
      { path:'settings', component: SettingsPageComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsPageRoutingModule { }

export const settingsPageRoutingComponents = [
  SettingsPageComponent
]
