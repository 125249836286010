<div id="main-content" data-page="comparison">
    <div class="header-content">
        <div class="page-name">
            <page-name [pageName]='"Comparison Report"'></page-name>
        </div>
    </div>

    <div class="page-content dept-comparison">

        <section>
            <p></p>
            <div class="comparison-card p-shadow p-radius white-BG name-comparison">

                <div class='info department-name' *ngFor='let item of info; let i = index'>
                    <div>
                        <img src="../../../assets//global-search/departments.svg" class="icon" *ngIf="comparisonType === 'department'" />
                        <p>{{ info[i].name }}</p>
                    </div>
                    <p>{{ info[i].fullName }}</p>
                    <p [routerLink]="['/comparison-report-select']">
                        Change
                        <i class="fa fa-chevron-right"></i>
                    </p>
                </div>

            </div>
        </section>

        <section *ngIf="info[0]['budget']">
            <p>Remaining Budget</p>
            <div class="comparison-card p-shadow p-radius white-BG budget-comparison">

                <div class='info remaining-budget' *ngFor='let item of info; let i = index'>
                    <a href="/department-drilldown">{{ convertToCash(info[i].budget.remaining) }}</a>
                    <p>of {{ convertToCash(info[i].budget.total) }}</p>
                    <div class='progress-bar'>
                        <progress-bar [percent]="progressBarWidth[i]"></progress-bar>
                    </div>
                </div>

            </div>
        </section>

        <section *ngIf="info[0]['expenditures']">
            <p>Expenditure Trends</p>
            <div class="comparison-card p-shadow p-radius white-BG expenditure-comparison">

                <div class='info' *ngFor='let item of info; let i = index'>
                    <div class='top-bar'>
                        <a href="/expenditures">{{ convertToCash(info[i].expenditures.total) }} </a>
                        <div>
                            <i class="fas fa-sort-up" [class.down]="info[i].expenditures.trend === 'down'"></i>
                            <p>{{ info[i].expenditures.change }}%</p>
                            <p>vs. Last Mo.</p>
                        </div>
                    </div>
                    <div class='graph-grid'>
                        <div class='doughnut-container'>
                            <div>
                                <doughnut-chart [cutOutPercent]="80" [dataArray]="expenditureValues[i]" [backgroundColors]="doughnutColors"></doughnut-chart>
                            </div>
                        </div>
                        <div class='graph-info'>
                            <div *ngFor="let key of expenditureKeys[i]; let j = index">
                                <div class='color-circle' [style.backgroundColor]="doughnutColors[j]"></div>
                                <a href="/expenditures">{{ convertToCash(info[i].expenditures.list[key]) }}</a>
                                <p>on {{ key }}</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

        <section *ngIf="info[0]['burnRate']">
            <p>Monthly Burn Rate</p>
            <div class="comparison-card p-shadow p-radius white-BG burn-comparison">

                <div class='info' *ngFor='let item of info; let i = index'>
                    <div class='top-bar'>
                        <a href="/burn-rate-month">{{ convertToCash(info[i].burnRate.total) }} </a>
                        <div>
                            <i class="fas fa-sort-up" [class.down]="info[i].burnRate.trend === 'down'"></i>
                            <p>{{ info[i].burnRate.change }}%</p>
                            <p>vs. Last Mo.</p>
                        </div>
                    </div>
                    <div class='graph-grid bar-grid'>
                        <div class='bar-container' *ngIf="screenSize > 1100">
                            <stacked-bar-chart [chartData]="chartData[i]" [graphLabels]="['Burn Rate']"></stacked-bar-chart>
                        </div>
                        <div class="percent-bar" *ngIf="screenSize <= 1100">
                            <div *ngFor='let value of burnRateValues[i]; let j = index' [style.backgroundColor]="doughnutColors[j]" [style.width.%]="calcPercent(burnRateValues[i][j], burnRateTotals[i])"></div>
                        </div>
                        <div class='graph-info'>
                            <div *ngFor="let key of burnRateKeys[i]; let j = index">
                                <div class='color-circle' [style.backgroundColor]="doughnutColors[j]"></div>
                                <a href="/burn-rate-month">{{ convertToCash(info[i].burnRate.list[key]) }}</a>
                                <p>on {{ key }}</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

        <section *ngFor='let category of categories'>
            <p>{{ category }}</p>
            <div class="comparison-card p-shadow p-radius white-BG size-comparison">

                <div class='info' *ngFor='let item of info; let i = index'>
                    <div *ngIf="info[i][category].length > 2">
                        <p [ngClass]="{'red-text': category === 'Avg. Monthly Burn Rate'}">{{ info[i][category] }}</p>
                    </div>
                    <div class='two-liner' *ngIf="info[i][category].length <= 2">
                        <p [ngClass]="{'red-text': category === 'Avg. Monthly Burn Rate'}">{{ info[i][category][0] }}</p>
                        <p>{{ info[i][category][1] }}</p>
                    </div>
                </div>

            </div>
        </section>

        <div class='center-line'></div>

    </div>

</div>