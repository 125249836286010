import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContractSpendingRoutingModule, contractSpendingRoutingComponents } from './contract-spending-routing.module';
import { SharedModule } from '../../modules/shared/shared.module';


@NgModule({
  declarations: [
    contractSpendingRoutingComponents
  ],
  imports: [
    CommonModule,
    ContractSpendingRoutingModule,
    SharedModule
  ]
})
export class ContractSpendingModule { }
