
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpendituresRoutingModule, expendituresRoutingComponents } from './expenditures-routing.module';
import { SharedModule } from '../../modules/shared/shared.module';

@NgModule({
  declarations: [
    expendituresRoutingComponents
  ],
  imports: [
    CommonModule,
    ExpendituresRoutingModule,
    SharedModule
  ]
})
export class ExpendituresModule { }

