import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, Output, ElementRef, Input, HostListener } from '@angular/core';
import { WindowWidthService } from 'src/app/services/windowWidth.service';
import * as filteredCharts from './credit-card-transaction-data.json';
import * as data from './category-data.json';
import * as transactionData from './transaction-table-data.json';
import * as pData from './top-department-panel-data.json'
import * as departmentJson from './departments.json';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from 'rxjs';
import { ModalBgService } from 'src/app/services/modal-bg.service';
import { DeptTableComponent } from '../../components/_reusable/dept-table/dept-table.component';
import { GlobalFnService } from 'src/app/services/global-functions.service';
import { DeptFiltersComponent } from 'src/app/components/dept-filters/dept-filters.component';
import { DoughnutChartComponent } from 'src/app/components/_reusable/doughnut-chart/doughnut-chart.component';

@AutoUnsubscribe()

@Component({
  selector: 'app-credit-card-transactions',
  templateUrl: './credit-card-transactions.component.html',
  styleUrls: ['./credit-card-transactions.component.scss']
})

export class CreditCardTransactionsComponent implements OnInit, OnDestroy {

  protected subscription1$: Subscription;
  protected subscription2$: Subscription;

  // Get Column Names from Table
  @ViewChild(DeptTableComponent) tableComponent: any = [];
  @ViewChild('scrollingContent', { static: false }) scrollingContent: ElementRef;
  @ViewChild(DeptFiltersComponent) dFilters: any;

  @ViewChild(DoughnutChartComponent) doughnutChart: any;

  @Output() visibleFilters: any = this.tableComponent.columnNames;
  @Output() gridAreaNames: any;
  @Output() getModifiedColNames: any;
  @Input() colOrderFromTable: any;

  columnNames: string;
  screenSize: number;
  chartData: any = filteredCharts;
  btnNames: any = ["Overview", "Transactions List"];
  currentBtn: number = 0;
  deptBtnNames: string[] = ['All', '30 Days', '60 Days', 'After 60 Days']
  tableDataArray: any = [];
  categoryDataArray: any = [];
  chartDataArray: any = [];
  departmentDataArray: any = [];
  chartOptions: any;
  selectedDatesArray: any = [];
  currentView: string = 'agency';
  chart: any;
  filterActive: any;
  abreviationArray: any = [];
  changes: boolean = false;
  tabNames: any = [
    { name: "Active Transactions" },
    { name: "Archived Transactions" },
    { name: "Recent Payments" }
  ]
  transactionTabs: any = [
    { name: "Recent" },
    { name: "Active" },
    { name: "Archived" }
  ]

  tableData: any = transactionData.transactionData[0]
  departmentData: any = departmentJson.tableData;

  dailyExpenses: any;
  calendarDay: string;

  constructor(
    private _cd: ChangeDetectorRef,
    private _windowWidth: WindowWidthService,
    public _modalBG: ModalBgService,
    public _fn: GlobalFnService
  ) { }

  ngOnInit(): void {
    this.subscription1$ = this._windowWidth.currentVal.subscribe(currentVal => this.screenSize = currentVal);
    this.subscription2$ = this._modalBG.currentVal.subscribe(currentVal => this.filterActive = currentVal);

    for (let key in data.data) {
      if (data.data.hasOwnProperty(key)) {
        this.categoryDataArray.push(data.data[key])
      }
    }

    for (let key in this.tableData) {
      if (this.tableData.hasOwnProperty(key)) {
        this.tableDataArray.push(this.tableData[key])
      }
    }

    for (let key in pData.topDepartmentData) {
      if (pData.topDepartmentData.hasOwnProperty(key)) {
        this.departmentDataArray.push(pData.topDepartmentData[key])
      }
    }

    this.dailyExpenses = this.tableData.slice(0, 6)
  }

  ngAfterViewInit(): void {
    this.chartOptions = this.chartDataArray[0];
    this.setTableColumns()
    this._cd.detectChanges();
  }

  changeView(e: any) {
    this.currentView = e.target.id;
    this.tableData = this.currentView === 'agency' ? transactionData.transactionData[0] : this.departmentData
    this.currentBtn = 0;
    this.setTableColumns()
    this._fn.restoreColumns();
  }

  setTableColumns() {
    this._fn.createTableGridArea(this.tableData[0]);
    this._fn.createFilterGridArea(this.dFilters, this.tableData[0])
  }

  // Create new grid-template-area
  receiveNewColNames(e: any) {
    this.gridAreaNames = "";
    for (var i = 0; i < e.length; i++) {
      this.gridAreaNames += e[i] + " "
    }
    this.gridAreaNames = "'" + this.gridAreaNames + "'";
  }

  // Receive from filters, send full/abreviation to table (column 1)
  getNewData(e: any) {
    if (e) {
      for (var i = 0; i < this.tableData.length; i++) {
        this.abreviationArray.push(this.tableData[i]._abreviation)
      }
    } else {
      this.abreviationArray = []
    }
  }

  changeTableData(i) {
    this.tableData = transactionData.transactionData[i];
    this.setTableColumns();
    this._fn.restoreColumns();
    this.dFilters.resetToggles();
  }

  changeDD(e: any) {
    this.tableData = this._fn.changeDD(this.tableData);
    this._fn.genNewDoughnutData(this.doughnutChart);
    this._fn.shuffleArray(this.departmentDataArray);
  }

  ngOnDestroy() { }

  btnClick(i: number) {
    this.currentBtn = i
  }

  daySelect(day) {
    this.calendarDay = day;
    this.dailyExpenses = this.shuffle(this.dailyExpenses);
  }

  //for randomizing array on change
  shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;

    while (0 !== currentIndex) {

      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

}
