<div id="main-content" data-page="burn-rate-category">
    <div class="header-content">
        <div class="header">
            <h2 class="page-title">Booze Allen: Burn Rate by Expense Category</h2>
        </div>
        <div [ngClass]="{'mobile-background':screenSize < 1101}">
            <dropdown-menus (ddInfo)='changeDD($event)'></dropdown-menus>
        </div>
    </div>
    <div class='search'>
        <search-bar *ngIf="screenSize > 1100" [placeholder]="'Search Month, Burn Rate #, or Burn Rate %'" [backgroundColor]="'white'" (filterStatus)="_fn.transparentBG($event)"></search-bar>
        <search-bar *ngIf="screenSize <= 1100" [placeholder]="'Search Month, Burn Rate #, or Burn Rate %'" (filterStatus)="_fn.transparentBG($event)"></search-bar>
    </div>
    <div class="table-component">
        <div>
            <dept-table [abreviations]="abreviationArray" [data]="tableData" [itemsPerPageInput]="10" [firstColumnWidth]="200" [pageValues]="_fn.gridAreaNames"></dept-table>
        </div>
    </div>
    <div id="filter-container" [ngClass]="{'show-filters': _modalBG.panelStatus[3].filters.value}">
        <dept-filters [data]="tableData" [filterList]="tableComponent.columnNames" [pageValues]="_fn.gridAreaNames" (columnNamesEvt)="receiveNewColNames($event)" (abreviationEvt)="getNewData($event)"></dept-filters>
    </div>
</div>