import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Input } from '@angular/core';
import { Chart } from 'chart.js';
import { WindowWidthService } from 'src/app/services/windowWidth.service';
import * as chartValues from 'src/app/components/_reusable/area-line-chart/area-chart.json';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from 'rxjs';

@AutoUnsubscribe()

@Component({
  selector: 'line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit, OnDestroy {

  protected subscription1$: Subscription;

  lineData: any;
  lineChart: any;
  lineOptions: any;
  screenSize: number;
  isIPad: any;
  lineColor: any;
  jsonValues: any = [];
  valueWidth: number;
  months: any = ['Jan', 'Feb', 'Mar', 'Apr', 'May', "June", "July", 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  days: any = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat']

  @Input() chartData: any;
  @Input() timeSpan: any;
  @Input() values: boolean;
  @Input() height: any;
  @Input() bgGradient: string = 'rgb(137,218,236)';
  @Input() borderColor: string = "#01B1D8";

  @ViewChild('trendsCanvas', { static: false }) trendsCanvas: ElementRef;
  @ViewChild('chartValues', { static: false }) chartValues: ElementRef;

  constructor(
    private _windowWidth: WindowWidthService
  ) { }

  ngOnInit(): void {
    this.subscription1$ = this._windowWidth.currentVal.subscribe(currentVal => this.screenSize = currentVal);

    // // Detect iPad to configure canvas height
    // this.isIPad = /Macintosh/.test(navigator.userAgent) && 'ontouchend' in document;

    // Set chart width & color
    for (let key in chartValues.values) {
      if (chartValues.values.hasOwnProperty(key)) {
        this.jsonValues.push(chartValues.values[key]);
      }
    }
  }

  ngAfterViewInit() {
    this.trendsCanvas.nativeElement.style = "width:100%;height:" + this.height + "px";
    const gradient = this.trendsCanvas.nativeElement.getContext('2d').createLinearGradient(0, 0, 0, 200);
    gradient.addColorStop(0, this.bgGradient);
    gradient.addColorStop(1, 'white');

    this.lineData = {
      labels: this.days,
      datasets: [
        {
          label: "",
          data: this.chartData,
          fill: true,
          lineTension: 0.1,
          backgroundColor: gradient,
          borderColor: this.borderColor,
          borderCapStyle: 'square',
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: "",
          pointBackgroundColor: "",
          pointBorderWidth: 0,
          pointHoverRadius: 0,
          pointHoverBackgroundColor: "transparent",
          pointHoverBorderColor: "",
          pointHoverBorderWidth: 0,
          pointRadius: [],
          pointHitRadius: 0,
          spanGaps: false
        }
      ]
    }
    this.lineOptions = {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        enabled: false
      },
      animation: false,
      layout: {
        padding: { left: 0, right: 0, top: 10, bottom: 5 }
      },
      scales: {
        yAxes: [{
          display: false
        }],
        xAxes: [{
          display: false
        }]
      },
      legend: {
        display: false
      }
    }

    this.lineChart = new Chart(this.trendsCanvas.nativeElement, {
      type: "line",
      data: this.lineData,
      options: this.lineOptions
    });

    this.lineChart.aspectRatio = 0;

    this.createLineGraphAxis(0);
  }

  ngOnChanges() {
    if (this.lineChart != undefined) {
      this.createLineGraphAxis(this.timeSpan)
    }
  }

  ngDoCheck() {
    if (this.lineChart != undefined) {
      this.lineData.datasets[0].data = this.chartData;
      this.lineChart.update()
    }
  }

  ngOnDestroy() { }

  createLineGraphAxis(item: any) {
    if (this.values) {
      this.chartValues.nativeElement.innerHTML = "";
      if (this.timeSpan == 0 || this.timeSpan > 5) {
        this.chartValues.nativeElement.style = "margin: 0 20px"
        for (var i = 0; i < 7; i++) {
          this.chartValues.nativeElement.innerHTML += '<div class="relative" style="width:' + 14.3 + '%"><div class="dotted-line"></div>' + this.days[0 + i] + '</div>'
        }
      }
      if (this.timeSpan == 1) {
        this.chartValues.nativeElement.style = "margin: 0 50px"
        for (var i = 1; i < 4; i++) {
          this.chartValues.nativeElement.innerHTML += '<div class="relative" style="width:' + 33.3 + '%"><div class="dotted-line"></div>week ' + i + '</div>'
        }
      }
      if (this.timeSpan == 2) {
        this.chartValues.nativeElement.style = "margin: 0 50px"
        for (var i = 1; i < 4; i++) {
          this.chartValues.nativeElement.innerHTML += '<div class="relative" style="width:' + 33.3 + '%"><div class="dotted-line"></div>mo ' + i + '</div>'
        }
      }
      if (this.timeSpan == 3) {
        this.chartValues.nativeElement.style = "margin: 0 30px"
        for (var i = 1; i < 7; i++) {
          this.chartValues.nativeElement.innerHTML += '<div class="relative" style="width:' + 16.67 + '%"><div class="dotted-line"></div>' + this.months[0 + i] + '</div>'
        }
      }
      if (this.timeSpan == 4) {
        this.chartValues.nativeElement.style = "margin: 0 30px"
        for (var i = 1; i < 10; i++) {
          this.chartValues.nativeElement.innerHTML += '<div class="relative" style="width:' + 11.1 + '%"><div class="dotted-line"></div>' + this.months[0 + i] + '</div>'
        }
      }
      if (this.timeSpan == 5) {
        this.chartValues.nativeElement.style = "margin: 0 20px"
        for (var i = 0; i < 12; i++) {
          this.chartValues.nativeElement.innerHTML += '<div class="relative" style="width:' + 83.3 + 'px"><div class="dotted-line"></div>' + this.months[0 + i] + '</div>'
        }
      }
    }
  }
}
