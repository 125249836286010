import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { WindowWidthService } from 'src/app/services/windowWidth.service';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from 'rxjs';
import { GlobalFnService } from 'src/app/services/global-functions.service';
import * as filteredCharts from './filteredCharts.json';

@AutoUnsubscribe()

@Component({
  selector: 'app-sub-dept-analysis',
  templateUrl: './sub-dept-analysis.component.html',
  styleUrls: ['./sub-dept-analysis.component.scss']
})
export class SubDeptAnalysisComponent implements OnInit, OnDestroy {

  months: any = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", 'Aug', "Sept", "Oct", "Nov", "Dec"];

  screenSize: number;
  width: number = window.innerWidth;
  progression: number = 12230593;
  mobileWidth: number = 1100;
  
  lineSubDeptChart: any;
  lineSubDeptData: any;
  lineOptions: any;
  
  favorited: boolean = false;
  isMobile: boolean = false;
  yearly: boolean = true;
  yearSelected: boolean;
  dataIndex:number;
  
  colors:string[] = ["#01B3DB", "#81D36B", "#003A68"];

  departmentData:any = filteredCharts.departments
  superlatives:any = filteredCharts.superlatives
  dataArray:any = [];

  protected subscription1$: Subscription;
  protected subscription2$: Subscription;

  constructor(
    private _windowWidth: WindowWidthService,
    private _changeDetect: ChangeDetectorRef,
    public _fn: GlobalFnService
  ) { }

  ngOnInit(): void {
    this.subscription2$ = this._windowWidth.currentVal.subscribe(currentVal => this.screenSize = currentVal);

    this.departmentData.forEach(item => {
      this.dataArray.push(item.data)
    })
  }

  ngAfterViewInit() {
    this._changeDetect.detectChanges();
  }

  ngOnDestroy(){}

  // Format number...fix to allow cents
  formatNumber(n: any) {
    var formatedNum = String(n).replace(/(.)(?=(\d{3})+$)/g, '$1,');
    n = formatedNum;
    return n
  }

  // Resize event
  generalInfoClick() {
      // console.log("Ive been Clicked - Genaral Info Icon / Sub-Dept-Analysis : Bottom - Right Card");
  }

  toggleFavorite() {
    this.favorited = !this.favorited
  }

  changeDD(e) {
    this.dataArray = this._fn.changeDD(this.dataArray)
    this.departmentData = this._fn.changeDD(this.departmentData)
    this.superlatives = this._fn.changeDD(this.superlatives)
    this.progression += 12121212
  }

}