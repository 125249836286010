import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { WindowWidthService } from '../../services/windowWidth.service';
import * as data from './data.json';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from 'rxjs';

@AutoUnsubscribe()

@Component({
  selector: 'app-vendor-po-invoice-list',
  templateUrl: './vendor-po-invoice-list.component.html',
  styleUrls: ['./vendor-po-invoice-list.component.scss']
})
export class VendorPOInvoiceListComponent implements OnInit, OnDestroy {

  protected subscription1$: Subscription;

  screenSize: number;
  result: any = [];
  itemNum: number = 0;
  selectedTab: string = 'overview';
  dataIndex:number;
  viewSelection: string='Overview';

  @ViewChild('completed') completedElement: ElementRef;

  constructor(
    private _wService: WindowWidthService
  ) { }

  ngOnInit(): void {
    this.subscription1$ = this._wService.currentVal.subscribe(currentVal => this.screenSize = currentVal);
    this.screenSize = this._wService.someWidth;

    for (let key in data.stream[this.dataIndex]) {
      if (data.stream[this.dataIndex].hasOwnProperty(key)) {
        this.result.push(data.stream[this.dataIndex][key])
      }
    }
  }

  ngOnDestroy(){}

  viewChange(e:any ){
    this.viewSelection = e.target.buttonId;
  }

  showResult(i: number) {
    this.itemNum = i;
  }

}