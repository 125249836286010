import { Component, OnInit,OnDestroy, ChangeDetectorRef, Input } from '@angular/core';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";

@AutoUnsubscribe()

@Component({
  selector: 'progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit, OnDestroy {

  @Input() percent = 50;

  constructor(
    private _changeDetect:ChangeDetectorRef
  ) { }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this._changeDetect.detectChanges();
  }

  ngAfterViewChecked() {}

  ngOnDestroy(){}
}
