<div id="filter-template">
    <header>
        <div class="chevron relative" (click)="closePanel()">
            <i class="fa fa-chevron-left vhCenter"></i>
        </div>
        <div class="title">
            <p>{{ filterTitle }}</p>
        </div>
        <div class="close cursorPointer" (click)="closePanel()">
            <img src="../../../assets/dept-name/closeFilterPanel.svg" />
        </div>
    </header>
    <div class="content">
        <div class="selection-group">
            <div class="faux-checkbox"></div>
            <div class="amount">$0 - $500,000</div>
        </div>
        <div class="selection-group">
            <div class="faux-checkbox"></div>
            <div class="amount">$0 - $500,000</div>
        </div>
        <div class="selection-group">
            <div class="faux-checkbox"></div>
            <div class="amount">$0 - $500,000</div>
        </div>
        <div class="selection-group">
            <div class="faux-checkbox"></div>
            <div class="amount">$0 - $500,000</div>
        </div>
    </div>
    <footer>
        <div class="btn-group">
            <div class="save">
                <button>Save</button>
            </div>
            <div class="clear">
                <button>Clear</button>
            </div>
        </div>
    </footer>
</div>