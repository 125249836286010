import { Component, OnDestroy } from '@angular/core';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from 'rxjs';
import { ModalBgService } from 'src/app/services/modal-bg.service';

@AutoUnsubscribe()

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnDestroy {

  protected subscription1$: Subscription

  notificationsPanel:boolean;
  userPanelStatus:boolean;
  currentRoute: string;
  filterStatus:any;

  constructor(
    private _modalBG:ModalBgService
  ) {}

  ngOnDestroy(){}

  closePanels(){
    this.filterStatus = false;
    this._modalBG.closeAll()
  }

}
