import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BurnRateMonthComponent } from '../burn-rate-month/burn-rate-month.component';

const routes: Routes = [
  { path: '', component: BurnRateMonthComponent, children: [
      { path:'burn-rate-month', component: BurnRateMonthComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BurnRateMonthRoutingModule { }

export const burnRateMonthRoutingComponents = [
  BurnRateMonthComponent
]
