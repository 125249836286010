import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as invoiceData from './invoices.json';
import * as chartJson from './chart-data.json';
import * as vendorJson from './vendor-data.json';
import { WindowWidthService } from 'src/app/services/windowWidth.service';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from 'rxjs';
import { GlobalFnService } from 'src/app/services/global-functions.service';
import { DropdownMenusComponent } from 'src/app/components/_reusable/dropdown-menus/dropdown-menus.component';

@AutoUnsubscribe()

@Component({
  selector: 'app-contract-spending',
  templateUrl: './contract-spending.component.html',
  styleUrls: ['./contract-spending.component.scss']
})
export class ContractSpendingComponent implements OnInit, OnDestroy {

  protected subscription1$: Subscription;

  p: number = 1;
  itemsPerPage = 8;
  result: any = [];
  screenSize: number;
  tableResult: any = [];
  sidebarResult: any = [];
  invoiceData: any = invoiceData;
  tableData = vendorJson.tableData;
  dataIndex: number;
  chart: any;
  chartSelection: number = 3;
  chartData = chartJson.chartData[this.chartSelection]
  firstFive = this.chartData.slice(0, 5);
  chartColors = chartJson.chartColors
  btnNames: any = ["All", "Active", "Expired"];
  tableIndex: number = 0;
  gridAreaNames = "'col1 col2 col3 col4 col5 col6'";

  @ViewChild(DropdownMenusComponent) ddMenuComp: DropdownMenusComponent

  constructor(
    private _windowWidth: WindowWidthService,
    private _changeDetect: ChangeDetectorRef,
    public _fn: GlobalFnService
  ) { }

  ngOnInit(): void {
    this.subscription1$ = this._windowWidth.currentVal.subscribe(currentVal => this.screenSize = currentVal)
  }

  ngAfterViewInit() {
    this._fn.createTableGridArea(this.tableData[0] );
    this._changeDetect.detectChanges()
  }

  ngOnDestroy() { }

  calcTotal(array, index) {
    let total = 0;
    array.forEach(contract => {
      total += contract.data[index]
    })
    return total
  }

  calcPercent(array, arrayNumber, index) {
    const total = this.calcTotal(array, arrayNumber)
    return Math.round((array[index].data[arrayNumber] / total) * 100) + '%'
  }

  inDollars(string) {
    return "$" + this._fn.numberWithCommas(string) + ".00"
  }

  returnRange(array) {
    return array[0] + " - " + array[1]
  }

  btnClick(i: number) {
    this.tableIndex = i;
    this.chartData.push(this.chartData.shift())
  }

  changeDD(e) {
    this.tableData = this._fn.changeDD(this.tableData)
    this.chartData = this._fn.changeDD(this.chartData)
    this.firstFive = this.chartData.slice(0, 5);
  }

}
