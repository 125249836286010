import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreditCardTransactionsComponent } from '../credit-card-transactions/credit-card-transactions.component';

const routes: Routes = [
  { path: '', component: CreditCardTransactionsComponent, children: [
      { path:'credit-card-transactions', component: CreditCardTransactionsComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CreditCardTransactionsRoutingModule { }

export const creditCardTransactionsRoutingComponents = [
  CreditCardTransactionsComponent
]
