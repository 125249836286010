import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeptDrilldownRoutingModule, deptDrilldownRoutingComponents} from './dept-drilldown-routing.module';
import { SharedModule } from '../../modules/shared/shared.module';
import { ContactCardComponent } from '../../components/contact-card/contact-card.component';

@NgModule({
  declarations: [
    deptDrilldownRoutingComponents,
    ContactCardComponent
  ],
  imports: [
    CommonModule,
    DeptDrilldownRoutingModule,
    SharedModule

  ]
})
export class DeptDrilldownModule { }
