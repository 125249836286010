import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'btn-group',
  templateUrl: './btn-group.component.html',
  styleUrls: ['./btn-group.component.scss']
})
export class BtnGroupComponent implements OnInit {
  @Input() btns: any;
  @Output() clickEvent = new EventEmitter<any>();
  
  @ViewChild('btnGroup', { static: false }) btnGroup: ElementRef;

  currentView:any;
  currentActiveButton:number = 0;

  constructor() { }

  ngOnInit(): void {
    if (!this.btns) this.btns = ["Placeholder", "Buttons"];
  }

  ngAfterViewInit(): void {
    this.btnGroup.nativeElement.removeAttribute("style");

    // Divide # of indices to create grid column
    this.btnGroup.nativeElement.style = "grid-template-columns:repeat(" + this.btns.length + "," + 100 / this.btns.length + "%";
  }

  // Button Click Event
  btnClickEvt(i:number) {
    this.currentActiveButton = i;
    this.clickEvent.emit(this.currentActiveButton);
  }


  

}
