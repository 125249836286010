
    <div #menu class="dd-menu">
        <div class="dropDown variable-btn" (click)="toggleMenu(0)">
            <p class="user-selection">{{ currentSelection }}</p>
            <div class="divider"></div>
            <div class="icon-wrapper">
                <i class="fa fa-chevron-down" aria-hidden="true" [ngClass]="{'rotateIcon':ddMenuData[0].menu}"></i>
            </div>
        </div>

        <div class="item-wrapper">
            <ul class="menu-options">
                <li (click)="selectItem(i)" *ngFor="let option of options; let i = index">
                    {{ option }}</li>
            </ul>
        </div>

    </div>