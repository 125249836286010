import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VoucherBreakdownComponent } from '../voucher-breakdown/voucher-breakdown.component';

const routes: Routes = [
  { path: '', component: VoucherBreakdownComponent, children: [
      { path:'voucher-breakdown', component: VoucherBreakdownComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VoucherBreakdownRoutingModule { }

export const voucherBreakdownRoutingComponents = [
  VoucherBreakdownComponent
]
