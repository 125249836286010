import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { CurrentRouteService } from 'src/app/services/current-route.service';
import { WindowWidthService } from 'src/app/services/windowWidth.service';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from 'rxjs';

@AutoUnsubscribe()

@Component({
  selector: 'graph-table',
  templateUrl: './graph-table.component.html',
  styleUrls: ['./graph-table.component.scss']
})
export class GraphTableComponent implements OnInit, OnDestroy {

  protected subscription1$: Subscription;
  protected subscription2$: Subscription;

  @Input() chartAmount:any;
  @Input() chartData:any;
  @Input() chartCategories:any;
  @Input() chartColor:any;

  currentPage: string;
  screenSize:number;

  constructor(
    private _currentRoute:CurrentRouteService,
    private _windowWidth: WindowWidthService
  ) { }

  ngOnInit(): void {
    this.subscription1$ = this._currentRoute.currentVal.subscribe(currentVal => this.currentPage = currentVal);
    this.subscription2$ = this._windowWidth.currentVal.subscribe(currentVal => this.screenSize = currentVal);
  }

  ngOnDestroy(){}

}
