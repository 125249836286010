import { Component, ElementRef, OnInit, Output, QueryList, ViewChild, ViewChildren, EventEmitter } from '@angular/core';

@Component({
  selector: 'calendar-credit',
  templateUrl: './calendar-credit.component.html',
  styleUrls: ['./calendar-credit.component.scss']
})
export class CalendarCreditComponent implements OnInit {

  days: any = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  calDate: any = new Date();
  month: any = this.calDate.getMonth();
  months: any = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  variableYear: any = this.calDate.getFullYear();
  currentYear: any;
  nextYear: any;
  currentMonth: any = this.calDate.getMonth();
  cVarMo: any = this.months[this.month];
  nVarMo: any = this.months[this.month];
  currentDay: any = this.calDate.getDate();
  endDay: string;
  divs: any = [];
  currentCount: number;
  nextCount: number;
  day: any;
  currentFirstDay: number;
  nextFirstDay: number;
  today: any;

  startDate: string;
  endDate: string;
  startDateChosen: boolean;

  datesWithTransactions:number[] = [0];

  @Output() emitStartDate = new EventEmitter<any>()

  @ViewChildren("currentElems") currentElems: QueryList<any>
  @ViewChildren("nextElems") nextElems: QueryList<any>

  constructor() { }

  ngOnInit(): void {
    this.createMonth(this.month + 1, this.month + 1, this.variableYear);
    this.startDate = this.currentMonth + 1 + "/" + this.currentDay + "/" + this.currentYear;
    this.startDate = (this.cVarMo + 1) + "/" + this.currentDay + "/" + this.currentYear;
    this.emitStartDate.emit(this.convertDate(this.startDate))
    this.randomizeDots();
  }

  ngAfterViewInit() {
    this.loadCalendar();
  }

  createMonth(cMo: any, nMo: any, year: any) {
    let cD = new Date(year, cMo, 0),
      nD = new Date(year, nMo + 1, 0),
      i: number = 0;
    this.divs = [];
    this.cVarMo = cD.getMonth();
    this.nVarMo > this.months.length - 1 ? this.nVarMo = this.months[0] : this.nVarMo = nD.getMonth();
    this.currentFirstDay = this.nextFirstDay = null;
    this.currentFirstDay = new Date(year, cMo - 1, 1).getDay();
    this.nextFirstDay = new Date(year, nMo, 1).getDay();
    this.currentCount = cD.getDate();
    this.nextCount = nD.getDate();
    this.day = this.currentCount;
    this.currentYear = cD.getFullYear();
    this.nextYear = nD.getFullYear();

    for (; i < 42; i++) {
      this.divs.push(this.currentCount[i])
    }
  }

  loadCalendar() {
    var currentDays = 1,
      nextDays = 1,
      dateStr: undefined;
    var elems = Array.from(document.querySelectorAll('[data-num]'));
    elems.map((item) => item.removeAttribute('data-num'))

    this.currentElems.forEach((currentElems) => currentElems.nativeElement.innerHTML = "");

    this.currentElems.forEach((currentElems, i) => {
      if (i >= this.currentFirstDay && i < this.currentCount + this.currentFirstDay) {
        currentElems.nativeElement.innerHTML = currentDays;
        currentElems.nativeElement.setAttribute('data-num', this.cVarMo + 1 + "/" + currentElems.nativeElement.innerHTML + "/" + this.currentYear)
        currentDays++
      }
    })
    this.nextElems.forEach((nextElems) => nextElems.nativeElement.innerHTML = "")
    this.nextElems.forEach((nextElems, i) => {
      if (i >= this.nextFirstDay && i < this.nextCount + this.nextFirstDay) {
        nextElems.nativeElement.innerHTML = nextDays;

        nextElems.nativeElement.setAttribute('data-num', this.nVarMo + 1 + "/" + nextElems.nativeElement.innerHTML + "/" + this.nextYear)
        nextDays++
      }
    })
  }

  monthBackward() {
    if (this.month > 0) {
      this.month--;
    } else {
      this.month = this.months.length - 1;
      this.currentYear--;
    }
    this.createMonth(this.month + 1, this.month + 1, this.currentYear);
    this.calNavigation()
    this.randomizeDots();
  }
  monthForward() {
    this.month > this.months.length - 2 ? this.month = 0 : this.month++;
    this.createMonth(this.month + 1, this.month + 1, this.nextYear);
    this.calNavigation()
    this.randomizeDots();
  }

  calNavigation() {
    this.renderCal();
    this.setCurrentDate();
    if (document.querySelector('.current-date') === null && document.querySelector('.end-date')) {
      var eDates = Array.from(document.querySelectorAll('#numbers > div')),
        eDateArr = [],
        index: number;
      for (var i = 0; i < eDates.length; i++) {
        if (eDates[i].classList.contains('end-date')) {
          index = i;
        }
      }
      eDateArr = eDates.slice(0, index);
      for (var i = 0; i < eDateArr.length; i++) {
        eDateArr[i].classList.add('date-span');
      }
    }
  }

  setCurrentDate() {
    document.querySelector('.current-date') != null ? document.querySelector('.current-date').removeAttribute('class') : '';
    document.querySelector('.end-date') != null ? document.querySelector('.end-date').removeAttribute('class') : '';
    var dataAttr = Array.from(document.querySelectorAll("[data-num]"));
    for (var i = 0; i < dataAttr.length; i++) {
      if (this.startDate === dataAttr[i].getAttribute('data-num')) {
        dataAttr[i].classList.add('current-date');
      }
    }
  }

  renderCal() {
    this.loadCalendar();
  }

  getCD(e: any, month: number) {
    if (e != null) {
      document.querySelector('.current-date') != null ? document.querySelector('.current-date').removeAttribute('class') : ''
      e.target.classList.add("current-date");
      this.startDate = e.target.dataset.num;
      this.emitStartDate.emit(this.convertDate(this.startDate))
    }
  }

  convertDate(date) {
    const dateArray = date.split('/');
    const month = this.months[dateArray[0] - 1]
    return `${month} ${dateArray[1]}`
  }

  randomizeDots() {
    const min = this.currentFirstDay;
    const max = this.currentCount + this.currentFirstDay - 1;
    this.datesWithTransactions = [
      this.randomIntFromInterval(min, max),
      this.randomIntFromInterval(min, max),
      this.randomIntFromInterval(min, max),
      this.randomIntFromInterval(min, max),
      this.randomIntFromInterval(min, max)
    ]
  }
  
  randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
}
