import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DeptDrilldownComponent } from '../dept-drilldown/dept-drilldown.component';

const routes: Routes = [
  { path: '', component: DeptDrilldownComponent, children: [
      { path:'department-drilldown', component: DeptDrilldownComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DeptDrilldownRoutingModule { }

export const deptDrilldownRoutingComponents = [
  DeptDrilldownComponent
]

