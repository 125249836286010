<div id="main-content" data-page="vendor-contract-details">
    <div class="page-content">
        <div class="content-grid">
            <h2>AEP</h2>
            <div class="relative">
                <div class='btn-group' [ngClass]="{'info-icon-active': screenSize > 1100 && screenSize < 1420}">
                    <button type="button" class="conseqta-btn conseqta-btn-reverse" *ngIf="screenSize > 1100" [routerLink]="['/credit-card-transactions']">Task Orders</button>
                    <button type="button" class="conseqta-btn" [ngClass]="{'conseqta-btn-reverse': screenSize > 1100}" [routerLink]="['/comparison-report-select']">Compare</button>
                    <button type="button" class="conseqta-btn" *ngIf="screenSize > 1100" [routerLink]="['/global-search-contact']">Contact</button>
                    <div>
                        <sidebar-toggle-icon (emitValue)="_fn.sidebarToggle($event)" (click)="_fn.sidebarBG(ddMenuComp)"></sidebar-toggle-icon>
                    </div>
                </div>
            </div>
            <div class="contract-num">Contract #34023904</div>
            <div class="status">
                <div></div>
                <div>
                    <div></div>
                    <p>Active</p>
                </div>
            </div>
            <div class='btn-wrapper'>
                <btn-group [btns]="btnNames" (clickEvent)="viewChange($event)"></btn-group>
            </div>
        </div>
        <div class='overview-layout' *ngIf="viewSelection === 'Overview'">
            <div class="contract-details">
                <div class="remaining-contract-amnt p-padding p-radius p-shadow white-BG">
                    <div>
                        <div class="dot"></div>
                        <div>Remaining Contract Amount</div>
                    </div>
                    <div class="dollar-amnt">
                        <span>$</span><span>4,230,593 </span><span> / </span><span> $5,000,000</span>
                    </div>
                    <div class='progress-bar'>
                        <progress-bar [percent]="progressBarWidth"></progress-bar>
                    </div>
                    <div class='mobile-budget-bar' *ngIf="screenSize <= 1100">
                        <span class="percent-color">{{ doughnutData[1] }}%</span>
                        <span class="ml-5"> of your </span>
                        <a class='obligations-link' href="/obligations">Obligations Budget</a>
                        <span> is going to this vendor.</span>
                        <div class="info" [routerLink]="['/contract-obligations-breakdown']">&#9432; Learn More</div>
                    </div>
                </div>
                <div class="obligations-budget" *ngIf="screenSize > 1100">
                    <div class="graph">
                        <div>
                            <doughnut-chart [cutOutPercent]="70" [backgroundColors]="bgColors" [dataArray]="doughnutData"></doughnut-chart>
                        </div>
                    </div>
                    <div class="relative">
                        <div class="vCenter">
                            <span class="percent-color">{{ doughnutData[1] }}%</span>
                            <span>of your</span>
                            <a class='obligations-link' href="/obligations">Obligations Budget</a><br>
                            <span>is going to this vendor.</span>
                            <div *ngIf="screenSize > 675">
                                <div class="info" [routerLink]="['/contract-obligations-breakdown']">&#9432; Learn More</div>
                            </div>
                            <div *ngIf="screenSize < 676">
                                <div class="info">&#9432;</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="screenSize <= 1100">
                <burn-rate-sidebar></burn-rate-sidebar>
            </div>

            <div class="expiration-award-vendor">
                <div>
                    <p class="table-header-item" id="expiration-date-text">Expiration Date</p>
                    <p id="expiration-date">06/20/2020</p>
                </div>
                <div>
                    <p class="table-header-item" id="award-date-text">Award Date</p>
                    <p id="award-date">04/12/2019</p>
                </div>
                <div>
                    <address>
                        <span class="table-header-item">Vendor Name</span><br>
                        <span id="vendor-name-address">Vendor XJF</span><br>
                    </address>
                    <address>
                        <span class="table-header-item">Customer Name</span><br>
                        <span id="customer-name-address">Agency FSD</span><br>
                    </address>
                </div>
                <div>
                    <address>
                        <span class="table-header-item">Customer Address</span><br>
                        <span id="top-vendor-address2">1234 Muffin Lane</span><br>
                        <span id=zip-span>Ashburn, VA 20146</span>
                    </address>
                </div>
                <div class="terms">
                    <div *ngIf="(screenSize > 675)">
                        <p id="term-cond">
                            Terms and Conditions
                        </p>
                    </div>
                    <div *ngIf="(screenSize < 676 )">
                        <p id="term-cond">
                            Terms & Conditions
                        </p>
                    </div>
                    <div>
                        <button type="button" class="conseqta-btn ">Open PDF</button>
                    </div>
                    <p id="text-body">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                        in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        <br><br>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem
                        ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
                        aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p>
                </div>
            </div>
        </div>

        <div class='po-layout' *ngIf="viewSelection != 'Overview'">
            <div class="spending-breakdown" [routerLink]="['/contract-spending']">
                <div id="POs" class="white-BG p-padding" *ngIf="viewSelection == 'POs'">
                    <p>Spending Breakdown</p>
                    <div class='doughnut-grid'>
                        <div class="relative">
                            <div class="d-component">
                                <doughnut-chart [cutOutPercent]="70" [backgroundColors]="poColors" [dataArray]="doughnutData"></doughnut-chart>
                            </div>
                        </div>
                        <div class='doughnut-legend'>
                            <div class="titles">
                                <p>Total</p>
                                <p>$4,000,000</p>
                            </div>
                            <div *ngFor="let items of doughnutData">
                                <span class="grid-dots" [ngStyle]="{'background-color': items.color}"></span>
                                <p>PO Number</p>
                                <p>{{ items }}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="white-BG p-padding" *ngIf="viewSelection == 'Vendors'">
                    <i class="fa fa-chevron-right" [routerLink]="['/vendor-overview']"></i>
                    <p>Spending Breakdown</p>
                    <div class='doughnut-grid'>
                        <div class="relative">
                            <div class="d-component">
                                <doughnut-chart [cutOutPercent]="70"></doughnut-chart>
                            </div>
                        </div>
                        <div class='doughnut-legend'>
                            <div *ngFor="let items of vendorsArray">
                                <span class="grid-dots" [ngStyle]="{'background-color': items.color}"></span>
                                <p>{{ items.vendor }}</p>
                                <p *ngIf="screenSize > 675">{{ items.percent }}</p>
                                <p>{{ items.amount }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="dept-layout" [ngClass]="{'hidden':viewSelection != 'Depts.'}">
            <div class='dept-graph-table p-radius p-shadow white-BG'>
                <div class='chevron-box' [routerLink]="['/department-drilldown']">
                    <i class="fa fa-chevron-right"></i>
                </div>
                <p class='dept-title'><span>6</span> Departments</p>
                <div class="line-chart">
                    <multi-line-chart [colors]="chartColors" [data]="lineGraphData" [dHeight]="150" [mHeight]="150"></multi-line-chart>
                </div>
            </div>
        </div>

        <div class="table-component" [ngClass]="{'hidden': viewSelection === 'Overview'}">
            <div class='search'>
                <search-bar (filterStatus)="_fn.transparentBG($event)"></search-bar>
            </div>
            <div class='table-wrapper'>
                <dept-table [data]="tableData[currentTab]" [abreviations]="abreviationArray" [itemsPerPageInput]="10" [firstColumnWidth]="200" [pageValues]="_fn.gridAreaNames" [pageLink]="pageLinks[currentTab]"></dept-table>
            </div>
        </div>

        <div id="filter-container" [ngClass]="{'show-filters': _modalBG.panelStatus[3].filters.value}">
            <dept-filters [data]="tableData[currentTab]" *ngIf="tableComponent != undefined" [filterList]="tableComponent.columnNames" [pageValues]="_fn.gridAreaNames" (columnNamesEvt)="receiveNewColNames($event)" (abreviationEvt)="getNewData($event)"></dept-filters>
        </div>
    </div>

    <div id="sidebar" *ngIf="screenSize > 1100" [ngClass]="{'show-sidebar':_fn.sidebarVisible}">
        <div *ngIf="screenSize < 1420 && _fn.sidebarVisible" class="sidebar-close" (click)="_fn.sidebarToggle()">
            <img src="../../../assets/dept-name/closeFilterPanel.svg" />
        </div>
        <burn-rate-sidebar></burn-rate-sidebar>
    </div>
</div>