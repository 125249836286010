import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { WindowWidthService } from '../../services/windowWidth.service';
import * as vendors from './vendors.json';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from 'rxjs';
import * as poJson from './po-data.json'
import { ModalBgService } from 'src/app/services/modal-bg.service';
import { DeptTableComponent } from 'src/app/components/_reusable/dept-table/dept-table.component';
import { Output } from '@angular/core';
import * as lineGraphData from './deptData.json';
import { GlobalFnService } from 'src/app/services/global-functions.service';
import { DropdownMenusComponent } from 'src/app/components/_reusable/dropdown-menus/dropdown-menus.component';
import { DeptFiltersComponent } from 'src/app/components/dept-filters/dept-filters.component';

@AutoUnsubscribe()

@Component({
  selector: 'app-vendor-contract-details',
  templateUrl: './vendor-contract-details.component.html',
  styleUrls: ['./vendor-contract-details.component.scss']
})
export class VendorContractDetailsComponent implements OnInit, OnDestroy {

  protected subscription1$: Subscription

  total: number = 14200000;
  progression: number = 12230593;
  progressBarWidth: any;
  screenSize: number;
  viewSelection: string = 'Overview';
  tableData: any = poJson.tableData;
  vendorsArray: any = [];
  abreviationArray: any = [];
  btnNames: any = ['Overview', 'POs', 'Vendors', 'Depts.'];
  lineGraphData: any = lineGraphData.deptData;

  bgColors: any = ["rgba(204, 227, 236, 1)", "rgba(0, 117, 160, 1)"];
  poColors: any = ["rgb(113,206,88)", "rgb(3,42,85)"];
  chartColors: string[] = ["#01B3DB", "#81D36B", "#003A68", "#02D47F", "#C4C742", "#0075A0"];
  doughnutData: any = [40, 60];

  pageLinks: string[] = ['', 'vendors-po-drilldown-invoices', 'vendors-drilldown-overview', 'department-drilldown']
  currentTab: number = 0;

  @ViewChild(DeptTableComponent) tableComponent: any = [];
  @ViewChild(DropdownMenusComponent) ddMenuComp: DropdownMenusComponent;
  @ViewChild(DeptFiltersComponent) dFilters: any;

  columnNames: string;
  @Output() visibleFilters: any = this.tableComponent.columnNames;
  @Output() gridAreaNames: any;
  filterStatus: any;

  constructor(
    private _wService: WindowWidthService,
    public _modalBG: ModalBgService,
    private _cd: ChangeDetectorRef,
    public _fn: GlobalFnService
  ) { }

  ngOnInit(): void {
    this.subscription1$ = this._wService.currentVal.subscribe(currentVal => this.screenSize = currentVal);
    this.screenSize = this._wService.someWidth;

    for (let key in vendors.vendors) {
      if (vendors.vendors.hasOwnProperty(key)) {
        this.vendorsArray.push(vendors.vendors[key])
      }
    }
  }

  ngAfterViewInit(): void {
    // Calculate progress bar width
    this.progressBarWidth = Math.round((this.progression / this.total) * 100);
    this.setTableColumns()
    this._cd.detectChanges()
  }

  ngOnDestroy() { }

  // Create new grid-template-area
  receiveNewColNames(e: any) {
    this.gridAreaNames = "";
    for (var i = 0; i < e.length; i++) {
      this.gridAreaNames += e[i] + " "
    }
    this.gridAreaNames = "'" + this.gridAreaNames + "'";
  }

  // Receive from filters, send full/abreviation to table (column 1)
  getNewData(e: any) {
    if (e) {
      this.abreviationArray = [];
      for (var i = 0; i < this.tableData[this.currentTab].length; i++) {
        this.abreviationArray.push(this.tableData[this.currentTab][i]._abreviation);
      }
    } else {
      this.abreviationArray = []
    }
  }

  viewChange(i: any) {
    this.currentTab = i;
    this.viewSelection = this.btnNames[i];
    if (i == 1) {
      this.dFilters.showAbreviations = false;
      this.getNewData(false)
    }
    if (i == 2 || i == 3) {
      this.dFilters.showAbreviations = true;
      this.getNewData(true);
    }
    this.tableComponent.data = this.tableData[i];
    this.setTableColumns()
    this.dFilters.resetToggles();
  }

  setTableColumns() {
    this._fn.createTableGridArea(this.tableData[this.currentTab][0]);
    this._fn.createFilterGridArea(this.dFilters, this.tableData[this.currentTab][0]);
  }

}