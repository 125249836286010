<div class='graph-wrapper'>
    <canvas id="bar" #chartRef></canvas>

    <div class='graph-labels' *ngIf="showLabels">
        <div [style.top.px]="graphHeights[i]" *ngFor="let graph of graphTotals; let i = index">
            <p>{{ graphLabels[i] }}</p>
            <p>${{ _fn.numberWithCommas(graphTotals[i]) }}</p>
        </div>
    </div>

</div>