import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VoucherBreakdownRoutingModule, voucherBreakdownRoutingComponents } from './voucher-breakdown-routing.module';
import { SharedModule } from '../../modules/shared/shared.module';


@NgModule({
  declarations: [
    voucherBreakdownRoutingComponents
  ],
  imports: [
    CommonModule,
    VoucherBreakdownRoutingModule,
    SharedModule
  ]
})
export class VoucherBreakdownModule { }
