import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VendorPOInvoiceListRoutingModule, vendorPOInvoiceListRoutingComponents } from './vendor-po-invoice-list-routing.module';
import { VendorPOInvoiceListComponent } from '../vendor-po-invoice-list/vendor-po-invoice-list.component';
import { SharedModule } from '../../modules/shared/shared.module';
import {NgxPaginationModule} from 'ngx-pagination';

@NgModule({
  declarations: [
    vendorPOInvoiceListRoutingComponents,
    VendorPOInvoiceListComponent
  ],
  imports: [
    CommonModule,
    VendorPOInvoiceListRoutingModule,
    NgxPaginationModule,
    SharedModule
  ]
})
export class VendorPOInvoiceListModule { }