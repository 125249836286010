import { Component, OnDestroy, OnInit } from '@angular/core';
import * as data from './data.json'

@Component({
  selector: 'dept-filter-dsktop',
  templateUrl: './dept-filter-dsktop.component.html',
  styleUrls: ['./dept-filter-dsktop.component.scss']
})
export class DeptFilterDsktopComponent implements OnInit, OnDestroy {

  panelStatus:boolean;
  groupData:any = [];
  selectedItems:any = [];

  constructor() { }

  ngOnInit(): void {
    
    for(let key in data.panelData){
      if(data.panelData.hasOwnProperty(key)){
        this.groupData.push(data.panelData[key])
      }
    }
  }

  ngOnDestroy(){}

  panelToggle(){
    this.panelStatus = !this.panelStatus;
  }

  // Panel Item Selection
  selectRow(i:number, e:any){
    this.selectedItems[i] = e.target.parentElement.querySelector('.amount').innerHTML;
  }

  clearAll(){
    this.selectedItems = [];
  }

}
