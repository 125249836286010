<div id="{{ currentRoute === '/landing' ? 'home-page' : 'ach-component' }}" class="chartHeader">
    <div class="data-title">
        <div class="relative">
            <div class="vCenter indicator"></div>
        </div>
        <p>{{"Total " + currentYear + " "}}<span *ngIf="width > 675"> </span>{{ pgTitle.slice(1) }}</p>
    </div>
    <div class="amount">
        <div class="relative">
            <span class="relative">$</span>
        </div>
        <div>
            <span>91,839</span>
        </div>
        <div class="relative">.56</div>
        <div *ngIf="currentRoute != '/landing'" class="projected">projected</div>
    </div>
    <div *ngIf="currentRoute === '/landing'" class="avg-mo-expenditures">
        <p>avg. monthly expenditures</p>
        <i class="sort fas fa-sort-down"></i>
    </div>
    <!-- <div class="projected" *ngIf="isMobile">projected</div> -->
</div>