import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CurrentRouteService {

  currentRoute:string | undefined;

  parentRoute = ['/landing', '/departments', '/vendor-main-landing', '/search', '/more'];
  noBackBtn:boolean = false;

  private valueSource = new BehaviorSubject(this.currentRoute);
  currentVal = this.valueSource.asObservable();
  breadcrumbs:any;
  category:any;

  changeValue(myValue: any) {
    this.currentRoute = myValue;
    for(var i = 0; i < this.parentRoute.length; i++){
     if(this.parentRoute[i] === this.currentRoute ){
       this.noBackBtn = false;
       break;
     } else {
       this.noBackBtn = true;
     }
    }

    // Get data from app router
    this.breadcrumbs = this._ar.firstChild.snapshot.data.breadcrumbs;
    this.category = this._ar.firstChild.snapshot.data;
    return this.valueSource.next(myValue);
  }

  constructor(
    private _ar:ActivatedRoute
  ) {}


}