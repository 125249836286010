import { Component, OnInit, Output, ViewChild, ElementRef, OnDestroy, ChangeDetectorRef, ViewChildren, QueryList, HostListener } from '@angular/core';
import { WindowWidthService } from 'src/app/services/windowWidth.service';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from 'rxjs';
import { ModalBgService } from 'src/app/services/modal-bg.service';
import { DeptTableComponent } from '../../components/_reusable/dept-table/dept-table.component';
import { GlobalFnService } from 'src/app/services/global-functions.service';
import { DoughnutScrollComponent } from 'src/app/components/_reusable/doughnut-scroll/doughnut-scroll.component';
import * as deptJson from "./deptData.json";
import * as expenseJson from './expenseData.json';
import * as contractJson from './contractData.json'
import { DeptFiltersComponent } from 'src/app/components/dept-filters/dept-filters.component';
import { ToggleSwitchComponent } from 'src/app/components/_reusable/toggle-switch/toggle-switch.component';

@AutoUnsubscribe()

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit, OnDestroy {

  protected subscription1$: Subscription;
  protected subscription2$: Subscription;

  btnNames: string[] = ["Dept.", "Exp. Category", "Contract"]
  btnIndex: number = 0;
  p: number = 1;
  itemsPerPage: number = 6;
  result: any = [];
  tableResult: any = [];
  progressBarWidth: any;
  screenSize: number;
  dataIndex: number;
  backgroundColors = ["#81D36B", "#0083D8", "#5CC5EB", "#BED758", "#003A68"];
  filterActive: any;
  tableLinks: string[] = ["department-drilldown", "burn-rate-category", "vendor-contract-details"];

  deptData: any = deptJson.tableData;
  expenseData: any = expenseJson.tableData;
  contractData: any = contractJson.tableData;
  tableData: any = [this.deptData, this.expenseData, this.contractData];
  chartData: number[][] = [[10, 30, 10, 20, 30], [30, 10, 20, 30, 20], [15, 25, 15, 10, 20]]
  tableIndex: number = 0;
  abreviationArray: any = [];

  // Get Column Names from Table
  @ViewChild(DeptTableComponent) tableComponent: any = [];
  columnNames: string;
  @Output() visibleFilters: any = this.tableComponent.columnNames;
  @Output() gridAreaNames: any;

  @ViewChild('completed') completed: ElementRef;
  @ViewChild(DoughnutScrollComponent) dScroll: DoughnutScrollComponent;
  @ViewChild(DeptFiltersComponent) dFilters: any;

  constructor(
    private _windowWidth: WindowWidthService,
    public _modalBG: ModalBgService,
    private _cd: ChangeDetectorRef,
    public _fn: GlobalFnService
  ) { }

  ngOnInit(): void {
    this.subscription1$ = this._windowWidth.currentVal.subscribe(currentVal => this.screenSize = currentVal);
    this.subscription2$ = this._modalBG.currentVal.subscribe(currentVal => this.filterActive = currentVal);
  }

  ngAfterViewInit() {
    this.setTableColumns()
    this._cd.detectChanges();
  }

  ngOnDestroy() { }

  // Format number...fix to allow cents
  formatNumber(n: any) {
    var formatedNum = String(n).replace(/(.)(?=(\d{3})+$)/g, '$1,');
    n = formatedNum;
    return n
  }

  // Create new grid-template-area
  receiveNewColNames(e: any) {
    this.gridAreaNames = "";
    for (var i = 0; i < e.length; i++) {
      this.gridAreaNames += e[i] + " "
    }
    this.gridAreaNames = "'" + this.gridAreaNames + "'";
  }

  // Receive from filters, send full/abreviation to table (column 1)
  getNewData(e: any) {
    if (e) {
      for (var i = 0; i < this.tableData[this.tableIndex].length; i++) {
        this.abreviationArray.push(this.tableData[this.tableIndex][i]._abreviation);
      }
    } else {
      this.abreviationArray = []
    }
  }

  tabChange(i: number) {
    this.tableIndex = i;
    this.loadTable()
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.loadTable()
  }

  loadTable(){
    this.setTableColumns();
    this._fn.restoreColumns();
    this.dFilters.resetToggles()
  }

  setTableColumns() {
    this._fn.createTableGridArea(this.tableData[this.tableIndex][0]);
    this._fn.createFilterGridArea(this.dFilters, this.tableData[this.tableIndex][0]);
  }

}