import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SpendingMilestoneComponent } from '../spending-milestone/spending-milestone.component';

const routes: Routes = [
  { path: '', component: SpendingMilestoneComponent, children: [
      { path:'spending-milestone', component: SpendingMilestoneComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SpendingMilestoneRoutingModule { }

export const spendingMilestoneRoutingComponents = [
  SpendingMilestoneComponent
]
