<div class='doughnut-scroll'>
    <div class='chart-wrapper'>
        <div>
            <doughnut-chart [cutOutPercent]="70" [dataArray]="dataArray" [currentFocus]="categoryIndex" [backgroundColors]="backgroundColors"></doughnut-chart>
        </div>
    </div>
    <div>
        <div class='department-info'>
            <div class='department-name'>
                <div class='color' [style.background-color]="backgroundColors[categoryIndex]"></div>
                <p>{{ this.data[this.categoryIndex].Department || this.data[this.categoryIndex]["Expense Category"] || this.data[this.categoryIndex].Contract }}</p>
                <p class="dollar-amount">{{ screenSize
                    <= 675 ? _fn.abrevNum(this.data[this.categoryIndex].Remaining) : this.data[this.categoryIndex].Remaining}}</p>
            </div>
            <div class='expense-categories'>
                <p class='upper'>top 3 Categories</p>
                <div *ngFor="let item of topThree">
                    <p class='expense' *ngFor="let items of item">
                        {{ items.value }}
                    </p>
                </div>
            </div>
        </div>
        <nav>
            <div class='arrow' (click)="goBack()">
                <i class="fa fa-chevron-left"></i>
            </div>
            <div class='arrow arrow-right' (click)="goForward()">
                <i class="fa fa-chevron-right"></i>
            </div>
        </nav>
    </div>
</div>