import { Component, OnDestroy, OnInit } from '@angular/core';
import { OpenContactService } from 'src/app/services/open-contact.service';
import { WindowWidthService } from 'src/app/services/windowWidth.service';
import * as contactData from './contacts.json';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from 'rxjs';
import { ModalBgService } from 'src/app/services/modal-bg.service';

@AutoUnsubscribe()

@Component({
  selector: 'contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss']
})
export class ContactCardComponent implements OnInit, OnDestroy {

  protected subscription1$: Subscription;
  protected subscription2$: Subscription;

  screenSize: number;
  deptContactCard: boolean;
  contractsStatus: boolean = false;
  contactGroup: any = [];


  constructor(
    private _contactService: OpenContactService,
    private _windowWidth: WindowWidthService,
    private _modalBG: ModalBgService
  ) { }

  ngOnInit(): void {
    this.subscription1$ = this._contactService.currentVal.subscribe(currentVal => this.deptContactCard = currentVal);
    this.subscription2$ = this._windowWidth.currentVal.subscribe(currentVal => this.screenSize = currentVal)

    for (let key in contactData.relatedContacts) {
      if (contactData.relatedContacts.hasOwnProperty(key)) {
        this.contactGroup.push(contactData.relatedContacts[key])
      }
    }
  }

  ngOnDestroy(){}

  contractsMenu() {
    this.contractsStatus = !this.contractsStatus;
  }

  closeSubMenu() {
    this._modalBG.closeAll()
  }

  contactCardToggle() {
    this.deptContactCard = !this.deptContactCard;
    this._contactService.contactCardState = this.deptContactCard;
    this._contactService.changeValue(this.deptContactCard)
    this.contractsStatus = false;
  }

}
