import { Component, OnInit, ElementRef, ViewChild, HostListener, OnDestroy, Output, EventEmitter, ChangeDetectorRef, Input } from '@angular/core';
import { WindowWidthService } from 'src/app/services/windowWidth.service';
import * as ddMenuData from '../dropdown-menus/dropdowns.json';
import { style } from '@angular/animations';
import { CurrentRouteService } from 'src/app/services/current-route.service';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from 'rxjs';

@AutoUnsubscribe()

@Component({
  selector: 'dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit, OnDestroy {

  @Output() ddInfo = new EventEmitter<any>();

  @Input() options:any = ["placeholder 1", "placeholder 2", "placeholder 3"];
  currentSelection:any;

  elems: any = [];
  allMenus: any = [];

  ddMenuData: any = ddMenuData.dropDownMenus;

  @ViewChild('menu', { static: false }) menu: ElementRef;

  constructor(
    private _changeDetect:ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.currentSelection =  this.options[0];
  }

  ngAfterViewInit() {
    this._changeDetect.detectChanges();
  }

  ngAfterViewChecked() {
  }

  ngOnDestroy(){}

  emitInfo() {
    this.ddInfo.emit({
      menuValue: this.currentSelection
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.closeMenu()
  }

  closeMenu() {
    this.allMenus = Array.from(this.menu.nativeElement.querySelectorAll('div .menu-options'));
    for (var i = 0; i < this.allMenus.length; i++) {
      this.allMenus[i].classList.remove('menu-show');
    }
  }

  toggleMenu(i: number) {
    this.elems = Array.from(this.menu.nativeElement.querySelectorAll('.menu-options'));
    for (var k = 0; k < this.elems.length; k++) {
      this.elems[k].removeAttribute('style')
    }

    this.elems[i].classList.contains('menu-show') ? this.elems[i].classList.remove('menu-show') : this.elems[i].classList.add('menu-show');
  }

  selectItem(i: number) {
    this.currentSelection = this.options[i]
    this.closeMenu()
    this.emitInfo();
  }

}
