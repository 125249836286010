import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BurnRateCategoryRoutingModule, burnRateCategoryRoutingComponents } from './burn-rate-category-routing.module';
import { SharedModule } from '../../modules/shared/shared.module';


@NgModule({
  declarations: [
    burnRateCategoryRoutingComponents
  ],
  imports: [
    CommonModule,
    BurnRateCategoryRoutingModule,
    SharedModule
  ]
})
export class BurnRateCategoryModule { }
