import { HostListener, Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WindowWidthService {

  someWidth:number|undefined;

  private winWidthSource = new BehaviorSubject(this.someWidth);
  currentVal = this.winWidthSource.asObservable();

  changeValue(newValue:number){
    return this.winWidthSource.next(newValue);
  }
}