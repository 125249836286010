import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { WindowWidthService } from 'src/app/services/windowWidth.service';

@Component({
  selector: 'tab-group',
  templateUrl: './tab-group.component.html',
  styleUrls: ['./tab-group.component.scss']
})

@AutoUnsubscribe()

export class TabGroupComponent implements OnInit, OnDestroy {
  @Input() tabs: any;
  @Input() maxWidth: number;
  @Output() tabClickEvt = new EventEmitter<any>();
  @ViewChild('tabGroup', { static: false }) tabGroup: ElementRef;

  currentView: any;
  currentActiveTab: number = 0;
  screenSize:number;

  protected subscription$1: Subscription;

  tabNames = [];
  tabInfo = [];

  constructor(
    private _windowService:WindowWidthService,
    private _changeDetect:ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    if (!this.tabs) this.tabs = [{ name: "Placeholder" }, { name: "Tabs" }];
    for (let i = 0; i < this.tabs.length; i++) {
      this.tabNames.push(this.tabs[i].name)
      this.tabInfo.push(this.tabs[i].info)
    }
    if (this.tabInfo[1]) this.numFormatter(this.tabInfo[1]);
    this.subscription$1 = this._windowService.currentVal.subscribe(currentVal => this.screenSize = currentVal);
  }

  ngAfterViewInit(): void {
    this.tabGroup.nativeElement.removeAttribute("style");

    // Divide # of indices to create grid column
    this.tabGroup.nativeElement.style = "grid-template-columns:repeat(" + this.tabs.length + ", auto)";

    this._changeDetect.detectChanges();
  }

  ngOnDestroy(){}

  // Formate Number
  numFormatter(num:any) {
    var removeChars = num.toString().replace(/[$,]/g,"");
    if(removeChars.length == 8){
      var newNum = removeChars.substr(0,2) + 'M';
      num = newNum;
      return num
    }
}

  // Button Click Event
  tabClick(i) {
    this.currentActiveTab = i;
    this.tabClickEvt.emit(this.currentActiveTab);
  }

  calcPadding(max) {
    return max ? `calc(100% - ${max}px)` : "0"
  }


}
