    <div id="main-content" data-page="vendor-spending">
    <div class="page-content">
        <div class="header-content">
            <div class="page-name">
                <page-name [pageName]='"Vendor Spending"'></page-name>
            </div>
            <div [ngClass]="{'mobile-background':screenSize < 1100}">
                <dropdown-menus (ddInfo)='changeDD($event)'></dropdown-menus>
            </div>
        </div>

        <div class="spending-breakdown p-padding p-radius p-shadow white-BG">
            <p class='breakdown-title'>Spending Breakdown</p>
            <div class='bar-grid'>
                <div class='stacked-bar'>
                    <stacked-bar-chart [chartData]="chartData" [graphLabels]="['This Month', 'Last Month']" [showLabels]="true" [graphHeight]="120"></stacked-bar-chart>
                </div>
                <div class='bar-info'>
                    <div class='title-bar'>
                        <p>Vendor</p>
                        <p>This Month</p>
                        <p>Last Month</p>
                    </div>
                    <div class='info-bar' *ngFor="let i = index; let vendor of chartData | slice:0:5">
                        <div class='color-circle' [style.backgroundColor]="chartColors[i]"></div>
                        <p>{{ vendor.name }}</p>
                        <p>{{ calcPercent(this.chartData, 0, i) }}</p>
                        <p>{{ calcPercent(this.chartData, 1, i) }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class='table-title'>
            <i class="fas fa-arrow-up"></i>
            <p>Top Five Spending Vendors</p>
        </div>
        <div class="data-table p-radius p-shadow white-BG">
            <dept-table [data]="tableData" [itemsPerPageInput]="10" [firstColumnWidth]="150" [pageValues]="_fn.gridAreaNames" [pageLink]="'vendors-drilldown-overview'"></dept-table>
        </div>
    </div>
</div>