<div id="main-content" data-page="voucher-breakdown">
    <div class="page-content">
        <div class="sidebar-icon">
            <sidebar-toggle-icon (emitValue)="_fn.sidebarToggle($event)" (click)="_fn.sidebarBG(ddMenuComp)"></sidebar-toggle-icon>
        </div>
        <div class="header-content">
            <div class="page-name">
                <page-name [pageName]='"Voucher Breakdown"'></page-name>
            </div>
            <div [ngClass]="{'mobile-background':screenSize <= 1100}">
                <dropdown-menus (ddInfo)='changeDD($event)'></dropdown-menus>
            </div>
        </div>
        <section class='spending-breakdown p-shadow p-radius white-BG'>
            <div class="spending-information-container">
                <h2>Spending Breakdown</h2>
                <div class="inner-info-display">
                    <div class="subtitle-row">
                        <p>Document Type</p>
                        <p>This Month</p>
                        <p>Last Month</p>
                    </div>
                    <div class="panel-values">
                        <div class="spending-breakdown-panel-data" *ngFor="let item of spendingBreakdownData; let i = index">
                            <div>{{ spendingBreakdownData[i].documentType }}</div>
                            <div class="italic-numbers">{{ spendingBreakdownData[i].thisMonthPercent }}</div>
                            <div class="italic-numbers">{{ spendingBreakdownData[i].lastMonthPercent }}</div>
                        </div>
                    </div>
                </div>
                <div class="inner-spending-display">
                    <div class="monthly-amounts-area">
                        <div>
                            <p>This Month</p>
                            <p class="blue-highlight">{{ chartData[chartDataIndex][0].thisMonth[0] }}</p>
                        </div>
                        <div>
                            <p>Last Month</p>
                            <p>{{ chartData[chartDataIndex][0].lastMonth[1] }}</p>
                        </div>
                    </div>
                    <div class="bar-graph-area">
                        <bar-chart [chartData]="chartData[chartDataIndex]"></bar-chart>
                    </div>

                </div>
            </div>
        </section>
        <section class='voucher-creation ' [ngClass]="{'white-BG' : screenSize > 1100}">
            <div class="voucher-information-container" *ngIf="screenSize > 1100">
                <h2>Voucher Creation</h2>
                <div class="inner-info-display">
                    <div class="inner-info-display">
                        <div class="subtitle-row">
                            <p>Document Type</p>
                            <p>This Month</p>
                            <p>Last Month</p>
                        </div>
                        <div class="panel-values">
                            <div class="spending-breakdown-panel-data panel-data-color" *ngFor="let item of spendingBreakdownData; let i = index">
                                <div class="dot"><span [ngStyle]="{'backgroundColor': spendingBreakdownData[i].dotColor}"></span></div>
                                <div>{{ spendingBreakdownData[i].documentType }}</div>
                                <div class="italic-numbers">{{ spendingBreakdownData[i].thisMonthPercent }}</div>
                                <div class="italic-numbers">{{ spendingBreakdownData[i].lastMonthPercent }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="inner-spending-display inner-s-d-2">
                    <div class="this-month">
                        <div>
                            <p>This Month</p>
                            <p>{{ chartData[chartDataIndex][0].thisMonth[1] }}</p>
                        </div>
                        <div class="doughnut-chart-container">
                            <div>
                                <doughnut-chart cutOutPercent="70" [dataArray]="chartData[chartDataIndex][0].doughnutData"></doughnut-chart>
                            </div>
                        </div>
                    </div>
                    <div class="last-month">
                        <div>
                            <p>Last Month</p>
                            <p>{{ chartData[chartDataIndex][0].lastMonth[1] }}</p>
                        </div>
                        <div class="doughnut-chart-container">
                            <div>
                                <doughnut-chart cutOutPercent="70" [dataArray]="chartData[chartDataIndex][1].doughnutData"></doughnut-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="outer-data-container" *ngIf="screenSize < 1101">
                <div *ngFor="let item of sidebarData; let i = index">
                    <div class="inner-data-container">
                        <div class='analytic-card p-shadow p-radius white-BG' id='i'>
                            <p class="subheader">Vouchers Submitted</p>
                            <div class='button-enclosure'>
                                <btn-group [btns]='panelButtons' (clickEvent)="panelToggle($event, i)"></btn-group>
                            </div>
                            <div class="voucher-data">
                                <p>{{sidebarData[i].voucherType}}</p>
                                <div>
                                    <p class="number-link">{{ sidebarData[i][panelSelection[i]].count }}</p>
                                    <p>{{ sidebarData[i][panelSelection[i]].name }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <div id='sidebar' *ngIf="screenSize > 1100" [ngClass]="{'show-sidebar':_fn.sidebarVisible}">
        <div *ngIf="_fn.sidebarVisible && screenSize > 1100 && screenSize < 1420" class="sidebar-close" (click)="_fn.sidebarToggle()">
            <img src="../../../assets/dept-name/closeFilterPanel.svg" />
        </div>
        <div class="panel-wrapper">
            <div *ngFor="let item of sidebarData; let i = index">
                <div class='analytic-card p-shadow p-radius white-BG' id='i'>
                    <p class="subheader">Vouchers Submitted</p>
                    <div class='button-enclosure'>
                        <btn-group [btns]='panelButtons' (clickEvent)="panelToggle($event, i)"></btn-group>
                    </div>
                    <div class="voucher-data">
                        <p>{{sidebarData[i].voucherType}}</p>
                        <div>
                            <p class="number-link">{{ sidebarData[i][panelSelection[i]].count }}</p>
                            <p>{{ sidebarData[i][panelSelection[i]].name }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>