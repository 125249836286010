<div class='budget-graphs-container'>
    <div class='tab-group-container' *ngIf='screenSize <= 1100'>
        <tab-group [tabs]="tabInfo" (tabClickEvt)="getTabClick($event)"></tab-group>
    </div>
    <div class='remaining-budget' *ngIf='screenSize <= 1100'>
        <h3><sup>$</sup>4,230,593</h3>
        <span>Remaining Budget</span>
    </div>
    <div class='graphs-grid'>
        <div class='mini-graphs-wrapper'>
            <div class='remaining-budget' *ngIf='screenSize > 1100'>
                <h3><sup>$</sup>4,230,593</h3>
                <span>Remaining Budget</span>
            </div>
            <div class='mini-graph-box' [routerLink]="['/commitments']">
                <p>Commitments</p>
                <div class='mini-graph'>
                    <div class="mini-height">
                        <line-chart [chartData]="jsonData[chartIndex].miniOne" [timeSpan]="chartIndex" [values]="false" [height]="miniHeight" [bgGradient]="redGradient" [borderColor]="redBorder"></line-chart>
                    </div>
                </div>
                <p class="dollar-amount">{{ screenSize
                    < 1300 ? '$1.3M': '$1,323,752.21'}}</p>
                        <i class="fa fa-chevron-right"></i>
            </div>
            <div class='mini-graph-box' [routerLink]="['/obligations']">
                <p>Obligations</p>
                <div class='mini-graph'>
                    <div class="mini-height">
                        <line-chart [chartData]="jsonData[chartIndex].miniTwo" [timeSpan]="chartIndex" [values]="false" [height]="miniHeight" [bgGradient]="redGradient" [borderColor]="redBorder"></line-chart>
                    </div>
                </div>
                <p class="dollar-amount">{{ screenSize
                    < 1300 ? '$276K': '$276,831.59' }}</p>
                        <i class="fa fa-chevron-right"></i>
            </div>
            <div class='mini-graph-box' [routerLink]="['/expenditures']">
                <p>Expenditures</p>
                <div class='mini-graph'>
                    <div class="mini-height">
                        <line-chart [chartData]="jsonData[chartIndex].miniThree" [timeSpan]="chartIndex" [values]="false" [height]="miniHeight" [bgGradient]="greenGradient" [borderColor]="greenBorder"></line-chart>
                    </div>
                </div>
                <p class="dollar-amount">{{ screenSize
                    < 1300 ? '$265K': '$265,871.34'}}</p>
                        <i class="fa fa-chevron-right"></i>
            </div>
        </div>

        <div class='main-graph-wrapper'>
            <div class="line-wrapper">
                <div class='btn-group-wrapper'>
                    <btn-group (clickEvent)="btnClick($event)" [btns]='btnNames'>
                    </btn-group>
                </div>
            </div>
            <div class='chart-overlay'>
                <div class='graph-dotted-line'></div>
                <div class='graph-dotted-line'></div>
                <div class='right-labels'>
                    <p *ngFor='let label of graphScale[currentTab]'>{{ label }}</p>
                </div>
            </div>
            <div class="line-chart">
                <div class="c-component">
                    <line-chart [chartData]="jsonData[chartIndex].lineOne" [timeSpan]="chartIndex" [values]="true" [height]="fullHeight"></line-chart>
                </div>
            </div>
        </div>

    </div>
</div>