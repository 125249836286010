import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContractObligationsBreakdownComponent } from '../contract-obligations-breakdown/contract-obligations-breakdown.component';

const routes: Routes = [
  { path: '', component: ContractObligationsBreakdownComponent, children: [
      { path:'contract-obligations-breakdown', component: ContractObligationsBreakdownComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContractObligationsBreakdownRoutingModule { }

export const contractObligationsBreakdownRoutingComponents = [
  ContractObligationsBreakdownComponent
]
