<div id="main-content" data-page="vendor-po-invoice-list">
    <div class="pg-content">
        <div class="content-grid">
            <h2>$500,000</h2>
            <div class="relative compare">
                <div></div>
                <button type="button" [routerLink]="['/comparison-report-select']">Compare</button>
            </div>
            <div class="contract-num">PO #58342409</div>
            <div class="status">
                <div>
                </div>
                <div>
                    <div></div>
                    <p>Active</p>
                </div>
            </div>
        </div>

        <div class="button-trio" *ngIf="screenSize < 1101">
            <btn-group [btns]='["Overview", "Vouchers", "Invoices"]' (click)="viewChange($event)"></btn-group>
        </div>
        <div class="award-vendor p-radius p-padding p-shadow white-BG" *ngIf="viewSelection === 'Overview' || screenSize > 1099" [ngClass]="{'overview-active': selectedTab === 'overview'}">
            <div>
                <div>
                    <p>PO Start Date</p>
                    <p>04/12/2019</p>
                </div>
            </div>
            <div>
                <p>PO Start Date</p>
                <p>04/12/2019</p>
            </div>
            <div>
                <div>
                    <p>Vendor Name</p>
                    <p>Vendor ABC</p>
                </div>
                <div>
                    <p>Customer Name</p>
                    <p>Agency ABC</p>
                </div>
            </div>
            <div>
                <address>
               Vendor Address<br>
               1234 Muffin Lane<br>
               Ashburn, VA 20146
            </address>
                <address>
               Customer Address<br>
               1234 Muffin Lane<br>
               Ashburn, VA 20146
            </address>
            </div>
        </div>

        <!-- Mobile Vouchers-->
        <div id="sidebar" *ngIf="selectedTab === 'vouchers' && screenSize < 1100">
            <div class="loop-wrapper">
                <div class="repeating-element" *ngFor="let item of result; let i = index">
                    <div>
                        <p>{{ item.vendorName }}</p>
                        <p>{{ item.amount }}</p>
                        <div class="relative">
                            <i class="fa fa-chevron-right vCenter"></i>
                        </div>
                    </div>
                    <div>
                        <p>{{ item.expenseName }}</p>
                        <p>{{ item.date }}</p>
                    </div>
                    <div (click)="showResult(i)" class="btn-mask"></div>
                </div>
            </div>
        </div>

        <!-- Mobile Invoices-->
        <div id="sidebar" *ngIf="selectedTab === 'invoices' && screenSize < 1100">
            <div class="loop-wrapper">
                <div class="repeating-element" *ngFor="let item of result; let i = index">
                    <div>
                        <p>{{ item.vendorName }}</p>
                        <p>{{ item.amount }}</p>
                        <div class="relative">
                            <i class="fa fa-chevron-right vCenter"></i>
                        </div>
                    </div>
                    <div>
                        <p>{{ item.expenseName }}</p>
                        <p>{{ item.date }}</p>
                    </div>
                    <div (click)="showResult(i)" class="btn-mask"></div>
                </div>
            </div>
        </div>

    </div>
</div>