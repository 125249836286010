<div #menuGroup class="menu-group">
    <div *ngFor="let menus of ddMenuResult; let i = index" [ngClass]="{'variable-btn':isMobile && i === 1}">
        <div class="dropDown" [ngClass]="{'variable-btn':!isMobile}" (click)="toggleMenu(i)">
            <p #dataSpan *ngIf="i === 0" class="user-selection">{{ duration }}</p>
            <p #monthVal *ngIf="i === 1" class="user-selection">{{ month }}</p>
            <p *ngIf="i === 2" class="user-selection">{{ year }}</p>
            <div class="divider" *ngIf="!isMobile"></div>
            <div class="icon-wrapper" *ngIf="!isMobile">
                <i class="fa fa-chevron-down" aria-hidden="true" [ngClass]="{'rotateIcon':menus.menu}"></i>
            </div>
        </div>

        <div class="item-wrapper">
            <ul id="duration" class="menu-options" *ngIf="i === 0">
                <li (click)="selectItem(i, $event)" *ngFor="let submenus of menus.dd; let j = index">
                    {{ submenus.element }}</li>
            </ul>
            <ul id="month" class="menu-options" *ngIf="i === 1">
                <li (click)="selectItem(i, $event)" *ngFor="let submenus of menus.dd.slice().reverse(); let l = index" [ngClass]="{'inactive': l > currentMonth}">{{ months[0 + l] }}</li>
            </ul>
            <ul id="year" class="menu-options" *ngIf="i === 2" [ngClass]="{'year-loop': i == 2}">
                <li (click)="selectItem(i, $event)" *ngFor="let submenus of menus.dd; let j = index">
                    {{ currentYear - j }}</li>
            </ul>
        </div>
    </div>
</div>