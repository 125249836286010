import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VendorMainLandingComponent } from '../vendor-main-landing/vendor-main-landing.component';

const routes: Routes = [
  { path: '', component: VendorMainLandingComponent, children: [
      { path:'vendor-main-landing', component: VendorMainLandingComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VendorMainLandingRoutingModule { }

export const vendorMainLandingRoutingComponents = [
  VendorMainLandingComponent
]

