import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { CurrentRouteService } from 'src/app/services/current-route.service';
import { WindowWidthService } from 'src/app/services/windowWidth.service';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from 'rxjs';

@AutoUnsubscribe()

@Component({
  selector: 'budget-forecast',
  templateUrl: './budget-forecast.component.html',
  styleUrls: ['./budget-forecast.component.scss']
})
export class BudgetForecastComponent implements OnInit, OnDestroy {

  // Used in Landing & Dept Overview
  // This is the full card containing header, three button group component, and doughnut chart component

  protected subscription1$: Subscription;
  protected subscription2$: Subscription;

  userRoute:string;
  screenSize:number;

  @Input() someArray:any = [];

  constructor(
    private _routeService: CurrentRouteService,
    private _windowWidth: WindowWidthService
  ) { }

  ngOnInit(): void {
    this.subscription1$ = this._routeService.currentVal.subscribe(currentVal => this.userRoute = currentVal);
    this.subscription2$ = this._windowWidth.currentVal.subscribe(currentVal => this.screenSize = currentVal)
  }

  ngOnDestroy(){}

}
