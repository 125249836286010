import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss']
})
export class ToggleSwitchComponent {

  switchActive:boolean = true;
  lastVisibleColumn: boolean;
  @Output() toggleStatus:any = new EventEmitter<boolean>()
  @Input() reset:boolean;

  constructor() { }

  toggle(){
    this.switchActive = !this.switchActive;
    this.toggleStatus.emit(this.switchActive)
  }

}
