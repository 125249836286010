<div id="main-content" data-page="burn-rate-month">
    <div class="header-content">
        <div class="page-name">
            <page-name [pageName]='"Burn Rate Over Time"'></page-name>
        </div>
        <div class='button-container'>
            <btn-group [btns]="['Monthly', 'Quarterly', 'Yearly']" (clickEvent)="changeData($event)"></btn-group>
        </div>
    </div>
    <div class="line-chart p-shadow p-radius white-BG" *ngIf="screenSize > 1100">
        <div class='cash-comparison'>
            <div class='cash'>
                <span>${{ currentTime[currentBtn] }}</span>
                <div>
                    <div class='color-icon red'></div>
                    <span>Current</span>
                </div>
            </div>
            <div class='cash'>
                <span>${{ lastTime[currentBtn] }}</span>
                <div>
                    <div class='color-icon pink'></div>
                    <span>Last Year</span>
                </div>
            </div>
        </div>
        <div class="increase">
            <green-box [arrow]="true" [text]="'14% Increase from last year'"></green-box>
        </div>
        <div class="area-chart">
            <app-area-line-chart [monthData]="currentYear" [duration]="durations[currentBtn]" [chartsArray]="chartsArray" [screensize]="screenSize"></app-area-line-chart>
        </div>
    </div>
    <div class="page-content">
        <div class='top-box p-shadow p-radius white-BG' *ngIf="screenSize < 1101">
            <div class='cash-comparison'>
                <div class='cash'>
                    <span>${{ currentTime[currentBtn] }}</span>
                    <div>
                        <div class='color-icon red'></div>
                        <span>Current</span>
                    </div>
                </div>
                <div class='cash'>
                    <span>${{ lastTime[currentBtn] }}</span>
                    <div>
                        <div class='color-icon pink'></div>
                        <span>Last Year</span>
                    </div>
                    <div class="increase-mobile" *ngIf="screenSize > 675">
                        <green-box [arrow]="true" [text]="'14% Increase from last year'"></green-box>
                    </div>
                </div>
            </div>
            <div class="increase-mobile" *ngIf="screenSize <= 675">
                <green-box [arrow]="true" [text]="'14% Increase from last year'"></green-box>
            </div>
            <div class="area-chart">
                <app-area-line-chart [monthData]="currentYear" [duration]="durations[currentBtn]" [chartsArray]="chartsArray" [screensize]="screenSize"></app-area-line-chart>
            </div>
        </div>
        <div class="data-table p-shadow p-radius white-BG">
            <div class='top-subheader'>
                <p>Burn Rate By {{ timeSpans[currentBtn] }}</p>
                <div class='search'>
                    <search-bar [placeholder]="'Search Month, Burn Rate #, or Burn Rate %'" (filterStatus)="_fn.transparentBG($event)"></search-bar>
                </div>
            </div>
            <dept-table [data]="tableData" [itemsPerPageInput]="10" [firstColumnWidth]="200" [pageValues]="_fn.gridAreaNames"></dept-table>
        </div>
    </div>
    <div id="filter-container" [ngClass]="{'show-filters': _modalBG.panelStatus[3].filters.value}">
        <dept-filters [data]="tableData" [filterList]="tableComponent.columnNames" [pageValues]="_fn.gridAreaNames" (columnNamesEvt)="receiveNewColNames($event)"></dept-filters>
    </div>
</div>