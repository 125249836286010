import { Component, OnInit, OnDestroy } from '@angular/core';
import * as notifcationsData from './notifications.json';
import * as data from './barcharts.json';
import { WindowWidthService } from 'src/app/services/windowWidth.service';
import * as Chart from 'chart.js';
import { CurrentRouteService } from 'src/app/services/current-route.service';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from 'rxjs';
import { ModalBgService } from 'src/app/services/modal-bg.service';

@AutoUnsubscribe()

@Component({
    selector: 'notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {

    protected subscription2$: Subscription;
    protected subscription3$: Subscription;

    result: any = [];
    newItems: number = 0;
    thisPanel: boolean;
    screenSize: number;
    viewTwo: boolean = false;
    currentRoute: string;
    modal: any;
    chartData = data.barCharts

    constructor(
        private _wService: WindowWidthService,
        private _routeService: CurrentRouteService,
        private _modalBG: ModalBgService
    ) { }

    ngOnInit(): void {
        for (let key in notifcationsData.list) {
            if (notifcationsData.list.hasOwnProperty(key)) {
                this.result.push(notifcationsData.list[key])
            }
        }
        for (var i = 0; i < this.result.length; i++) {
            if (this.result[i].dot) {
                this.newItems++;
            }
        }

        this._modalBG.currentVal.subscribe(currentVal => this.modal = currentVal);

        this.subscription2$ = this._wService.currentVal.subscribe(currentVal => this.screenSize = currentVal);
        this.subscription3$ = this._routeService.currentVal.subscribe(currentVal => this.currentRoute = currentVal);

        this.screenSize = this._wService.someWidth;

        // Charts
        let dataObj = {
            labels: ["2019", "2020"],
            datasets: [{
                label: '',
                data: data.barCharts,
                minBarLength: 1,
                backgroundColor: [
                    '#999999',
                    '#04C6E0'
                ],
                borderColor: [],
                borderWidth: 1
            }]
        }

        let chartOptions = {
            responsive: false,
            scales: {
                xAxes: [{
                    display: false,
                    ticks: {
                        maxRotation: 90,
                        minRotation: 80
                    }
                }],
                yAxes: [{
                    display: false,
                    ticks: {
                        beginAtZero: true,
                        display: false
                    }
                }]
            },
            legend: {
                display: false
            },
            tooltips: {
                enabled: false
            },
            layout: {
                padding: {
                    left: 0,
                    right: 0,
                    top: 15,
                    bottom: 0
                }
            },
            events: [],
            maintainAspectRatio: false
        }
        let chart = new Chart('chartCanvas', {
            type: 'bar',
            data: dataObj,
            options: chartOptions
        });
    }

    ngOnDestroy() { }

    closePanel() {
        this._modalBG.panelStatus[0].notifications.value = false;
        this._modalBG.changeValue(0, 'notifications', false);
        this.viewTwo = false;
    }

    toggleView() {
        this.viewTwo = !this.viewTwo;
    }
    calcLabelHeight(n) {
        return (n * 2) + 15
    }

}