<div id="user-mobile">
    <button>
        <div (click)="closePanel()" class="close">&#10005;</div>
    </button>
    <div class="header">
        <div class="photo">
            <img src="../../../assets//global/bobevans.png" />
        </div>
        <p class="name">Bob Evans</p>
        <p class="title">Director of Mashed Potatoes</p>
        <button type="button" [routerLink]="'/server-connect'">Sign Out</button>
    </div>
    <div class="options">
        <div id="user-profile">
            <div class="grid"  (click)="clickLink()">
                <div class="relative">
                    <i style="color: rgb(12,97,142)" class="fas fa-cog"></i>
                </div>
                <p>Privacy Settings</p>
                <i class="fas fa-chevron-right"></i>
            </div>
            <div class="grid"  (click)="clickLink()">
                <div class="relative">
                    <i style="color: rgb(113,206,88)" class="fas fa-user"></i>
                </div>
                <p>Update Account Information</p>
                <i class="fas fa-chevron-right"></i>
            </div>
            <div class="grid" (click)="clickLink()">
                <div class="relative">
                    <i style="color: rgb(24,185,216)" class="fas fa-bell"></i>
                </div>
                <p>Notification Settings</p>
                <i class="fas fa-chevron-right"></i>
            </div>
            <div class="grid">
                <div class="relative">
                    <i style="color: rgb(28,207,108)" class="fas fa-long-arrow-alt-right"></i>
                </div>
                <p>Switch to a General User</p>
                <i class="fas fa-chevron-right"></i>
            </div>
        </div>
    </div>
</div>