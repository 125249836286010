import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { Location } from '@angular/common';
import * as userInfo from './user-info.json'
import { WindowWidthService } from 'src/app/services/windowWidth.service';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from 'rxjs';

@AutoUnsubscribe()

@Component({
  selector: 'app-settings-page',
  templateUrl: './settings-page.component.html',
  styleUrls: ['./settings-page.component.scss']
})
export class SettingsPageComponent implements OnInit, OnDestroy {

  protected subscription1$: Subscription;

  users = userInfo.users
  switchStatus = [false, true, false]
  screenSize:number;
  filterStatus:any;
  currentTab:string = "Settings";
  userInfo:string;
  notificationToggle:boolean = false;

  user = this.users[0]

  constructor(
    private location: Location,
    private _windowWidth:WindowWidthService,
    private _changeDetect:ChangeDetectorRef
    ) { }

  ngOnInit(): void {
    this.subscription1$ = this._windowWidth.currentVal.subscribe(currentVal => this.screenSize = currentVal);
  }

  ngAfterViewInit() {
    this._changeDetect.detectChanges()
  }

  ngOnDestroy(){}

  toggleSwitch(n) {
    this.switchStatus[n] = !this.switchStatus[n] 
  }

  previousPage() {
    this.location.back();
  }

  changeTab(str:string) {
    this.currentTab = str;
    if (str === 'Name') this.userInfo = this.users[0].name;
    if (str === 'User Name') this.userInfo = this.users[0].username;
    if (str === 'Position') this.userInfo = this.users[0].position;
  }

  toggleNotificationMenu() {
    this.notificationToggle = !this.notificationToggle
  }

}
