import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VendorBreakdownComponent } from '../vendor-breakdown/vendor-breakdown.component';

const routes: Routes = [
  { path: '', component: VendorBreakdownComponent, children: [
      { path:'vendor-breakdown', component: VendorBreakdownComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VendorBreakdownRoutingModule { }

export const vendorbreakdownRoutingComponents = [
  VendorBreakdownComponent
]

