
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VendorMainLandingRoutingModule, vendorMainLandingRoutingComponents } from './vendor-main-landing-routing.module';
import { SharedModule } from '../../modules/shared/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
  declarations: [
    vendorMainLandingRoutingComponents
  ],
  imports: [
    CommonModule,
    VendorMainLandingRoutingModule,
    NgxPaginationModule,
    SharedModule
  ]
})
export class VendorMainLandingModule { }

