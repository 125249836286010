import { Component, OnInit, Injectable, OnDestroy, OnChanges, DoCheck } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from 'rxjs';
import { CurrentRouteService } from 'src/app/services/current-route.service';
import { ModalBgService } from 'src/app/services/modal-bg.service';
import { WindowWidthService } from 'src/app/services/windowWidth.service';

function _window(): any {
  return window;
}

@Injectable()
export class WindowRef {
  get nativeWindow(): any {
    return _window();
  }
}

@AutoUnsubscribe()

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  host: {
    "(window:resize)": "onWindowResize($event)"
  }
})
export class HeaderComponent implements OnInit, OnDestroy, DoCheck {

  protected subscription1$: Subscription;
  protected subscription2$: Subscription;
  protected subscription3$: Subscription;

  currentSize: number;
  modal: any;
  isMobile: boolean = false;
  width: number;
  mobileWidth: number = 992;
  backBtn:boolean;
  currentRoute: any;
  bCrumbs:any;

  constructor(
    private _modalBG: ModalBgService,
    private _windowWidth: WindowWidthService,
    public _routeService: CurrentRouteService
  ) {}

  ngOnInit() {
    this.subscription1$ = this._modalBG.currentVal.subscribe(currentVal => this.modal = currentVal);
    this.subscription2$ = this._windowWidth.currentVal.subscribe(currentVal => this.width = currentVal);
    this.subscription3$ = this._routeService.currentVal.subscribe(currentRoute => this.currentRoute = currentRoute);
  }

ngDoCheck(){
  this.bCrumbs = this._routeService.breadcrumbs;
}

  ngOnDestroy() { }

  toggleNotifications() {
    if(this.width > 1100){
      this._modalBG.panelStatus[0].notifications.value = !this._modalBG.panelStatus[0].notifications.value;
      this._modalBG.changeValue(0, 'notifications', this._modalBG.panelStatus[0].notifications.value);
    } else {
      document.querySelector('.view-one').classList.remove('hidden');
      document.querySelector('.view-two').classList.add('hidden');
    }
  }

  userProfile() {
    this._modalBG.panelStatus[1].userProfile.value = !this._modalBG.panelStatus[1].userProfile.value;
    this._modalBG.changeValue(1, 'userProfile', this._modalBG.panelStatus[1].userProfile.value);
  }

  onWindowResize(event) {
    this.width = event.target.innerWidth;
    this.isMobile = this.width < this.mobileWidth;
  }


}
