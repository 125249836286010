import { Component, OnDestroy, OnInit } from '@angular/core';
import { WindowWidthService } from 'src/app/services/windowWidth.service';
import { CurrentRouteService } from 'src/app/services/current-route.service';
import { NavigationEnd, Router } from '@angular/router';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from 'rxjs';

@AutoUnsubscribe()

@Component({
  selector: 'area-chart-header',
  templateUrl: './area-chart-header.component.html',
  styleUrls: ['./area-chart-header.component.scss']
})
export class AreaChartHeaderComponent implements OnInit, OnDestroy {

  protected subscription1$: Subscription;
  protected subscription2$: Subscription;

  width: number;
  pgTitle: string;
  currentRoute: string;
  date: any = new Date();
  currentYear: any = this.date.getFullYear()

  constructor(
    private _windowWidth: WindowWidthService,
    private _currentRoute: CurrentRouteService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.subscription1$ = this._windowWidth.currentVal.subscribe(currentVal => this.width = currentVal);
    this.subscription2$ = this._currentRoute.currentVal.subscribe(currentVal => this.currentRoute = currentVal);
    this.pgTitle = this.currentRoute;

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        var currentPage = document.getElementById("main-content"),
          title = currentPage.getAttribute("data-page");

        if (title === 'home') this.pgTitle = title;
        if (title === 'commitments') this.pgTitle = title;
        if (title === 'expenditures') this.pgTitle = title;
        if (title === 'obligations') this.pgTitle = title;

        this.pgTitle = title.charAt(0).toUpperCase() + title.slice(1);
      }
    })
  }

  ngOnDestroy(){}


}
