<div id="main-content" data-page="vendor-breakdown">
    <div class="header-content">
        <div class="page-name">
            <page-name [pageName]='"Vendor Breakdown"'></page-name>
        </div>
        <div [ngClass]="{'mobile-background':screenSize < 1100}">
            <dropdown-menus (ddInfo)='changeDD($event)'></dropdown-menus>
        </div>
    </div>
    <div class="page-content">
        <div class="grid relative p-padding p-shadow p-radius white-BG">
            <div class="analytic">
                <nav>
                    <div class="back">
                        <div (click)='backClick()'>
                            <img src='../../../assets//dept-name/awesome-chevron-right.svg' />
                        </div>
                    </div>
                    <div class="forward">
                        <div (click)='forwardClick()'>
                            <img src='../../../assets//dept-name/awesome-chevron-right.svg' />
                        </div>
                    </div>
                </nav>
                <div class="showcase">
                    <div class="graph">
                        <div>
                            <doughnut-chart [cutOutPercent]="70" [dataArray]="tableValue[dataIndex]._vendorDetails.data"></doughnut-chart>
                        </div>
                    </div>
                    <div class="graph-details">
                        <div>
                            <p>{{ screenSize > 675 ? tableValue[dataIndex].Amount : _fn.abrevNum(tableValue[dataIndex].Amount) }}</p>
                            <p>{{ tableValue[dataIndex]._vendorDetails.vendorName }}</p>
                            <p>{{tableValue[dataIndex]._vendorDetails.vendorName }} makes up <span id="percent-bold">
                                    {{ _fn.randomNumber === undefined ? 20 : _fn.randomNumber }}% </span> of monthly commitments.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="data-table">
            <p class="table-name" *ngIf="screenSize > 1200 || screenSize < 1100">{{ 'Commitments from ' + tableValue[dataIndex]._vendorDetails.vendorName}}</p>
            <p class="table-name" *ngIf="screenSize > 1100 && screenSize < 1200">{{ tableValue[dataIndex]._vendorDetails.vendorName }}</p>
            <div class="wrapper">
                <div class="btn-group">
                    <btn-group [btns]="btnNames" (clickEvent)="btnClick($event)"></btn-group>
                </div>
                <div class='search'>
                    <search-bar [placeholder]="'Search Dept, Contact, Etc..'" (filterStatus)="_fn.transparentBG($event)">
                    </search-bar>
                </div>
            </div>
            <div>
                <dept-table [data]="tableValue" [itemsPerPageInput]="10" [firstColumnWidth]="200" [pageValues]="_fn.gridAreaNames" (clickEvt)="receiveEvt($event)"></dept-table>
            </div>
        </div>
    </div>
    <div id="filter-container" [ngClass]="{'show-filters': _modalBG.panelStatus[3].filters.value}">
        <dept-filters [data]="tableValue" [filterList]="tableComponent.columnNames" [pageValues]="_fn.gridAreaNames" (columnNamesEvt)="receiveNewColNames($event)"></dept-filters>
    </div>
</div>