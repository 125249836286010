import { Component, OnInit, ElementRef, ViewChild, HostListener, OnDestroy, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { WindowWidthService } from 'src/app/services/windowWidth.service';
import * as ddMenuData from './dropdowns.json';
import { style } from '@angular/animations';
import { CurrentRouteService } from 'src/app/services/current-route.service';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from 'rxjs';

@AutoUnsubscribe()

@Component({
  selector: 'dropdown-menus',
  templateUrl: './dropdown-menus.component.html',
  styleUrls: ['./dropdown-menus.component.scss']
})
export class DropdownMenusComponent implements OnInit, OnDestroy {

  @Output() ddInfo = new EventEmitter<any>();

  protected subscription1$: Subscription;
  protected subscription2$: Subscription;

  screenSize: number;
  viewChecked: number = 0;

  yearly: boolean = true;
  elemID: any;
  isMobile: boolean;
  pageNames: any = [];

  elems: any = [];
  ddMenuResult = [];
  allMenus: any = [];

  ddMenuData: any = ddMenuData;
  menuValue: string;

  duration: string = 'Monthly';
  month: string = 'Month';
  year: string = 'Yearly';

  currentYear: any = new Date().getFullYear();
  currentMonth: any = new Date().getMonth();
  months: any = ['Jan', 'Feb', 'Mar', 'Apr', 'May', "June", "July", 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  selectedMonth: any = this.months[this.currentMonth];

  userRoute: string;

  @ViewChild('menuGroup', { static: false }) menuGroup: ElementRef;
  @ViewChild('monthVal', { static: false }) monthDD: ElementRef;
  @ViewChild('dataSpan', { static: false }) dateSpan: ElementRef;

  constructor(
    private windowWidth: WindowWidthService,
    private currentRoute: CurrentRouteService,
    private _changeDetect: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.subscription1$ = this.windowWidth.currentVal.subscribe(currentVal => this.screenSize = currentVal);
    this.subscription2$ = this.currentRoute.currentVal.subscribe(currentVal => this.userRoute = currentVal);

    this.screenSize > 1100 ? this.isMobile = false : this.isMobile = true;
  }

  ngAfterViewInit() {
    // Populate drop down menus
    for (let key in ddMenuData.dropDownMenus) {
      if (ddMenuData.dropDownMenus.hasOwnProperty(key)) {
        this.ddMenuResult.push(ddMenuData.dropDownMenus[key])
      }
    }
    this._changeDetect.detectChanges();
  }

  ngAfterViewChecked() {
    this.viewChecked++;
    if (this.viewChecked === 3 && this.screenSize > 1100) {
      this.isMobile = false;
    }
    if (this.viewChecked === 3 && this.screenSize < 1101) {
      this.openMobileMenu()
    }
  }

  ngOnDestroy() { }

  emitInfo() {
    this.ddInfo.emit({
      month: this.month,
      duration: this.duration,
      year: this.year,
      menuValue: this.menuValue
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (this.screenSize > 1100) {
      this.isMobile = false;
      this.closeAllMenus()
    } else {
      this.isMobile = true;
      this.openMobileMenu();
    }
  }

  // Open Mobile Menu
  openMobileMenu() {
    this.allMenus = [];
    this.allMenus = document.querySelectorAll('.menu-options');
    this.allMenus = Array.from(this.menuGroup.nativeElement.querySelectorAll('div .menu-options'));
    for (var j = 0; j < this.allMenus.length; j++) {
      this.allMenus[j].classList.remove('menu-show')
    }
    this.allMenus[1].classList.add('menu-show')
  }

  // Close all the menus
  closeAllMenus() {
    if (!this.isMobile) {
      this.allMenus = Array.from(this.menuGroup.nativeElement.querySelectorAll('div .menu-options'));
      for (var i = 0; i < this.allMenus.length; i++) {
        this.allMenus[i].classList.remove('menu-show');
      }
    }
  }

  // Close the other two menus on desktop while using the third
  closeUnselectedMenus(items: any, i: any) {
    for (var j = 0; j < items.length; j++) {
      if (i != j) {
        items[j].classList.remove('menu-show');
      }
    }
  }

  removeStyleAttrs() {
    var arr: any;
    arr = Array.from(this.menuGroup.nativeElement.querySelectorAll('.menu-options'));
    for (var i = 0; i < arr.length; i++) {
      arr[i].removeAttribute(style);
    }
  }

  // Center horizontal list items on mobile
  centerUls(arr: any) {
    var ulWidth: number, listElems: any;
    listElems = Array.from(arr.querySelectorAll('li'))

    ulWidth = 0;
    for (var k = 0; k < listElems.length; k++) {
      ulWidth += listElems[k].offsetWidth;
    }
    arr.style.width = ulWidth + 'px';
  }

  // Dropdown menu toggle
  toggleMenu(i: number) {
    // Value being shown in menu
    this.menuValue = this.menuGroup.nativeElement.children[i].querySelector('p').innerHTML;
    this.elems = Array.from(this.menuGroup.nativeElement.querySelectorAll('.menu-options'));
    if (!this.isMobile) {
      for (var k = 0; k < this.elems.length; k++) {
        this.elems[k].removeAttribute('style')
      }
    }
    this.closeUnselectedMenus(this.elems, i)
    if (!this.isMobile) {
      this.elems[i].classList.contains('menu-show') ? this.elems[i].classList.remove('menu-show') : this.elems[i].classList.add('menu-show');
    } else {
      this.elems[i].classList.add('menu-show');
      this.centerUls(this.elems[i]);
      for (var j = 0; j < this.menuGroup.nativeElement.children.length; j++) {
        this.menuGroup.nativeElement.children[j].classList.remove('variable-btn');
      }
      this.menuGroup.nativeElement.children[i].classList.add('variable-btn');
    }
  }

  // Select menu item
  selectItem(i: number, event: any) {
    this.elemID = event.target.parentElement.id;

    // Add Dash to Month Menu If Quarterly/Yearly Selected
    if (event.target.parentElement.id === 'duration' && event.target.innerHTML === ' Quarterly' ||
      this.elemID === "year" && this.dateSpan.nativeElement.innerHTML != ' Monthly' ||
      event.target.parentElement.id === 'duration' && event.target.innerHTML === ' Yearly') {

      this.monthDD.nativeElement.parentElement.classList.add('inactive');
      this.monthDD.nativeElement.innerHTML = "&mdash;";
    } else {
      this.monthDD.nativeElement.parentElement.classList.remove('inactive');
      this.monthDD.nativeElement.innerHTML = this.month;
    }

    var monthMenuValue: any = [];
    monthMenuValue = Array.from(this.menuGroup.nativeElement.children[1].getElementsByClassName('user-selection'))[0];
    this.menuValue = event.target.innerHTML;
    this.menuGroup.nativeElement.children[i].querySelector('p').innerHTML = this.menuValue;
    if (!this.isMobile) {
      this.menuGroup.nativeElement.children[i].querySelector('ul').classList.remove('menu-show');
    }
    var allElems: any = Array.from(this.menuGroup.nativeElement.querySelectorAll('.active-element'));
    for (var j = 0; j < allElems.length; j++) {
      allElems[j].classList.remove('active-element');
    }
    event.target.classList.add('active-element');

    // Month Menu
    if (i === 1) {
      this.menuValue = this.selectedMonth;
    }
    this.emitInfo();
  } // selectItem end

  getChartStuff() {
    // Filter Charts
    var item: number;

    // select JSON obj
    if (this.menuValue === "2022") item = 0;
    if (this.menuValue === "2021") item = 1;
    if (this.menuValue === "2020") item = 2;
    if (this.menuValue === "2019") item = 3;
    if (this.menuValue === "2018") item = 4;
    if (this.menuValue === "Monthly") item = 5;
    if (this.menuValue === "Quarterly") item = 6;
    if (this.menuValue === "Yearly") item = 7;

    // if (this.selectedMonth === this.menuValue) {
    //   item = Math.ceil(Math.random() * 7)
    // }

    // Doughnut Chart
    // this.chart.chart.config.data.datasets[0].data = this.filteredChartsArray[item].graphOne.data;

    // update line chart
    // this.lineData.datasets[0].data = this.filteredChartsArray[item].graphThree.data;
    // this.lineData.datasets[0].labels = this.filteredChartsArray[item].graphThree.labels;
    // this.lineData.datasets[0].categories = this.filteredChartsArray[item].graphThree.categories;

    // this.lineChart.chart.update();
    // this.chart.chart.update();
    // this.createLineGraphAxis(item);
  }
}
