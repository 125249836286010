import { Component, OnDestroy, OnInit, Output, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { GlobalFnService } from 'src/app/services/global-functions.service';
import { ModalBgService } from 'src/app/services/modal-bg.service';
import { WindowWidthService } from 'src/app/services/windowWidth.service';
import { DeptTableComponent } from '../../components/_reusable/dept-table/dept-table.component';
import { Router } from '@angular/router';
import * as tableData from './table-data.json';
import { DeptFiltersComponent } from 'src/app/components/dept-filters/dept-filters.component';

@AutoUnsubscribe()

@Component({
  selector: 'app-comparison-report-select',
  templateUrl: './comparison-report-select.component.html',
  styleUrls: ['./comparison-report-select.component.scss']
})
export class ComparisonReportSelectComponent implements OnInit, OnDestroy {

  protected subscription1$: Subscription;
  protected subscription2$: Subscription

  // Get Column Names from Table
  @ViewChild(DeptTableComponent) tableComponent: any = [];
  columnNames: string;
  @Output() visibleFilters: any = this.tableComponent.columnNames;
  @Output() gridAreaNames: any;

  @ViewChild(DeptFiltersComponent) dFilters: any;

  screenSize: number;
  filterStatus: any;
  tableData: any = tableData.tableData;
  currentTab: number = 0;
  boxesChecked: any = [];
  abreviationArray: any = [];
  tabNames = [
    { name: "Depts." },
    { name: "Fiscal Yrs." },
    { name: "Contracts" },
    { name: "Vouchers" }
  ];
  removeCheckNum: number;

  constructor(
    private _windowWidth: WindowWidthService,
    public _modalBG: ModalBgService,
    public _fn: GlobalFnService,
    private cd: ChangeDetectorRef,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.subscription1$ = this._windowWidth.currentVal.subscribe(currentVal => this.screenSize = currentVal);
    this.subscription2$ = this._windowWidth.currentVal.subscribe(currentVal => this.filterStatus = currentVal);
  }

  ngAfterViewInit() {
    this._fn.createTableGridArea(this.tableData[this.currentTab][0]);
    this._fn.createFilterGridArea(this.dFilters, this.tableData[this.currentTab][0])
    this.cd.detectChanges()
  }

  changeTableData(num) {
    this.currentTab = num;
    this.dFilters.resetToggles()
    this._fn.createTableGridArea(this.tableData[0][0] );
    this._fn.createFilterGridArea(this.dFilters, this.tableData[0][0]);
    this._fn.restoreColumns()
  }

  getNumChecked(arr) {
    this.boxesChecked = arr;
  }

  // Create new grid-template-area
  receiveNewColNames(e: any) {
    this.gridAreaNames = "";
    for (var i = 0; i < e.length; i++) {
      this.gridAreaNames += e[i] + " "
    }
    this.gridAreaNames = "'" + this.gridAreaNames + "'";
  }

  // Receive from filters, send full/abreviation to table (column 1)
  getNewData(e: any) {
    if (e) {
      for (var i = 0; i < this.tableData.length; i++) {
        this.abreviationArray.push(this.tableData[i]._abreviation)
      }
    } else {
      this.abreviationArray = []
    }
  }

  clickCompare() {
    let comparison;
    if (this.currentTab === 0) comparison = 'department'
    if (this.currentTab === 1) comparison = 'budget'
    if (this.currentTab === 2) comparison = 'contract'
    if (this.currentTab === 3) comparison = 'contract'
    if (this.boxesChecked.length === 2) this.router.navigate(['/comparison'], { state: { comparisonType: comparison } })
  }

  removeCheck(i) {
    this.removeCheckNum = 3;
    setTimeout(() => { this.removeCheckNum = i; }, 1);
  }

  ngOnDestroy() { }

}
