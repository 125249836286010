import { Component, OnInit, OnDestroy } from '@angular/core';
import * as userInfo from '../settings-page/user-info.json';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from 'rxjs';
import { ModalBgService } from 'src/app/services/modal-bg.service';

@AutoUnsubscribe()

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {

  protected subscription1$: Subscription

  userInfo = userInfo.users
  user = this.userInfo[0]

  SettingsPanel: boolean;
  currentTab = "general";
  panelStatus: any;

  constructor(
    private _modalBG: ModalBgService
  ) { }

  ngOnInit() {
    this.user.password = this.user.password.replace(/./g, '*');
    this.subscription1$ = this._modalBG.currentVal.subscribe(currentVal => this.panelStatus = currentVal);
  }

  ngOnDestroy() { }

  closePanel() {
    this.currentTab = "general";
    this._modalBG.panelStatus[2].settings.value = false;
    this._modalBG.changeValue(2, 'settings', false);
  }

  changeTab(n:any) {
    this.currentTab = n;
  }

  toggleSwitch(toggle) {
    this.user.notificationSettings[toggle] = !this.user.notificationSettings[toggle]
  }

}
